import { Grid, Link } from "@mui/material";

import { useGetAccountRelationshipQuery } from "api/api-accounts";
import { INCOMLEND_INTERNAL_LINK, LEADS, PRODUCTS } from "codes";
import MainCard from "components/Common/Cards/MainCard";
import Input from "components/Common/Input";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { useFormikContext } from "formik";
import { currencyFormatter } from "helpers/currencyFormatter";
import type { CLDetails, PartnerData } from "types";
import styles from "../cl.module.scss";
import LabelOnly from "./LabelOnly";

const TransactionWithSupplier = ({
  companyId,
  partner,
}: {
  companyId: string;
  partner: PartnerData;
}) => {
  const { data: relationships, isFetching } = useGetAccountRelationshipQuery(
    {
      companyId,
      partnerId: partner.id ?? "",
    },
    { skip: !Boolean(partner.id) || !Boolean(companyId) }
  );

  const formatNumber = (number: number) => {
    return new Intl.NumberFormat().format(number);
  };
  const CURRENCY = "USD";
  const disabled = false;
  const formikProps = useFormikContext<CLDetails>();
  const partnerType =
    partner?.product === PRODUCTS.factoring ? "Supplier" : "Buyer";
  return (
    <MainCard
      content
      title={
        <Link
          href={`${INCOMLEND_INTERNAL_LINK}${LEADS}/${partner.internal_name}`}
          target={"_blank"}
        >
          {partner?.name}
        </Link>
      }
    >
      {isFetching && <SkeletonLoad bars={10} />}
      {!isFetching && relationships && (
        <Grid container>
          <Grid item lg={6} xs={12}>
            <LabelOnly
              label={"Number Of Past Paid Transaction"}
              value={formatNumber(
                relationships?.number_of_past_paid_transaction
              )}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <LabelOnly
              label={"Average Invoice Amount"}
              value={`${CURRENCY} ${currencyFormatter({
                amount: relationships?.average_invoice_amount,
              })}`}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <LabelOnly
              label={"Average Payment Terms"}
              value={relationships?.average_payment_terms}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <LabelOnly
              label={"Any Credit Note"}
              value={relationships?.any_credit_note}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <LabelOnly
              label={`Any delays in payment with ${partnerType}`}
              value={relationships?.any_delays_of_payment_with_buyer}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <LabelOnly
              label={"Annual Revenue"}
              value={`${CURRENCY} ${currencyFormatter({
                amount: formikProps.values.buyerAnnualRevenue,
              })}`}
            />
          </Grid>

          <Grid item xs={12}>
            <Input
              labelClassName={styles.labelClass}
              sx={{
                ".MuiInputBase-root": {
                  height: "11vh",
                  backgroundClip: "border-box",
                  borderRadius: "10px",
                  textarea: {
                    height: "10vh !important",
                    overflow: "scroll !important",
                  },
                },
              }}
              name={"buyerReasonOfAnyDelaysOfPaymentWithBuyer"}
              label={`Reason for Delays in Payment with ${partnerType}`}
              placeholder={"N/A"}
              disabled={disabled}
              value={
                relationships?.reason_of_any_delays_of_payment_with_buyer ?? ""
              }
              multiline
              style={{ width: "100%" }}
              {...formikProps}
            />
          </Grid>
        </Grid>
      )}
    </MainCard>
  );
};
export default TransactionWithSupplier;
