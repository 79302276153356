import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  useAddDocumentToContactMutation,
  useAddDocumentToDirectorMutation,
  useGetDirectorsQuery,
} from "api/api-accounts";
import { useGetAccountContactsQuery } from "api/api-users-v2";
import Layout from "components/Common/Layout";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { Form, Formik } from "formik";
import useAccountData from "hooks/useAccountData";
import { MapContext } from "pages/maps/map";
import React, { useState } from "react";
import { toast } from "react-toastify";
import AddAuthorizedSignatory from "./AddAuthorizedSignatory";
import IDVerificationSignatory from "./IDVerificationAuthorizedSignatory";
import IDVerificationDirector from "./IDVerificationDirector";

interface IDVerificationProps {
  accountType: string;
  isLocked: boolean;
}

const IDVerification: React.FC<IDVerificationProps> = ({
  accountType,
  isLocked,
}) => {
  const mapId = React.useContext(MapContext);
  const {
    mapData,
    isLoading: isLoadingAccountData,
    supplierInternalData,
    buyerInternalData,
  } = useAccountData({ accountType, mapId: mapId ?? "" });

  const { data: supplierContactAccount, isLoading: isLoadingSupplierContacts } =
    useGetAccountContactsQuery(supplierInternalData?.id ?? "", {
      skip: !Boolean(supplierInternalData?.id),
    });
  const { data: buyerContactAccount, isLoading: isLoadingBuyerContacts } =
    useGetAccountContactsQuery(buyerInternalData?.id ?? "", {
      skip: !Boolean(buyerInternalData?.id),
    });

  const { data: supplierDirectors } = useGetDirectorsQuery(
    supplierInternalData?.id ?? "",
    {
      skip: !Boolean(supplierInternalData?.id),
    }
  );

  const { data: buyerDirectors } = useGetDirectorsQuery(
    buyerInternalData?.id ?? "",
    {
      skip: !Boolean(buyerInternalData?.id),
    }
  );

  const [open, setOpen] = useState(false);

  const initialValues = {
    primaryContact: "",
    idVerified1: false,
    notADirector: false,
    documentProof1: [] as string[],
    directors: [] as {
      contact: string;
      idVerified: boolean;
      documentProof: string[];
    }[],
  };

  const [addIDPrimayContact] = useAddDocumentToContactMutation();
  const [addDocumentDirector] = useAddDocumentToDirectorMutation();

  const handleSubmitContactDocument = (
    data: { file: File; year: number | null },
    label: string,
    document_type: string,
    contactIndex: number
  ) => {
    const contactId =
      accountType === "supplier"
        ? (supplierContactAccount?.[contactIndex]?.id ?? "")
        : (buyerContactAccount?.[contactIndex]?.id ?? "");

    if (!contactId) {
      toast(`Failed to upload document: Contact ID not found`, {
        type: "error",
      });
      return;
    }

    const accountId =
      accountType === "supplier"
        ? (supplierInternalData?.id ?? "")
        : (buyerInternalData?.id ?? "");

    addIDPrimayContact({
      account_id: accountId,
      contact_id: contactId,
      data: {
        file: data.file,
        document_type: document_type,
        document_category: label,
      },
    })
      .unwrap()
      .then((response) => {
        toast(
          `Uploaded ${response.name} successfully for contact ${contactIndex + 1}!`,
          { type: "success" }
        );
      })
      .catch((error: any) => {
        toast(
          `Failed to upload document for contact ${contactIndex + 1}: ${error.message}`,
          { type: "error" }
        );
      });
  };

  const handleSubmitDirectorDocument = (
    data: { file: File; year: number | null },
    label: string,
    document_type: string,
    directorIndex: number
  ) => {
    const directorId =
      accountType === "supplier"
        ? (supplierDirectors?.[directorIndex]?.director_id ?? "")
        : (buyerDirectors?.[directorIndex]?.director_id ?? "");

    if (!directorId) {
      toast(`Failed to upload document: Contact ID not found`, {
        type: "error",
      });
      return;
    }

    const accountId =
      accountType === "supplier"
        ? (supplierInternalData?.id ?? "")
        : (buyerInternalData?.id ?? "");

    addDocumentDirector({
      account_id: accountId,
      director_id: directorId,
      data: {
        file: data.file,
        document_type: document_type,
        document_category: label,
      },
    })
      .unwrap()
      .then((response) => {
        toast(
          `Uploaded ${response.name} successfully for contact ${directorIndex + 1}!`,
          { type: "success" }
        );
      })
      .catch((error: any) => {
        toast(
          `Failed to upload document for contact ${directorIndex + 1}: ${error.message}`,
          { type: "error" }
        );
      });
  };

  if (
    isLoadingAccountData ||
    isLoadingSupplierContacts ||
    isLoadingBuyerContacts
  ) {
    return (
      <Layout
        title={"ID Verification"}
        headerConfig={{ left: 12, mid: 0, right: 0 }}
      >
        <SkeletonLoad />
      </Layout>
    );
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const supplierId = supplierInternalData?.id ?? "";
  const buyerId = buyerInternalData?.id ?? "";

  const contactAccount =
    accountType === "supplier" ? supplierContactAccount : buyerContactAccount;

  const directorsAccount =
    accountType === "supplier" ? supplierDirectors : buyerDirectors;

  return (
    <Box sx={{ padding: 2 }}>
      <Grid xs={12}>
        <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
          <Grid xs={5}>
            <Typography variant="h4">ID Verification</Typography>
          </Grid>
          <Grid xs={3} textAlign={"left"}>
            <Typography variant="h4">Proof of verification</Typography>
          </Grid>
          <Grid xs={3} textAlign={"left"}>
            <Typography variant="h4">Proof of address</Typography>
          </Grid>
        </Stack>
      </Grid>
      <Formik initialValues={initialValues} onSubmit={() => {}}>
        {(formikProps) => (
          <Form>
            {contactAccount &&
              contactAccount.map((contact, index) => (
                <IDVerificationSignatory
                  key={contact.id}
                  formikProps={formikProps}
                  handleSubmitContactDocument={handleSubmitContactDocument}
                  contact={contact}
                  index={index}
                  accountId={accountType === "supplier" ? supplierId : buyerId}
                  isLocked={isLocked}
                />
              ))}
            {directorsAccount &&
              directorsAccount.map((director, index) => (
                <IDVerificationDirector
                  key={director.id}
                  formikProps={formikProps}
                  handleSubmitDirectorDocument={handleSubmitDirectorDocument}
                  director={director}
                  index={index}
                  accountId={accountType === "supplier" ? supplierId : buyerId}
                  isLocked={isLocked}
                />
              ))}

            <Grid item xs={12} sx={{ mt: 3 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
                disabled={isLocked}
              >
                Add Director/Authorized Signatory
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Add Director/Authorized Signatory</DialogTitle>
        <DialogContent>
          <AddAuthorizedSignatory
            companyId={accountType === "supplier" ? supplierId : buyerId}
            isPartner={false}
            onEnd={handleClose}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default IDVerification;
