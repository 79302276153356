import {
  Avatar,
  Chip,
  Grid,
  Link,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { currencyFormatter } from "helpers/currencyFormatter";
import useWidth from "helpers/useWidth";
import { useRef } from "react";

import type { InvoiceStatus as IInvoiceStatus } from "types";

const InvoiceStatusConsolidated = (
  data: IInvoiceStatus & { noOfInvoices: number }
) => {
  const theme = useTheme();
  const invoiceData: IInvoiceStatus & { noOfInvoices: number } = {
    ...data,
  };
  // Taking away suitability because we have a better idea, i guess
  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref) : 500;
  const small = width && width < 150;
  // const COLOR_PER_SUITABILITY: { [condition: string]: string } = {
  //   "Natural Fit": "#9FCE63",
  //   "Exception Approved": "#9FCE63",
  //   "Approval Required": theme.palette.warning.main,
  //   "Not Approved": theme.palette.error.main,
  // };
  return (
    <Grid
      container
      spacing={{ lg: 0.5, xs: 0 }}
      justifyContent={"space-between"}
      sx={{ height: "100%", animation: "2s ease-in-out" }}
      ref={ref}
    >
      <Grid item xs={12}>
        <Stack spacing={{ lg: 0.5, xs: 0 }}>
          <Grid item xs={12}>
            <Link
              href={`${import.meta.env.VITE_SF_BASE_URL}${invoiceData.supplier_buyer_map}`}
              target={"_blank"}
              color={"error"}
              fontWeight={"bold"}
              fontSize={"0.7em"}
            >
              Map #{invoiceData.supplier_buyer_map_id}
            </Link>
          </Grid>
          <Tooltip title={invoiceData.buyer_name}>
            <Grid item xs={12}>
              <Stack
                direction={"row"}
                spacing={{ lg: 0.5, xs: 0 }}
                justifyContent={"start"}
                alignItems={"center"}
              >
                <Grid item>
                  <Avatar
                    variant="square"
                    sx={{
                      bgcolor: `${invoiceData.color}`,
                      width: "1em",
                      height: "1em",
                      fontSize: "0.9em",
                      mt: { lg: "3px", md: "1px" },
                    }}
                  >
                    {" "}
                  </Avatar>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    fontSize={"0.7rem"}
                    sx={{
                      maxWidth: "90%",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",

                      [theme.breakpoints.down("md")]: {
                        fontSize: "0.6em !important",
                      },
                    }}
                  >
                    {invoiceData.buyer_name}
                  </Typography>
                </Grid>
              </Stack>
            </Grid>
          </Tooltip>

          <Grid item xs={12}>
            <Typography
              fontWeight={"bold"}
              fontSize={"0.7rem"}
              sx={{
                [theme.breakpoints.down("md")]: {
                  fontSize: "0.6em !important",
                },
              }}
            >
              {currencyFormatter({
                amount: invoiceData.requested_amount_usd,
                currency: invoiceData.ccy,
              })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Tooltip title={invoiceData.supplier_name}>
              <Typography
                fontSize={"0.5em"}
                sx={{
                  maxWidth: "90%",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  cursor: "pointer",
                  [theme.breakpoints.down("md")]: {
                    fontSize: "0.5em !important",
                  },
                }}
              >
                {`${invoiceData.supplier_name}`}
              </Typography>
            </Tooltip>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={small ? 0 : 1}>
              <Grid item height={"1.5ch"} xs={small ? 12 : 6}>
                <Tooltip title={`Insurer: ${data.insurer}`}>
                  <Chip
                    label={`${data.insurer ?? "Insurer Not Found"}`}
                    sx={{
                      fontSize: "0.5em",
                      height: small ? "2.5ch" : "4ch",
                      lineHeight: "1.5ch",
                      borderRadius: small ? 1 : 3,
                      color: theme.palette.background.default,
                      width: "100%",
                    }}
                    color="warning"
                  />
                </Tooltip>
              </Grid>

              <Grid item height={"1.5ch"} xs={small ? 12 : 6}>
                <Tooltip title={`Product: ${data.product}`}>
                  <Chip
                    label={`${data.product ?? "Product Not Found"}`}
                    sx={{
                      fontSize: "0.5em",
                      height: small ? "2.5ch" : "4ch",
                      lineHeight: "1.5ch",
                      borderRadius: small ? 1 : 3,
                      bgcolor: "#9542ee",
                      color: theme.palette.background.default,
                      flexWrap: "wrap",
                      width: "100%",
                    }}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      </Grid>

      <Grid
        item
        xs={12}
        pt={"0 !important"}
        sx={{
          bgcolor: theme.palette.error.main,
          borderRadius: 1,
        }}
      >
        <Typography
          fontWeight={"bold"}
          fontSize={"0.7rem"}
          textAlign="center"
          margin={"auto"}
          height={"100%"}
          sx={{
            lineHeight: "0.4em",
            color: theme.palette.background.default,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            [theme.breakpoints.down("md")]: {
              fontSize: "0.6em !important",
            },
          }}
        >
          {invoiceData.noOfInvoices} Invoices
        </Typography>
      </Grid>
    </Grid>
  );
};
export default InvoiceStatusConsolidated;
