import {
  AdminPanelSettings,
  DoneOutline,
  Gavel,
  Handshake,
  LocalPolice,
  Person3,
  PersonAdd,
  ThumbDownOffAlt,
  Troubleshoot,
} from "@mui/icons-material";
import { List, Stack } from "@mui/material";
import CreditLimitRequest from "assets/navigationIcons/creditLimitRequests.svg?react";
import Layout from "components/Common/Layout";
import SelectScript from "components/RunScripts/SelectScript";
import React from "react";

interface Item {
  [key: string]: {
    items: {
      [key: string]: {
        items: {
          icon: any;
        };
      };
    };
  };
}

const ScriptsPage: React.FC = () => {
  const functions: Item[] = [
    {
      Account: {
        items: {
          "Create an Account": {
            items: { icon: <Person3 /> },
          },
          "Create a Partner for an Account": {
            items: { icon: <PersonAdd /> },
          },
          "Onboard an Account": {
            items: { icon: <Handshake /> },
          },
        },
      },
    },
    {
      "Credit Limit": {
        items: {
          "Create a Credit Limit": {
            items: { icon: <CreditLimitRequest /> },
          },
          "Approve CL": {
            items: { icon: <DoneOutline /> },
          },
          "Reject CL": {
            items: { icon: <ThumbDownOffAlt /> },
          },
        },
      },
    },
    {
      Maps: {
        items: {
          "Fill Compliance": {
            items: { icon: <LocalPolice /> },
          },
          "Fill Risk": {
            items: { icon: <Troubleshoot /> },
          },
          "Fill Legal": {
            items: { icon: <Gavel /> },
          },
        },
      },
    },
    {
      Personas: {
        items: {
          "Create an Internal User - Management Admin": {
            items: { icon: <AdminPanelSettings /> },
          },
          "Create an Internal User - Compliance Admin": {
            items: { icon: <LocalPolice /> },
          },
          "Create an Internal User - Legal Admin": {
            items: { icon: <Gavel /> },
          },
          "Create an Internal User - Risk Admin": {
            items: { icon: <Troubleshoot /> },
          },
          "Create an Internal User - ICPL Admin": {
            items: { icon: <Troubleshoot /> },
          },
          "Create an Internal User - Funding Admin": {
            items: { icon: <Troubleshoot /> },
          },
        },
      },
    },
  ];

  return (
    <Layout
      title="Scripts"
      textHeadingOptions={{
        fontWeight: "bolder",
        level: 2,
        fontSize: "1.5em",
      }}
      subtitle={"Welcome to the Scripts page. Select a functionality to mock"}
      subtitleOptions={{
        fontStyle: "italic",
        color: "info.main",
        fontWeight: 600,
      }}
    >
      <Stack spacing={1}>
        <List dense={true}>
          {functions?.map((item, index) => (
            <SelectScript key={index} item={item} />
          ))}
        </List>
      </Stack>
    </Layout>
  );
};

export default ScriptsPage;
