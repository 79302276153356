import { Link, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useGetEligibilityByIsoQuery } from "api/api-legal";
import { BUYER, INCOMLEND_INTERNAL_LINK, SELLER, SUPPLIER } from "codes";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import SkeletonLoad from "components/Common/SkeletonLoad";
import type { CountryEligibility } from "types";

interface IGoverningLaws {
  iso3: string;
  type: string;
}
const GoverningLaws = (props: IGoverningLaws) => {
  const { iso3, type } = props;
  const { data: countryLegalDetails, isFetching } = useGetEligibilityByIsoQuery(
    iso3,
    {
      skip: !iso3,
    }
  );

  const accountType = (type === SUPPLIER ? SELLER : BUYER)?.toLowerCase();

  return (
    <Stack spacing={1}>
      {isFetching && <SkeletonLoad bars={1} />}
      {!isFetching &&
        (countryLegalDetails ? (
          <>
            <Stack direction={"row"} spacing={1}>
              <LabelViewOnly label={`${type} Country`} />
              <Link
                href={`${INCOMLEND_INTERNAL_LINK}/countries/${iso3}/view-eligibility`}
                target={"_blank"}
              >
                {countryLegalDetails?.name}
              </Link>
            </Stack>
            <Stack direction={"row"} spacing={1}>
              <LabelViewOnly label="Eligibility" />
              <LabelViewOnly
                label={
                  countryLegalDetails?.[
                    `${accountType}` as keyof CountryEligibility
                  ]
                }
                sx={{ fontWeight: 500 }}
              />
            </Stack>
          </>
        ) : (
          <>
            <Stack direction={"row"} spacing={1}>
              <LabelViewOnly label={`${type} Country`} />
              <Typography color={"grey"}>None</Typography>
            </Stack>
            <Stack direction={"row"} spacing={1}>
              <LabelViewOnly label="Eligibility" />
              <LabelViewOnly
                label={"None"}
                sx={{ fontWeight: 500, color: grey["500"] }}
              />
            </Stack>
          </>
        ))}
    </Stack>
  );
};

export default GoverningLaws;
