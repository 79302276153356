import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SyncIcon from "@mui/icons-material/Sync";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Link,
  Stack,
} from "@mui/material";
import { useGetAccountQuery } from "api/api-accounts";
import { ACCOUNTS, BUYER, INCOMLEND_INTERNAL_LINK } from "codes";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { useFormikContext } from "formik";
import type { PartnerData } from "types";
import TradeDetails from "../Details/TradeDetails";
import { AccountSections } from "./AccountSectionTabs";

import AccountPartnerAdditionalInformation from "./AccountPartnerAdditionalInformation";
import AccountPartnerCreditLimit from "./AccountPartnerCreditLimit";

export default function AccountPartnerAccordion({
  sync,
  partner,
}: {
  sync: () => void;
  partner: PartnerData;
}) {
  const formikProps = useFormikContext<AccountSections>();
  const { partners, ...account } = formikProps.values;
  const { data: partner_account, isFetching } = useGetAccountQuery(
    partner?.id ?? "",
    {
      skip: !Boolean(partner?.id),
    }
  );

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="partner-account-content"
        id="partner-account-header"
      >
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Link
            fontWeight={"bold"}
            variant={"h4"}
            href={`${INCOMLEND_INTERNAL_LINK}${ACCOUNTS}/${partner?.internal_name}`}
          >
            {partner?.name}
          </Link>

          <IconButton onClick={sync}>
            <SyncIcon />
          </IconButton>
        </Stack>
      </AccordionSummary>
      {partner.profile === BUYER?.toLowerCase() && (
        <AccordionDetails>
          <Stack spacing={1}>
            {isFetching && <SkeletonLoad bars={10} />}
            {!isFetching && partner_account && (
              <AccountPartnerCreditLimit
                account={account}
                partner={partner_account}
                onEnd={sync}
              />
            )}
            {!isFetching && partner_account && (
              <AccountPartnerAdditionalInformation partner={partner_account} />
            )}
            <TradeDetails companyId={account?.id} partnerId={partner?.id} />
          </Stack>
        </AccordionDetails>
      )}
    </Accordion>
  );
}
