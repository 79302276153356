import { Box, Grid } from "@mui/material";
import { useGetDocumentsQuery } from "api/api-accounts";
import { BUYER, SELLER } from "codes";
import SkeletonLoad from "components/Common/SkeletonLoad";
import DocSection from "components/Documents/UploadOnboardingDocuments/DocSection";
import { useAppSelector } from "redux/hooks";
import type { Account } from "types";

const PartnerDocs = ({ currentPartner }: { currentPartner: Account }) => {
  const USER_ROLE = useAppSelector((state) => state.appState.role);
  const profile = useAppSelector((state) => state.appState.profile);
  const { data: pendingDocs, isFetching } = useGetDocumentsQuery(
    { account_id: currentPartner?.id },
    {
      skip: !Boolean(currentPartner?.id) || !Boolean(USER_ROLE?.id),
      refetchOnFocus: true,
      refetchOnReconnect: true,
    }
  );

  return (
    <Box flexGrow={1}>
      <Grid
        item
        xs={12}
        lg={12}
        margin="auto"
        justifyContent={"center"}
        alignItems={"center"}
      >
        {Boolean(currentPartner) && (
          <Grid item xs={12}>
            {isFetching && !pendingDocs && <SkeletonLoad bars={10} />}
            {!isFetching && pendingDocs && (
              <DocSection
                company={currentPartner}
                companyType={profile === SELLER ? BUYER?.toLowerCase() : SELLER}
                documents={pendingDocs?.data}
              />
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
export default PartnerDocs;
