import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import {
  useDeleteConditionMutation,
  useGetRequiredLegalDocumentsQuery,
  useModifyConditionsMutation,
} from "api/api-legal";
import Modal from "components/Common/Modal";
import SkeletonLoad from "components/Common/SkeletonLoad";
import VariableDropdown from "components/Common/VariableDropdown";
import VariableInput from "components/Common/VariableInput";
import { Form, Formik } from "formik";
import toProperCase from "helpers/propercase";
import { Dispatch, SetStateAction } from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import secondaryButtonStyle from "styles/secondaryButtonStyle";
import type { LegalCondition } from "types";

interface EditParams {
  state: boolean;
  data: LegalCondition | undefined;
}
const EditEligibilityCondition = ({
  iso3,
  editParams,
  setEditParams,
}: {
  iso3: string;
  editParams: EditParams;
  setEditParams: Dispatch<SetStateAction<EditParams>>;
}) => {
  const [modifyConditions] = useModifyConditionsMutation();
  const [deleteCondition] = useDeleteConditionMutation();
  const theme = useTheme();
  const { data: documentList, isFetching: isFetchingDocumentList } =
    useGetRequiredLegalDocumentsQuery(null);
  const modalOverlaySx = {
    backgroundColor: "white !important",
    width: "50%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4ch",
    padding: "2ch",
    fontSize: `${theme.typography.fontSize}px !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    height: "inherit !important",
    fontWeight: "inherit !important",
  };
  const modalSx = {
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  };

  return (
    <Formik
      initialValues={{
        condition_id: editParams.data?.condition_id,
        party: editParams.data?.party,
        condition: editParams.data?.condition,
        document: {
          required_document_id: editParams.data?.required_document_id,
          display_name: editParams.data?.document_name,
        },
      }}
      onSubmit={console.log}
      enableReinitialize
    >
      {(formikProps) => {
        return (
          <Form>
            <Modal
              message={
                <Grid item margin="auto">
                  <Grid container spacing={1}>
                    <Grid item xs={12} lg={4}>
                      <VariableDropdown
                        label={"Party"}
                        name={"party"}
                        keyValue={"name"}
                        value={toProperCase(formikProps.values.party ?? "")}
                        data={[
                          { name: "seller" },
                          { name: "buyer" },
                          { name: "both" },
                        ]}
                        placeholder={`Select Party`}
                        {...formikProps}
                        handleSave={(party) => {
                          iso3 &&
                            modifyConditions({
                              country: iso3,
                              condition_id: formikProps.values.condition_id,
                              conditions: [
                                {
                                  party,
                                },
                              ],
                            })
                              .unwrap()
                              .then(() => {
                                toast("Condition submitted successfully", {
                                  type: "success",
                                });
                                setEditParams({
                                  state: false,
                                  data: undefined,
                                });
                              })
                              .catch((err) => {
                                toast(`An error occured: ${err?.message}`, {
                                  type: "error",
                                });
                                setEditParams({
                                  state: false,
                                  data: undefined,
                                });
                              });
                        }}
                      />
                    </Grid>
                    {isFetchingDocumentList && !documentList?.length && (
                      <Grid item xs={12} lg={12}>
                        <SkeletonLoad bars={1} />
                      </Grid>
                    )}
                    {!isFetchingDocumentList && documentList?.length && (
                      <Grid item xs={12} lg={8}>
                        <VariableDropdown
                          name={"document.display_name"}
                          keyValue={"display_name"}
                          label={"Document"}
                          data={documentList}
                          placeholder={`Select Document`}
                          value={formikProps.values.document.display_name}
                          {...formikProps}
                          handleSave={(value) => {
                            const getSelectedDocumentId = documentList.find(
                              (item) => item.display_name === value
                            )?.id;
                            iso3 &&
                              modifyConditions({
                                country: iso3,
                                condition_id: formikProps.values.condition_id,
                                conditions: [
                                  {
                                    required_document_id: getSelectedDocumentId,
                                  },
                                ],
                              })
                                .unwrap()
                                .then(() => {
                                  toast("Condition submitted successfully", {
                                    type: "success",
                                  });
                                  setEditParams({
                                    state: false,
                                    data: undefined,
                                  });
                                })
                                .catch((err) => {
                                  toast(`An error occured: ${err?.message}`, {
                                    type: "error",
                                  });
                                  setEditParams({
                                    state: false,
                                    data: undefined,
                                  });
                                });
                          }}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12} lg={12}>
                      <VariableInput
                        name={"condition"}
                        label={"Condition"}
                        value={formikProps.values.condition ?? ""}
                        {...formikProps}
                        multiline
                        handleSave={(condition: any) => {
                          iso3 &&
                            modifyConditions({
                              country: iso3,
                              condition_id: formikProps.values.condition_id,
                              conditions: [
                                {
                                  condition,
                                },
                              ],
                            })
                              .unwrap()
                              .then(() => {
                                toast("Condition submitted successfully", {
                                  type: "success",
                                });
                                setEditParams({
                                  state: false,
                                  data: undefined,
                                });
                              })
                              .catch((err) => {
                                toast(`An error occured: ${err?.message}`, {
                                  type: "error",
                                });
                                setEditParams({
                                  state: false,
                                  data: undefined,
                                });
                              });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Stack
                        justifyContent={"space-between"}
                        direction={"row"}
                        alignItems={"center"}
                      >
                        <Grid item xs={12} lg={4}>
                          <Typography fontWeight={"bold"}>
                            Delete this condition?
                          </Typography>
                        </Grid>
                        <Grid item xs={12} lg={8}>
                          <Button
                            variant="outlined"
                            fullWidth
                            startIcon={<DeleteIcon />}
                            sx={{
                              ...secondaryButtonStyle,
                              ">div": {
                                ">div": {
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  textAlign: "center",
                                },
                              },
                            }}
                            color="error"
                            onClick={() =>
                              editParams.data?.condition_id &&
                              deleteCondition({
                                iso3,
                                condition_id: editParams.data?.condition_id,
                              })
                                .unwrap()
                                .then(() => {
                                  setEditParams({
                                    state: false,
                                    data: undefined,
                                  });
                                  toast("Succesfully submitted changes", {
                                    type: "success",
                                  });
                                })
                                .catch((err) =>
                                  toast(`An error occured: ${err?.message}`, {
                                    type: "error",
                                  })
                                )
                            }
                          >
                            Delete
                          </Button>
                        </Grid>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              }
              sx={modalSx}
              width={isMobile ? "90%" : "40%"}
              height={"auto"}
              modalContentSx={{ height: "auto" }}
              open={editParams.state}
              modalOverlaySx={modalOverlaySx}
              modalFooterSx={{ maxWidth: "100%" }}
              onClose={() => setEditParams({ state: false, data: undefined })}
              closeButton
            />
          </Form>
        );
      }}
    </Formik>
  );
};
export default EditEligibilityCondition;
