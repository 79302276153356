import { Avatar, Stack, styled } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { useGetUsersQuery } from "api/api-users-v2";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import CompanyUserManagement from "components/Onboard/User";
import { useFormikContext } from "formik";
import * as React from "react";
import type { Account, AccountRelationship, PartnerData } from "types";
import LeadPartnerAccordion from "./LeadPartnerAccordion";
import LeadTabs from "./LeadTabs";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
export interface LeadSections extends Account, AccountRelationship {
  partner_id: string;
  partners: PartnerData[];
  syncAction: () => void;
}

const TabPanelDiv = styled("div")`
  width: -webkit-fill-available;
  width: fill-available;
  width: -moz-available;
`;
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <TabPanelDiv
      role="tabpanel"
      hidden={value !== index}
      id={`lead-tabpanel-${index}`}
      aria-labelledby={`lead-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </TabPanelDiv>
  );
}

function a11yProps(index: number) {
  return {
    id: `lead-tab-${index}`,
    "aria-controls": `lead-tabpanel-${index}`,
  };
}

export default function LeadSectionTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const formikProps = useFormikContext<LeadSections>();
  const { partners, syncAction, ...lead } = formikProps.values;
  const { id } = lead;
  const { data: users = [], isFetching: isFetchingUsers } = useGetUsersQuery(
    id,
    {
      skip: !Boolean(id),
      refetchOnFocus: true,
      refetchOnReconnect: true,
    }
  );

  return (
    <Box sx={{ width: "100%" }} id="lead-tabs-box" flexGrow={1}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="lead section tabs"
          variant="fullWidth"
          centered
        >
          <Tab label="Account" {...a11yProps(0)} />
          <Tab
            label={
              <Stack spacing={1} direction={"row"}>
                <Typography>Users</Typography>
                <Avatar
                  sx={{
                    bgcolor: users?.length ? "success.main" : "error.main",
                    width: "1.2em",
                    height: "1.2em",
                    padding: "0 2ch",
                  }}
                  variant="rounded"
                >
                  <Typography>{users?.length}</Typography>
                </Avatar>
              </Stack>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <Stack spacing={1} direction={"row"}>
                <Typography>Partners</Typography>
                <Avatar
                  sx={{
                    bgcolor:
                      partners?.length &&
                      partners?.every((partner) => partner?.is_lead_complete)
                        ? "success.main"
                        : "error.main",
                    width: "1.2em",
                    height: "1.2em",
                    padding: "0 2ch",
                  }}
                  variant="rounded"
                >
                  <Typography>{partners?.length}</Typography>
                </Avatar>
              </Stack>
            }
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <LeadTabs />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {isFetchingUsers && <SkeletonLoad bars={3} />}
        {!isFetchingUsers && users && Boolean(users?.length) && (
          <CompanyUserManagement companyId={id} users={users} isPartner />
        )}
        {!isFetchingUsers && !Boolean(users?.length) && (
          <NoData
            text={"No Users Found"}
            sx={{ minWidth: "60vw", margin: "auto" }}
          />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        {partners &&
          Boolean(partners?.length) &&
          partners?.map((partner) => (
            <LeadPartnerAccordion
              key={partner?.id}
              sync={syncAction}
              partner={partner}
            />
          ))}
        {!partners ||
          (!Boolean(partners?.length) && (
            <NoData
              text={"No Partners Found"}
              sx={{ minWidth: "60vw", margin: "auto" }}
            />
          ))}
      </CustomTabPanel>
    </Box>
  );
}
