import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import EditIcon from "@mui/icons-material/Edit";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Avatar,
  Box,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Slide,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import {
  useRegisterTotpQuery,
  useResetPasswordMutation,
  useSetTotpPreferenceMutation,
  useValidateTotpRegistrationMutation,
} from "api/api-auth";
import { useUpdateUserMutation } from "api/api-users";
import {
  useAddUserProfilePicMutation,
  useFetchUserProfilePicQuery,
  useGetCurrentUserQuery,
} from "api/api-users-v2";
import { DEFAULT_ROWS, IS_REQUESTING_NEW_PASS, IS_VERIFYING_OTP } from "codes";
import TwoFA from "components/2FA";
import Input from "components/Common/Input";
import Layout from "components/Common/Layout";
import Modal from "components/Common/Modal";
import SkeletonLoad from "components/Common/SkeletonLoad";
import Switch from "components/Common/Switch";
import VariableDropdown from "components/Common/VariableDropdown";
import VariableInput from "components/Common/VariableInput";
import { VITE_EXPERIMENTAL_TOTP } from "experimental_features";
import { Form, Formik } from "formik";
import history from "helpers/history";
import useIsMobile from "hooks/useIsMobile";
import {
  checkSubscription,
  triggerSubscription,
  unsubscribeUser,
} from "lib/webPush";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { validationSchema } from "./formFields";
import styles from "./settings.module.scss";

const Settings = () => {
  const isMobile = useIsMobile();
  const theme = useTheme();
  const { data: user, isFetching: isFetchingUsers } = useGetCurrentUserQuery();
  const {
    data: profilePic,
    isFetching,
    status,
  } = useFetchUserProfilePicQuery(undefined, {
    refetchOnFocus: true,
    skip: !Boolean(user),
  });

  const [updateUser] = useUpdateUserMutation();
  const { t } = useTranslation();
  const [enableTotp] = useSetTotpPreferenceMutation();
  const [addUserProfilePic] = useAddUserProfilePicMutation();

  const [show, setShow] = useState(false);
  const onOpen = () => setShow(true);
  const onClose = () => setShow(false);
  const { data: otpUrl, isFetching: isFetchingOtpUrl } = useRegisterTotpQuery(
    null,
    {
      refetchOnFocus: false,
    }
  );
  const [registerMFA] = useValidateTotpRegistrationMutation();
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const modalOverlaySx = {
    backgroundColor: "white !important",
    width: "50%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4ch",
    padding: "2ch",
    fontSize: `${theme.typography.fontSize}px !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    height: "inherit !important",
    fontWeight: "inherit !important",
  };
  const modalSx = {
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  };
  const { replace } = history;

  const handleVerifyPasswordRequest = async (data: { email: string }) => {
    const { email } = data;
    email &&
      resetPassword({
        email,
      })
        .unwrap()
        .then(() => {
          replace("/login", {
            authState: IS_REQUESTING_NEW_PASS,
            email: data?.email,
          });
        })
        .catch((e: { error: string; message: string }) => {
          if (
            e?.message ===
            "An error occurred (InvalidParameterException) when calling the AdminResetUserPassword operation: Cannot reset password for the user as there is no registered/verified email or phone_number"
          ) {
            replace("/login", {
              authState: IS_VERIFYING_OTP,
              email: data?.email,
            });
          }
        });
  };

  const handleTotpPreferenceChange = (preference: boolean) => {
    if (preference) onOpen();
    else
      enableTotp({
        enabled: preference,
      })
        .unwrap()
        .then(() => {
          toast("2FA disabled successfully", { type: "success" });
        })
        .catch((err) =>
          toast(`2FA preference error: ${err?.message}`, { type: "error" })
        );
  };

  const [notifIsActive, setNotifIsActive] = useState(false);
  const { data: currentUser } = useGetCurrentUserQuery();

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      const isSubscribed = await checkSubscription();
      setNotifIsActive(isSubscribed);
    };

    checkSubscriptionStatus();
  }, []);

  const handlePushNotificationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNotifIsActive(event.target.checked);
    if (event.target.checked) triggerSubscription(currentUser?.id ?? "");
    if (!event.target.checked) unsubscribeUser();
  };

  return (
    <Slide in direction={"left"}>
      <Box>
        {user && (
          <Formik
            initialValues={{
              marketingChecked: user.opted_marketing_updates,
              firstName: user.first_name,
              lastName: user.last_name,
              mobile: user.mobile,
              title: user.title,
              totpEnabled: user.totp_enabled,
              rows: user?.settings?.rowsPerPage ?? DEFAULT_ROWS,
              code: undefined,
            }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={() => {}}
          >
            {(formikProps) => {
              return (
                <Form>
                  <Layout
                    title={`${t("account-settings")}`}
                    textHeadingOptions={{
                      fontWeight: "bolder",
                      level: 2,
                      fontSize: isMobile ? "1.2em" : "1.5em",
                    }}
                    headerConfig={{
                      left: 10,
                      mid: 0,
                      right: 2,
                      xs: {
                        left: 8,
                        mid: 0,
                        right: 4,
                      },
                      alignItems: "center",
                    }}
                    subtitle={isMobile ? "" : user.email}
                    mode="default"
                  >
                    <Grid container spacing={isMobile ? 0 : 2}>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} lg={12}>
                            <Grid
                              container
                              justifyContent={{ xs: "center", lg: "start" }}
                              alignItems={"center"}
                              spacing={1}
                              direction={{ xs: "column", lg: "row" }}
                            >
                              <Grid item xs={12}>
                                <Typography fontWeight={"bold"}>
                                  Profile Picture
                                </Typography>
                              </Grid>
                              <Grid item>
                                <input
                                  accept="image/*"
                                  id="contained-button-profile"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={(e) => {
                                    const file = e.target.files?.[0];
                                    const formData = new FormData();
                                    if (file) {
                                      formData.append("file", file);
                                      addUserProfilePic(formData)
                                        .unwrap()
                                        .then(() => {
                                          toast("Profile picture updated", {
                                            type: "success",
                                          });
                                        })
                                        .catch((error) => {
                                          toast(
                                            `Error updating profile picture: ${error.message}`,
                                            {
                                              type: "error",
                                            }
                                          );
                                        });
                                    }
                                  }}
                                />
                                <label htmlFor="contained-button-profile">
                                  <IconButton
                                    component="span"
                                    className={styles.container}
                                  >
                                    <Avatar
                                      src={profilePic?.url}
                                      sx={{
                                        bgcolor: theme.palette.primary.main,
                                        width: "5vw",
                                        height: "5vw",
                                        margin: "auto",
                                        [theme.breakpoints.down("sm")]: {
                                          width: "15vw",
                                          height: "15vw",
                                        },
                                      }}
                                    >
                                      {user?.first_name?.charAt(0)}
                                      {user?.last_name?.charAt(0)}
                                    </Avatar>
                                    <Grid
                                      container
                                      className={styles.overlay}
                                      alignItems="flex-end"
                                      justifyContent={"center"}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        className={styles.icon}
                                      >
                                        <Grid
                                          container
                                          justifyContent={"center"}
                                          alignItems="center"
                                        >
                                          <Grid item>
                                            <EditIcon />
                                          </Grid>
                                          <Grid item>
                                            <Typography>{`${t(
                                              "edit"
                                            )}`}</Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </IconButton>
                                </label>
                              </Grid>
                              {!profilePic?.url && (
                                <Grid item>
                                  <Button
                                    component="label"
                                    role={undefined}
                                    variant="contained"
                                    tabIndex={-1}
                                    startIcon={<CloudUploadIcon />}
                                  >
                                    Upload
                                    <VisuallyHiddenInput
                                      type="file"
                                      onChange={(e) => {
                                        const file = e.target.files?.[0];
                                        const formData = new FormData();
                                        if (file) {
                                          formData.append("file", file);
                                          addUserProfilePic(formData)
                                            .unwrap()
                                            .then(() => {
                                              toast("Profile picture updated", {
                                                type: "success",
                                              });
                                            })
                                            .catch((error) => {
                                              toast(
                                                `Error updating profile picture: ${error.message}`,
                                                {
                                                  type: "error",
                                                }
                                              );
                                            });
                                        }
                                      }}
                                    />
                                  </Button>
                                </Grid>
                              )}
                              {profilePic?.url && (
                                <Grid item>
                                  <Button
                                    component="label"
                                    role={undefined}
                                    variant="contained"
                                    tabIndex={-1}
                                    startIcon={<EditIcon />}
                                  >
                                    Edit
                                    <VisuallyHiddenInput
                                      type="file"
                                      onChange={(e) => {
                                        const file = e.target.files?.[0];
                                        const formData = new FormData();
                                        if (file) {
                                          formData.append("file", file);
                                          addUserProfilePic(formData)
                                            .unwrap()
                                            .then(() => {
                                              toast("Profile picture updated", {
                                                type: "success",
                                              });
                                            })
                                            .catch((error) => {
                                              toast(
                                                `Error updating profile picture: ${error.message}`,
                                                {
                                                  type: "error",
                                                }
                                              );
                                            });
                                        }
                                      }}
                                    />
                                  </Button>
                                </Grid>
                              )}
                              {isMobile && <Grid item>{user.email}</Grid>}
                            </Grid>
                          </Grid>
                          <Grid item xs={8}>
                            <Divider />
                          </Grid>

                          <Grid item lg={6} xs={12}>
                            <VariableInput
                              tabIndex={1}
                              label={`${t("first-name")}`}
                              name="firstName"
                              value={formikProps.values.firstName}
                              handleSave={(value) =>
                                updateUser({
                                  id: user?.id,
                                  first_name:
                                    value as typeof formikProps.values.firstName,
                                })
                                  .unwrap()
                                  .then(() => {
                                    toast(
                                      `${t("details-successfully-changed")}`,
                                      {
                                        type: "success",
                                      }
                                    );
                                  })
                                  .catch((error) => {
                                    const errorFile = error?.message;
                                    toast(
                                      `Something went wrong : ${errorFile}`,
                                      {
                                        type: "error",
                                      }
                                    );
                                  })
                              }
                              {...formikProps}
                            />
                          </Grid>

                          <Grid item lg={6} xs={12}>
                            <VariableInput
                              tabIndex={1}
                              label={`${t("last-name")}`}
                              name="lastName"
                              value={formikProps.values.lastName}
                              handleSave={(value) =>
                                updateUser({
                                  id: user?.id,
                                  last_name:
                                    value as typeof formikProps.values.lastName,
                                })
                                  .unwrap()
                                  .then(() => {
                                    toast(
                                      `${t("details-successfully-changed")}`,
                                      {
                                        type: "success",
                                      }
                                    );
                                  })
                                  .catch((error) => {
                                    const errorFile = error?.message;
                                    toast(
                                      `Something went wrong : ${errorFile}`,
                                      {
                                        type: "error",
                                      }
                                    );
                                  })
                              }
                              {...formikProps}
                            />
                          </Grid>

                          <Grid item lg={6} xs={12}>
                            <VariableInput
                              tabIndex={1}
                              label={`${t("phone-number")}`}
                              name="mobile"
                              fullWidth
                              type={"phone"}
                              value={formikProps.values.mobile}
                              handleSave={(value) =>
                                updateUser({
                                  id: user?.id,
                                  mobile:
                                    value as typeof formikProps.values.mobile,
                                })
                                  .unwrap()
                                  .then(() => {
                                    toast(
                                      `${t("details-successfully-changed")}`,
                                      {
                                        type: "success",
                                      }
                                    );
                                  })
                                  .catch((error) => {
                                    const errorFile = error?.message;
                                    toast(
                                      `Something went wrong : ${errorFile}`,
                                      {
                                        type: "error",
                                      }
                                    );
                                  })
                              }
                              {...formikProps}
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <VariableInput
                              tabIndex={1}
                              label={`${t("job-position")}`}
                              name="title"
                              value={formikProps.values.title}
                              handleSave={(value) =>
                                updateUser({
                                  id: user?.id,
                                  title:
                                    value as typeof formikProps.values.title,
                                })
                                  .unwrap()
                                  .then(() => {
                                    toast(
                                      `${t("details-successfully-changed")}`,
                                      {
                                        type: "success",
                                      }
                                    );
                                  })
                                  .catch((error) => {
                                    const errorFile = error?.message;
                                    toast(
                                      `Something went wrong : ${errorFile}`,
                                      {
                                        type: "error",
                                      }
                                    );
                                  })
                              }
                              {...formikProps}
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <VariableDropdown
                              name={"rows"}
                              keyValue={"name"}
                              label={"Default Number of Rows"}
                              labelClassName={styles.labelClass}
                              data={[
                                { name: 10, value: 10 },
                                { name: 50, value: 50 },
                                { name: 100, value: 100 },
                              ]}
                              value={formikProps.values.rows}
                              {...formikProps}
                              handleSave={(value) =>
                                updateUser({
                                  id: user?.id,
                                  settings: {
                                    ...user.settings,
                                    rowsPerPage:
                                      value as typeof formikProps.values.rows,
                                  },
                                })
                                  .unwrap()
                                  .then(() => {
                                    toast(
                                      `${t("details-successfully-changed")}`,
                                      {
                                        type: "success",
                                      }
                                    );
                                  })
                                  .catch((error) => {
                                    const errorFile = error?.message;
                                    toast(
                                      `Something went wrong : ${errorFile}`,
                                      {
                                        type: "error",
                                      }
                                    );
                                  })
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Switch
                                  value={formikProps.values.marketingChecked}
                                  onChange={(e: any) =>
                                    formikProps.setFieldValue(
                                      "marketingChecked",
                                      e.target.checked
                                    )
                                  }
                                  {...formikProps}
                                />
                              }
                              label={`${t("receive-our-marketing-updates")}`}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={notifIsActive}
                                  onChange={handlePushNotificationChange}
                                  data-testid="notification-switch"
                                  name="sdm"
                                  size="medium"
                                />
                              }
                              label={`Allow Notifications`}
                            />
                          </Grid>
                          {VITE_EXPERIMENTAL_TOTP && (
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        value={formikProps.values.totpEnabled}
                                        onChange={(e: any) => {
                                          handleTotpPreferenceChange(
                                            e.target.checked
                                          );
                                          formikProps.setFieldValue(
                                            "totpEnabled",
                                            e.target.checked
                                          );
                                        }}
                                        {...formikProps}
                                      />
                                    }
                                    label={`Two-Factor Authentication`}
                                  />

                                  <Grid item xs={12}>
                                    <Modal
                                      sx={modalSx}
                                      width={isMobile ? "90%" : "30%"}
                                      height={"auto"}
                                      modalContentSx={{
                                        height: "auto",
                                      }}
                                      modalOverlaySx={modalOverlaySx}
                                      modalFooterSx={{
                                        maxWidth: "100%",
                                        ">div": {
                                          minWidth: "60%",
                                        },
                                      }}
                                      Header={
                                        <>
                                          <Grid item xs={12}>
                                            <Typography
                                              fontWeight={"bold"}
                                              sx={{ pl: 1 }}
                                              fontSize={"1.5em"}
                                            >
                                              Register 2FA
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12}>
                                            <Divider />
                                          </Grid>
                                        </>
                                      }
                                      message={
                                        <Grid
                                          item
                                          xs={12}
                                          justifyContent={"center"}
                                        >
                                          <Grid container>
                                            <Grid item xs={8} margin={"auto"}>
                                              {isFetchingOtpUrl && !otpUrl && (
                                                <SkeletonLoad bars={1} />
                                              )}
                                              {!isFetchingOtpUrl && otpUrl && (
                                                <TwoFA value={otpUrl?.data} />
                                              )}
                                            </Grid>
                                            <Grid item xs={8} margin={"auto"}>
                                              <Input
                                                name={"code"}
                                                label={"Enter your 2FA code"}
                                                placeholder="6-digit code"
                                                style={{ width: "100%" }}
                                                {...formikProps}
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      }
                                      closeButton={true}
                                      primary={{
                                        variant: "contained",
                                        children: `${t("confirm")}`,
                                        onClick: () =>
                                          registerMFA(
                                            `${formikProps.values?.code}`
                                          )
                                            .unwrap()
                                            .then(() => {
                                              onClose();
                                              formikProps.values.totpEnabled &&
                                                enableTotp({
                                                  enabled:
                                                    formikProps.values
                                                      .totpEnabled,
                                                })
                                                  .unwrap()
                                                  .then(() => {
                                                    toast(
                                                      "2FA enabled successfully",
                                                      { type: "success" }
                                                    );
                                                  })
                                                  .catch((err) =>
                                                    toast(
                                                      `2FA preference error: ${err?.message}`,
                                                      { type: "error" }
                                                    )
                                                  );
                                            })
                                            .catch((err) =>
                                              toast(
                                                `An error occured in registering MFA: ${err?.message}`
                                              )
                                            ),
                                      }}
                                      open={show}
                                      onClose={() => onClose()}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={1}>
                              <Grid item lg={3} xs={12}>
                                <LoadingButton
                                  variant="contained"
                                  fullWidth
                                  loading={isLoading}
                                  onClick={() =>
                                    handleVerifyPasswordRequest({
                                      email: user.email,
                                    })
                                  }
                                >
                                  {`${t("change-password")}`}
                                </LoadingButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Layout>
                </Form>
              );
            }}
          </Formik>
        )}
      </Box>
    </Slide>
  );
};
export default Settings;
