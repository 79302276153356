import {
  Stack,
  styled,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import toCamelCase from "helpers/camelCase";
import moment from "moment";
import React from "react";
import type { MapApprover } from "types";

interface Approval {
  [department: string]: MapApprover & {
    sub_approvals?: Record<string, MapApprover>;
  };
}

interface ApprovalHistoryTableFieldProps {
  department: string;
}

const getBgColor = (status: string) => {
  switch (status) {
    case "Approved":
      return "#9FCE63";
    case "Rejected":
      return "#FFEBEE";
    default:
      return "orange";
  }
};

const ApprovalField = styled(Typography)<{ status: string; theme?: Theme }>(
  ({ theme, status }) => ({
    backgroundColor: getBgColor(status),
    color: theme.palette.white.main,
    textAlign: "center",
    borderRadius: theme.shape.borderRadius,
    fontWeight: 600,
  })
);

const ApprovalHistoryTableField: React.FC<ApprovalHistoryTableFieldProps> = ({
  department: key,
}) => {
  const formikProps = useFormikContext<Approval>();
  //   const [open, setOpen] = useState(false);

  const DEPARTMENT_MAPPER: Record<string, string> = {
    risk: "Risk Department",
    legal: "Legal Department",
    head_compliance_buyer: "Group Head of Compliance - Buyer Account",
    head_compliance_supplier: "Group Head of Compliance - Supplier Account",
    head_compliance: "Compliance Department",
    ceo: "CEO",
  };

  // No customization for any department at the moment, but judging the business, this is likely to change very soon.

  //   const ComplianceSubTable = (children: React.ReactNode) => (
  //     <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
  //       <Collapse in={open} timeout="auto" unmountOnExit>
  //         <Table
  //           sx={{
  //             minWidth: 650,
  //             border: "1px solid #e0e0e0",
  //             bgcolor: "background.default",
  //           }}
  //           size="small"
  //           aria-label="approval-table"
  //         >
  //           <TableBody>{children}</TableBody>
  //         </Table>
  //       </Collapse>
  //     </TableCell>
  //   );

  return (
    <>
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": {
            border: 0,
          },
        }}
      >
        <TableCell component="th" scope="row">
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            {/* {key === "head_compliance" && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            )} */}
            <Typography>{DEPARTMENT_MAPPER[key]}</Typography>
          </Stack>
        </TableCell>
        <TableCell align={"left"}>
          <Typography
            sx={{
              color: formikProps.values[toCamelCase(key)]?.approver_name
                ? "primary.main"
                : "info.main",
              fontWeight: formikProps.values[toCamelCase(key)]?.approver_name
                ? 600
                : 500,
            }}
          >
            {formikProps.values[toCamelCase(key)]?.approver_name ?? "None"}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <ApprovalField
            status={formikProps.values[toCamelCase(key)]?.approval_status}
          >
            {formikProps.values[toCamelCase(key)]?.approval_date
              ? moment(
                  formikProps.values[toCamelCase(key)]?.approval_date
                ).format("DD/MM/YYYY")
              : "Pending"}
          </ApprovalField>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography
            sx={{
              color: formikProps.values[toCamelCase(key)]?.comments
                ? "primary.main"
                : "info.main",
              fontWeight: formikProps.values[toCamelCase(key)]?.comments
                ? 600
                : 500,
            }}
          >
            {formikProps.values[toCamelCase(key)]?.comments ?? "None"}
          </Typography>
        </TableCell>
      </TableRow>
      {/* {key === "head_compliance" && (
        <TableRow>
          {ComplianceSubTable(
            Object.entries(
              formikProps.values[toCamelCase(key)]?.sub_approvals ?? {}
            ).map(([subKey, _]) => (
              <ApprovalHistoryTableField key={subKey} department={subKey} />
            ))
          )}
        </TableRow>
      )} */}
    </>
  );
};

export default ApprovalHistoryTableField;
