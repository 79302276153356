import { Sync } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import {
  useGetComplianceProvidersQuery,
  useGetCreditReportDocumentsQuery,
} from "api/api-compliance";
import SkeletonLoad from "components/Common/SkeletonLoad";
import toCamelCase from "helpers/camelCase";
import React from "react";
import CreditReportUpload from "./CreditReportUpload";
import { getCreditReportDocument } from "./helper";

interface CreditReportDocumentsProps {
  account_id: string;
  onDocumentDeleted?: () => void;
  isLocked?: boolean;
}

const CreditReportDocuments: React.FC<CreditReportDocumentsProps> = (props) => {
  const { account_id, onDocumentDeleted, isLocked } = props;

  const { data: providersToDisplay = [], isLoading: isLoadingProviders } =
    useGetComplianceProvidersQuery();

  const {
    data: creditReportDocuments,
    isFetching: isFetchingDocuments,
    refetch: refetchDocuments,
  } = useGetCreditReportDocumentsQuery(account_id, {
    skip: !Boolean(account_id),
  });

  const handleDocumentDeleted = () => {
    refetchDocuments();
    if (onDocumentDeleted) {
      onDocumentDeleted();
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid
          container
          spacing={2}
          justifyContent={"start"}
          alignItems={"center"}
        >
          <Grid item>
            <Typography variant="h4">Documents</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => refetchDocuments()}>
              <Sync />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      {isFetchingDocuments && <SkeletonLoad bars={4} />}
      {!isFetchingDocuments &&
        providersToDisplay?.map((provider) => {
          const document = getCreditReportDocument(
            creditReportDocuments ?? [],
            provider
          );

          return (
            <Grid item xs={12} lg={6} key={provider}>
              <CreditReportUpload
                data={document}
                accountId={account_id ?? ""}
                provider={toCamelCase(provider)}
                onDelete={handleDocumentDeleted}
                isLocked={isLocked}
              />
            </Grid>
          );
        })}
    </Grid>
  );
};

export default CreditReportDocuments;
