import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import {
  Avatar,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import CriticalIcon from "assets/issue-icons/critical.svg?react";
import LowIcon from "assets/issue-icons/low.svg?react";
import MediumIcon from "assets/issue-icons/medium.svg?react";
import moment from "moment";
import type { SupportTicket } from "types";

const Ticketing = (data: SupportTicket) => {
  const theme = useTheme();
  const Icon = (status: string, style?: React.CSSProperties) => {
    switch (status) {
      case "High":
        return <CriticalIcon style={style} />;
      case "Medium":
        return <MediumIcon style={style} />;
      case "Low":
        return <LowIcon style={style} />;
      default:
        return <LowIcon style={style} />;
    }
  };

  return (
    <Stack spacing={1} px={1}>
      <Grid item xs={12}>
        <Typography
          fontWeight={"bold"}
          fontSize={"1em"}
          color={theme.palette.error.main}
        >
          {data.internal_name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack
          direction={"row"}
          spacing={1}
          justifyContent={"start"}
          alignItems={"center"}
        >
          <Grid item>
            <Avatar
              sx={{
                bgcolor: data?.submitted_by
                  ? theme.palette.primary.main
                  : theme.palette.secondary.main,
                width: "2em",
                height: "2em",
                mt: "3px",
              }}
            >
              {data?.submitted_by ? (
                <Typography
                  fontSize={"0.7em"}
                >{`${data?.submitted_by?.first_name?.charAt(0)}${data?.submitted_by?.last_name?.charAt(0)}`}</Typography>
              ) : null}
            </Avatar>
          </Grid>
          <Grid item xs={9}>
            <Stack>
              <Typography
                sx={{
                  maxWidth: "70%",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
                fontSize={"0.8rem"}
              >
                {data?.submitted_by
                  ? `${data?.submitted_by?.first_name} ${data?.submitted_by?.last_name}`
                  : "Unassigned"}
              </Typography>
              <Typography
                fontSize={"0.7em"}
                sx={{
                  bgcolor: theme.palette.info.main,
                  borderRadius: 1,
                  color: "#fff",
                  px: 1,
                  width: "fit-content",
                }}
              >
                {data?.submitter_department === "IT"
                  ? "Information Technology"
                  : data?.submitter_department}
              </Typography>
            </Stack>
          </Grid>
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <Typography
          fontWeight={"bold"}
          fontSize={"1rem"}
          sx={{
            maxWidth: "790%",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {data?.title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack
          dangerouslySetInnerHTML={{ __html: data?.content }}
          sx={{
            height: "4vh",
            overflow: "hidden",
            img: {
              display: "none",
            },
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          spacing={1}
        >
          <Grid item>
            <Stack>
              <Stack spacing={0.5} alignItems={"center"} direction={"row"}>
                <CalendarTodayIcon sx={{ fontSize: "0.9em" }} />
                <Typography fontSize={"0.85em"}>
                  {moment(data?.submitted_at)?.local().format("MMM d")}
                </Typography>
              </Stack>

              <Stack spacing={0.5} alignItems={"center"} direction={"row"}>
                <HourglassTopIcon sx={{ fontSize: "0.9em" }} />
                <Typography fontSize={"0.85em"}>
                  {data?.estimate_min}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item>
            <Tooltip title={`${data?.business_priority?.label} Priority`}>
              <Stack
                direction={"row"}
                width={"100%"}
                alignItems={"center"}
                spacing={1}
              >
                <IconButton>
                  {Icon(data?.business_priority?.label, {
                    width: "2ch",
                    height: "auto",
                  })}
                </IconButton>
                <Tooltip
                  title={`Assignee: ${data?.assignee?.first_name} ${data?.assignee?.last_name}`}
                  placement="right-end"
                >
                  <Avatar
                    sx={{
                      bgcolor: data?.submitted_by
                        ? theme.palette.tertiary.main
                        : theme.palette.secondary.main,
                      width: "2em",
                      height: "2em",
                      mt: "3px",
                    }}
                  >
                    {data?.submitted_by ? (
                      <Typography
                        fontSize={"0.7em"}
                      >{`${data?.assignee?.first_name?.charAt(0) ?? "U"}${data?.assignee?.last_name?.charAt(0) ?? "A"}`}</Typography>
                    ) : null}
                  </Avatar>
                </Tooltip>
              </Stack>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};
export default Ticketing;
