import { faker } from "@faker-js/faker";
import {
  Grid,
  InputLabel,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useCreateAccountMutation } from "api/api-accounts";
import { useRegisterInvitedUserMutation } from "api/api-auth";
import { useInviteInternalUserMutation } from "api/api-it";
import { useGetMapsQuery } from "api/api-maps";
import { useAddInterVieweeMutation } from "api/api-risk";
import {
  BUYER,
  INCOMLEND_INTERNAL_LINK,
  IT_LINK,
  SELLER,
  SUPPLIER,
} from "codes";
import SkeletonLoad from "components/Common/SkeletonLoad";
import moment from "moment";
import { SetStateAction, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ScriptModals from "./ScriptModals";

interface IFunction {
  [key: string]: {
    call: Function;
    params?: Record<string, any>;
    loader: boolean;
    additionalCall?: Function;
    onEnd?: (params?: any) => void;
    helperModal?: {
      title?: string;
      body?: JSX.Element;
      params?: Record<string, any>;
      call?: Function;
      onEnd?: (params?: any) => void;
      loader?: boolean;
    };
  };
}
interface Item {
  [key: string]: IFunction;
}

const customFunction = () => {};

interface Props {
  section: string;
  item: string;
}
const ScriptButton: React.FC<Props> = ({ section, item }) => {
  const [registerCompany, { isLoading: isLoadingRegisterCompany }] =
    useCreateAccountMutation();

  const { data: maps, isFetching } = useGetMapsQuery(null);
  const [addInterviewee, { isLoading: isLoadingAddInterviewee }] =
    useAddInterVieweeMutation();
  const [showHelperModal, setShowHelperModal] = useState<boolean>(false);

  const [tempValues, setTempValues] = useState<any>();

  const fillRisk = ({ addIntervieweeProps }: { addIntervieweeProps: any }) => {
    addInterviewee(addIntervieweeProps);
  };

  const [inviteInternalUser, { isLoading: isLoadingInternalUser }] =
    useInviteInternalUserMutation();

  const [registerUser, { isLoading: isLoadingRegisterUser }] =
    useRegisterInvitedUserMutation();

  const fakeUser = {
    first_name: faker.person.firstName(),
    last_name: faker.person.lastName(),
    title: faker.commerce.department(),
    email: faker.internet.email(),
    password: "Password@123",
    mobile: "+6593590000",
  };
  const userRegistration = () =>
    registerUser({
      first_name: fakeUser.first_name,
      last_name: fakeUser.last_name,
      title: fakeUser.title,
      email: fakeUser.email,
      password: fakeUser.password,
      mobile: fakeUser.mobile,
      accepted_terms: true,
      opted_marketing_updates: false,
    })
      .unwrap()
      .then((res) => {
        toast.success("User Created Successfully");
        window.open(
          `${INCOMLEND_INTERNAL_LINK}${IT_LINK}/user-management/${res?.data?.user_id}/manage`,
          "_blank"
        );
      })
      .catch(() => {
        toast.error("User Creation Failed");
      });
  useEffect(() => {
    console.log("showHelperModal", showHelperModal);
  }, [showHelperModal]);

  const scripts = {
    Account: {
      "Create an Account": {
        call: registerCompany,
        loader: isLoadingRegisterCompany,
        params: {
          name: faker.company.name(),
          reg_no: faker.airline.flightNumber({
            addLeadingZeros: true,
            length: 10,
          }),
          address_line1: faker.location.streetAddress(),
          address_line2: faker.location.streetAddress(),
          city: faker.location.city(),
          country: "SGP",
          postcode: faker.location.zipCode(),
          website: faker.internet.url(),
          is_identified: true,
          incorporation_date: moment(
            faker.date.past({ years: 10 }).getDate()
          ).format("YYYY-MM-DD"),
          company_type: tempValues,
          annual_revenue: 2000000000,
          industry: "Metals & Minerals",
          goods_category: "Zinc",
          client: false,
          accounting_software: "Tally ERP",
          number_of_employees: "11-50 employees",
        },
        onEnd: (params) => setShowHelperModal(false),
        helperModal: {
          title: "Select Account Type",
          call: console.log,
          params: {
            account_id: tempValues?.account_id,
            company_type: tempValues?.profile,
          },
          onEnd: (params) => setTempValues(params),
          loader: false,

          body: (
            <Grid item xs={12} lg={6}>
              <InputLabel id="demo-simple-select-label">{`Lead Type`}</InputLabel>

              <Select
                labelId="demo-simple-select-label"
                fullWidth
                id="demo-simple-select"
                value={tempValues}
                label=""
                onChange={(e: SelectChangeEvent<SetStateAction<any>>) =>
                  setTempValues(e.target.value)
                }
              >
                <MenuItem value={SELLER}>{SUPPLIER}</MenuItem>
                <MenuItem value={BUYER?.toLowerCase()}>{BUYER}</MenuItem>
              </Select>
            </Grid>
          ),
        },
      },
      "Create a Partner for an Account": {
        call: customFunction,
        loader: isLoadingRegisterCompany,
      },
      "Onboard an Account": {
        call: customFunction,
        loader: isLoadingRegisterCompany,
      },
    },

    "Credit Limit": {
      "Create a Credit Limit": {
        call: customFunction,
        loader: isLoadingRegisterCompany,
      },
      "Approve CL": {
        call: customFunction,
        loader: isLoadingRegisterCompany,
      },
      "Reject CL": {
        call: customFunction,
        loader: isLoadingRegisterCompany,
      },
    },

    Maps: {
      "Fill Compliance": {
        call: customFunction,

        loader: isLoadingRegisterCompany,
      },
      "Fill Risk": {
        call: fillRisk,
        props: {
          addIntervieweeProps: {
            map_id: tempValues?.map_id,
            account_type: "seller",
            data: {
              interviewee_name: faker.person.fullName(),
              interviewee_title: faker.person.jobTitle(),
            },
          },
        },
        loader: isLoadingRegisterCompany,
        helperModal: {
          title: "Select Map to Fill",
          call: console.log,
          params: {
            account_id: tempValues?.account_id,
            company_type: tempValues?.profile,
          },
          onEnd: (params) => setTempValues(params),
          loader: false,

          body: (
            <Grid item xs={12} lg={6}>
              <InputLabel id="demo-simple-select-label">{`Select Map`}</InputLabel>

              {Boolean(maps?.length) && (
                <Select
                  labelId="demo-simple-select-label"
                  fullWidth
                  id="demo-simple-select"
                  value={tempValues}
                  label=""
                  onChange={(e: SelectChangeEvent<SetStateAction<any>>) =>
                    setTempValues({
                      account_id: e.target.value,
                      company_type: SELLER,
                    })
                  }
                >
                  {maps?.map((map) => (
                    <MenuItem value={map?.map_id} key={map?.map_id}>
                      {map?.map_id}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Grid>
          ),
        },
      },
      "Fill Legal": {
        call: customFunction,
        loader: isLoadingRegisterCompany,
      },
    },
    Personas: {
      "Create an Internal User - Management Admin": {
        call: () =>
          inviteInternalUser({
            email: fakeUser.email,
            first_name: fakeUser.first_name,
            last_name: fakeUser.last_name,
            mobile: fakeUser.mobile,
            title: fakeUser.title,
            departments: ["MANAGEMENT"],
            groups: ["MANAGEMENT_ADMIN"],
          })
            .unwrap()
            .then(userRegistration)
            .catch(() => {
              toast.error("User Invite Failed");
            }),
        loader: isLoadingRegisterUser,
      },
      "Create an Internal User - Compliance Admin": {
        call: () =>
          inviteInternalUser({
            email: fakeUser.email,
            first_name: fakeUser.first_name,
            last_name: fakeUser.last_name,
            mobile: fakeUser.mobile,
            title: fakeUser.title,
            departments: ["COMPLIANCE"],
            groups: ["COMPLIANCE_ADMIN"],
          })
            .unwrap()
            .then(userRegistration)
            .catch(() => {
              toast.error("User Invite Failed");
            }),
        loader: isLoadingRegisterUser,
      },
      "Create an Internal User - Legal Admin": {
        call: () =>
          inviteInternalUser({
            email: fakeUser.email,
            first_name: fakeUser.first_name,
            last_name: fakeUser.last_name,
            mobile: fakeUser.mobile,
            title: fakeUser.title,
            departments: ["LEGAL"],
            groups: ["LEGAL_ADMIN"],
          })
            .unwrap()
            .then(userRegistration)
            .catch(() => {
              toast.error("User Invite Failed");
            }),
        loader: isLoadingRegisterUser,
      },
      "Create an Internal User - Risk Admin": {
        call: () =>
          inviteInternalUser({
            email: fakeUser.email,
            first_name: fakeUser.first_name,
            last_name: fakeUser.last_name,
            mobile: fakeUser.mobile,
            title: fakeUser.title,
            departments: ["RISK"],
            groups: ["RISK_ADMIN"],
          })
            .unwrap()
            .then(userRegistration)
            .catch(() => {
              toast.error("User Invite Failed");
            }),
        loader: isLoadingRegisterUser,
      },
      "Create an Internal User - ICPL Admin": {
        call: () =>
          inviteInternalUser({
            email: fakeUser.email,
            first_name: fakeUser.first_name,
            last_name: fakeUser.last_name,
            mobile: fakeUser.mobile,
            title: fakeUser.title,
            departments: ["ICPL"],
            groups: ["ICPL"],
          })
            .unwrap()
            .then(userRegistration)
            .catch(() => {
              toast.error("User Invite Failed");
            }),
        loader: isLoadingRegisterUser,
      },
      "Create an Internal User - Funding Admin": {
        call: () =>
          inviteInternalUser({
            email: fakeUser.email,
            first_name: fakeUser.first_name,
            last_name: fakeUser.last_name,
            mobile: fakeUser.mobile,
            title: fakeUser.title,
            departments: ["FUNDING"],
            groups: ["FUNDING_ADMIN"],
          })
            .unwrap()
            .then(userRegistration)
            .catch(() => {
              toast.error("User Invite Failed");
            }),
        loader: isLoadingRegisterUser,
      },
    },
  } as Item;

  const {
    call,
    loader = false,
    onEnd = console.log,
    params = {},
    helperModal: {
      call: modalCall,
      loader: modalLoader,
      title,
      body,
      onEnd: modalOnEnd = console.log,
      params: modalParams = {},
    } = {},
  } = scripts?.[section]?.[item];
  if (!scripts[section][item]) return null;
  if (loader) return <SkeletonLoad bars={2} />;

  return (
    <ListItemButton
      component="a"
      href="#script-list"
      onClick={(e) => {
        e.preventDefault();
        modalCall !== undefined ? setShowHelperModal(true) : call();
      }}
    >
      <ListItemText primary={item} />
      <ScriptModals
        item={item}
        call={call}
        onEnd={onEnd}
        modalOnEnd={modalOnEnd}
        showHelperModal={showHelperModal}
        setShowHelperModal={setShowHelperModal}
        title={title}
        body={body}
        params={params}
        modalCall={modalCall}
        modalLoader={modalLoader}
        tempValues={tempValues}
        modalParams={modalParams}
      />
    </ListItemButton>
  );
};

export default ScriptButton;
