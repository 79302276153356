import DeleteIcon from "@mui/icons-material/Delete";
import {
  Badge,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  NativeSelect,
  OutlinedInput,
  Typography,
  useTheme,
} from "@mui/material";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { FormikProps } from "formik";
import useIsMobile from "hooks/useIsMobile";
import moment from "moment";
import { ChangeEvent, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import type { CompanyDoc } from "types";
import DocumentUploadField from "./DocumentUploadField";
import styles from "./multiupload.module.scss";
interface Item {
  id: string;
  name: string;
  label: string;
  value: string;
  items: Item[];
  template?: any;
  description: string;
  count_unique_uploaded: number;
  yearly_set_count?: number;
}
const DocumentUploadSelect = ({
  item,
  onUpload,
  formikProps,
  isPresales,
  documents,
  initialData,
  onView,
  onDelete,
  enableCount = true,
  deleteDisabled = false,
  viewDisabled = false,
  uploadDisabled = false,
  disabled,
  isLoading,
  isLoadingSubmission,
}: {
  item: Item;
  onUpload: (
    data: { file: File; year: number | null },
    label: string,
    document_type: string,
    idx: number
  ) => void;
  formikProps: FormikProps<any>;
  isPresales?: boolean;
  documents?: CompanyDoc[];
  initialData?: CompanyDoc[];
  onView: (fileName: string, id: string, idx: number) => void;
  onDelete?: (fileName: string, id: string, idx: number) => void;
  enableCount?: boolean;
  deleteDisabled?: boolean;
  viewDisabled?: boolean;
  uploadDisabled?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  isLoadingSubmission?: boolean;
}) => {
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const targetValue = event.target.value;
    setSubItem(item.items.find((sub) => sub.value === targetValue));
  };
  const [subItem, setSubItem] = useState<Item>();
  const subItems = item.items.map((item) => item.id);
  const filesAlreadyUploaded = initialData?.filter((data) =>
    subItems.includes(data.document_type)
  );
  const { t } = useTranslation();
  const theme = useTheme();
  const isError = filesAlreadyUploaded?.length === 0;
  const isMobile = useIsMobile();

  return (
    <Grid
      container
      sx={{
        border: isError
          ? `1px solid ${theme.palette.secondary.main}`
          : `1px solid ${theme.palette.success.main}`,
        borderRadius: "10px",
        minHeight: "16vh",
        bgcolor: disabled ? "#ced4da" : theme.palette.background.paper,
      }}
    >
      {isLoading && <SkeletonLoad bars={2} />}
      {!isLoading && (
        <Fragment>
          {enableCount && (
            <Badge
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              sx={{ ".MuiBadge-badge": { width: "8ch", left: "3ch" } }}
              badgeContent={
                <Typography
                  sx={{
                    bgcolor:
                      item.count_unique_uploaded === item.yearly_set_count
                        ? "green"
                        : theme.palette.error.main,
                    color: "white !important",
                    padding: "0 0.9ch",
                    borderRadius: "8px",
                    fontSize: "1em",
                  }}
                >{`${item.count_unique_uploaded} of ${item.yearly_set_count}`}</Typography>
              }
            />
          )}

          <Grid item xs={11} sx={{ mt: 1 }} margin="auto">
            <Typography
              sx={{
                textTransform: "initial",
                fontSize: "1em",
                fontWeight: 600,
              }}
            >
              {item.label}
            </Typography>
          </Grid>
          {Boolean(filesAlreadyUploaded?.length) &&
            filesAlreadyUploaded?.map((document, idx) => (
              <Fragment key={idx}>
                <Grid item xs={11} margin="auto">
                  <Divider />
                </Grid>
                <Grid item xs={11} margin={"auto"}>
                  <Grid container alignItems={"center"}>
                    <Grid item lg={9} xs={8}>
                      <Grid container>
                        <Grid
                          item
                          lg={6}
                          className={`${styles.uploadBtnPlaceholder}`}
                        >
                          <Typography
                            fontSize={!isMobile ? "0.8em" : "0.6em"}
                            fontWeight={600}
                            sx={{ wordBreak: "break-word" }}
                          >
                            {document?.name?.split("-")[4] ?? document?.name}
                            {document?.year ? `- ${document?.year}` : ""}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          className={`${styles.uploadBtnPlaceholder}`}
                        >
                          <Typography
                            fontSize={!isMobile ? "0.7em" : "0.6em"}
                            color={theme.palette.secondary.main}
                          >
                            {`${t("submitted-on")}`}{" "}
                            {moment(document?.submitted).format("YYYY/MM/DD")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={3} xs={4}>
                      <Grid
                        container
                        justifyContent={"end"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        <Grid item>
                          <Button
                            fullWidth
                            sx={{
                              fontSize: !isMobile ? "0.8em" : "0.6em",
                              height: "auto !important",
                              padding: 0,
                            }}
                            onClick={() => {
                              onView(document?.name, document?.id, idx);
                            }}
                          >
                            View
                          </Button>
                        </Grid>
                        <Grid item>
                          {isLoadingSubmission ? (
                            <Grid item>
                              <CircularProgress size={15} />
                            </Grid>
                          ) : (
                            onDelete && (
                              <IconButton
                                edge="start"
                                color="inherit"
                                sx={{ p: 0 }}
                                disabled={deleteDisabled}
                                onClick={() =>
                                  onDelete(document?.name, document?.id, idx)
                                }
                              >
                                <DeleteIcon
                                  color={deleteDisabled ? "disabled" : "error"}
                                  fontSize={!isMobile ? "large" : "medium"}
                                />
                              </IconButton>
                            )
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Fragment>
            ))}
          {Boolean(filesAlreadyUploaded?.length) && (
            <Grid item xs={12} sx={{ mt: 1 }} margin="auto">
              <Divider sx={{ borderColor: "rgb(0 0 0 / 68%)" }} />
            </Grid>
          )}
          <Grid item xs={11} sx={{ mt: 1, mb: 1 }} margin="auto">
            <NativeSelect
              defaultValue={""}
              variant={"outlined"}
              disabled={disabled}
              input={<OutlinedInput label="" />}
              inputProps={{
                name: item.label,
                id: item.label,
              }}
              fullWidth
              sx={{
                fontSize: "1em",
                textTransform: "capitalize",
                borderRadius: "10px",
                ".MuiNativeSelect-select": {
                  padding: "7px !important",
                },
                ">div": {
                  borderRadius: "10px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  maxWidth: "76%",
                  textOverflow: "ellipsis",
                },
              }}
              onChange={(event) => handleChange(event)}
            >
              <option value={""}>{"Select Document from List"}</option>
              {item.items?.map((sub) => (
                <option value={sub.value} key={sub.name}>
                  {sub.label}
                </option>
              ))}
            </NativeSelect>
          </Grid>
          {subItem && (
            <Grid item xs={12}>
              <DocumentUploadField
                item={subItem}
                onUpload={onUpload}
                formikProps={formikProps}
                isPresales={isPresales}
                documents={documents}
                onView={onView}
                onDelete={onDelete}
                enableCount={enableCount}
                deleteDisabled={deleteDisabled}
                viewDisabled={viewDisabled}
                uploadDisabled={uploadDisabled}
                disabled={disabled}
                isLoading={isLoading}
                isLoadingSubmission={isLoadingSubmission}
              />
            </Grid>
          )}
        </Fragment>
      )}
    </Grid>
  );
};
export default DocumentUploadSelect;
