import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Switch,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Logout from "@mui/icons-material/Logout";
import { BrowserView, MobileView } from "react-device-detect";
import PerfectScrollbar from "react-perfect-scrollbar";

import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Settings } from "@mui/icons-material";
// import {
//   useGetNotifIsActiveQuery,
//   useUpdateNotifIsActiveMutation,
// } from "api/api-notifications";
import {
  useFetchUserProfilePicQuery,
  useGetCurrentUserQuery,
} from "api/api-users-v2";
import SkeletonLoad from "components/Common/SkeletonLoad";
import auth from "helpers/auth";
import logout from "helpers/logout";
import {
  checkSubscription,
  triggerSubscription,
  unsubscribeUser,
} from "lib/webPush";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import RolesSwitcher from "../Header/RolesSwitcher";

// ==============================|| PROFILE -SIDEBAR DRAWER ||============================== //

const ProfileSidebar = ({
  drawerOpen,
  drawerToggle,
}: {
  drawerOpen: boolean;
  drawerToggle: Dispatch<SetStateAction<boolean>>;
}) => {
  auth();
  const theme = useTheme();

  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const drawerWidth = 260;
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { data: user, isFetching } = useGetCurrentUserQuery();
  const [notifIsActive, setNotifIsActive] = useState(false);
  const { data: currentUser } = useGetCurrentUserQuery();

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      const isSubscribed = await checkSubscription();
      setNotifIsActive(isSubscribed);
    };

    checkSubscriptionStatus();
  }, []);

  const handlePushNotificationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNotifIsActive(event.target.checked);
    if (event.target.checked) triggerSubscription(currentUser?.id ?? "");
    if (!event.target.checked) unsubscribeUser();
  };

  const dispatch = useDispatch();
  const handleLogout = async () => {
    logout(dispatch);
  };
  const { data: profilePic, status } = useFetchUserProfilePicQuery(undefined, {
    skip: !Boolean(user),
    refetchOnFocus: true,
  });
  const handleListItemClick = (index: SetStateAction<number>, route = "") => {
    setSelectedIndex(index);
    if (route && route !== "") {
      navigate(route);
    }
  };

  const drawer = (
    <>
      <BrowserView>
        <PerfectScrollbar
          component="div"
          style={{
            height: !matchUpMd ? "calc(100vh - 56px)" : "calc(100vh - 88px)",
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
          data-testid="profile-sidebar-scrollbar"
        >
          <Stack justifyContent={"space-between"} height={"100%"}>
            <Box sx={{ p: 2 }}>
              <Stack>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Typography fontSize={"1.3em"} fontWeight={"bold"}>
                    {`${user?.first_name} ${user?.last_name}`}
                  </Typography>
                </Stack>
                {user?.title && <Typography>{user?.title}</Typography>}
                <RolesSwitcher data-testid="profile-sidebar-roles-switcher" />
              </Stack>
            </Box>
            <PerfectScrollbar
              style={{
                height: "100%",
                overflowX: "hidden",
              }}
              data-testid="profile-sidebar-profile-section"
            >
              <Box sx={{ p: 2 }}>
                <List
                  component="nav"
                  sx={{
                    width: "100%",
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: "10px",
                    [theme.breakpoints.down("md")]: {
                      minWidth: "100%",
                    },
                    "& .MuiListItemButton-root": {
                      mt: 0.5,
                    },
                  }}
                  data-testid="profile-sidebar-list"
                >
                  <ListItemButton
                    sx={{ borderRadius: `${theme.shape.borderRadius}px` }}
                    selected={selectedIndex === 0}
                    onClick={() => handleListItemClick(0, "/settings")}
                    data-testid="account-settings"
                  >
                    <ListItemIcon>
                      <Settings />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography fontWeight={"bold"}>
                          Account Settings
                        </Typography>
                      }
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ borderRadius: `${theme.shape.borderRadius}px` }}
                    selected={selectedIndex === 1}
                    onClick={handleLogout}
                    data-testid="logout"
                  >
                    <ListItemIcon>
                      <Logout />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography fontWeight={"bold"}>Logout</Typography>
                      }
                    />
                  </ListItemButton>
                </List>
              </Box>
            </PerfectScrollbar>
            <Box
              sx={{
                p: 2,
                backgroundColor: theme.palette.background.paper,
                mt: 2,
              }}
            >
              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
              >
                <Grid item>
                  <Typography>Allow Notifications</Typography>
                </Grid>
                <Grid item>
                  <Switch
                    checked={notifIsActive}
                    onChange={handlePushNotificationChange}
                    data-testid="notification-switch"
                    name="sdm"
                    size="medium"
                  />
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <Stack justifyContent={"space-between"} height={"100%"}>
            <Box sx={{ p: 2 }}>
              <Stack spacing={1}>
                <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
                  {isFetching && <SkeletonLoad bars={1} />}
                  {!isFetching && (
                    <Avatar
                      src={profilePic?.url}
                      sx={{
                        margin: "8px 0 8px 8px !important",
                        cursor: "pointer",
                        bgcolor: theme.palette.text.primary,
                        color: theme.palette.background.default,
                      }}
                      color="inherit"
                    >{`${user?.first_name?.charAt(0)?.toUpperCase()}
            ${user?.last_name?.charAt(0)?.toUpperCase()}`}</Avatar>
                  )}
                  <Typography variant={"h5"} fontWeight={"bold"}>
                    {`${user?.first_name} ${user?.last_name}`}
                  </Typography>
                  {user?.title && <Typography>{user?.title}</Typography>}
                </Stack>
                <RolesSwitcher data-testid="profile-sidebar-roles-switcher" />
              </Stack>
            </Box>
            <Divider />
            <Grid item>
              <Grid
                item
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography variant="subtitle1">
                    Allow Notifications
                  </Typography>
                </Grid>
                <Grid item>
                  {/* <Switch
                    checked={notifIsActive?.isNotifActive}
                    onChange={handlePushNotificationChange}
                    name="notification-status"
                    size="small"
                    data-testid="notification-switch"
                  /> */}
                </Grid>
              </Grid>
            </Grid>
            <PerfectScrollbar
              style={{
                height: "100%",
                overflowX: "hidden",
              }}
            >
              <Box sx={{ p: 2 }}>
                <List
                  component="nav"
                  sx={{
                    width: "100%",
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: "10px",
                    [theme.breakpoints.down("md")]: {
                      minWidth: "100%",
                    },
                    "& .MuiListItemButton-root": {
                      mt: 0.5,
                    },
                  }}
                  data-testid="profile-sidebar-list"
                >
                  <ListItemButton
                    sx={{ borderRadius: `${theme.shape.borderRadius}px` }}
                    selected={selectedIndex === 0}
                    onClick={() => handleListItemClick(0, "/settings")}
                  >
                    <ListItemIcon>
                      <Settings />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography fontWeight={"bold"}>
                          Account Settings
                        </Typography>
                      }
                    />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ borderRadius: `${theme.shape.borderRadius}px` }}
                    selected={selectedIndex === 1}
                    onClick={handleLogout}
                    data-testid="logout"
                  >
                    <ListItemIcon>
                      <Logout />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography fontWeight={"bold"}>Logout</Typography>
                      }
                    />
                  </ListItemButton>
                </List>
              </Box>
            </PerfectScrollbar>
          </Stack>
        </Box>
      </MobileView>
    </>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { md: 0 },
        width: matchUpMd ? drawerWidth : "auto",
        bgcolor: theme.palette.background.default,
      }}
      aria-label="profile-sidebar-box"
    >
      <Drawer
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor="right"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            bgcolor: theme.palette.background.default,
            color: theme.palette.primary.main,
            borderLeft: "none",
            [theme.breakpoints.up("md")]: {
              top: "88px",
            },
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
        data-testid="profile-sidebar-drawer"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default ProfileSidebar;
