import AddIcon from "@mui/icons-material/Add";
import { Box, Slide, Typography, useTheme } from "@mui/material";
import { useCreateTicketMutation, useGetTicketsQuery } from "api/api-support";
import { INCOMLEND_INTERNAL_LINK, SUPPORT } from "codes";
import Board from "components/Common/Board";
import Layout from "components/Common/Layout";
import Modal from "components/Common/Modal";
import SkeletonLoad from "components/Common/SkeletonLoad";
import CreateTicket from "components/Ticket/CreateTicket";
import { Form, Formik } from "formik";
import history from "helpers/history";
import useIsMobile from "hooks/useIsMobile";
import moment from "moment";
import { useState } from "react";
import { toast } from "react-toastify";
import secondaryButtonStyle from "styles/secondaryButtonStyle";
import type { SupportTicket } from "types";
import styles from "./support.module.scss";

interface Ticket {
  title: string;
  content: string;
  priority_code_id?: string;
  assignee_department_id: string;
  estimate_min?: string;
}

const formatTickets = (data: { [status: string]: SupportTicket[] }): any[] => {
  let result: any[] = [];
  for (let value in data) {
    if (Boolean(data[value]?.length))
      result.push(
        ...data[value].map((item: SupportTicket) => ({
          status: value,
          id: item.internal_name,
          name: item.title,
          data: item,
          value: item.estimate_min,
        }))
      );
    else
      result.push({
        id: "",
        link: "",
        status: value,
        name: "",
        data: {},
        value: 0,
      });
  }
  return result;
};

const SupportPage = () => {
  const { data: tickets, isFetching, refetch } = useGetTicketsQuery(null);
  const theme = useTheme();
  const isMobile = useIsMobile();

  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [createTicket, { isLoading }] = useCreateTicketMutation();
  const modalOverlaySx = {
    backgroundColor: "white !important",
    width: "50%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "2ch",
    padding: "2ch",
    fontSize: `${theme.typography.fontSize}px !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    height: "inherit !important",
    fontWeight: "inherit !important",
  };
  const modalSx = {
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    "&:focus-visible": {
      outline: "none",
    },
  };

  const initialValues: Ticket = {
    title: "",
    content: "",
    assignee_department_id: "",
  };

  return (
    <Slide in direction={"left"}>
      <Box sx={{ display: "flex" }} flexGrow={1}>
        <Layout
          title={`Support Tickets - Sprint ${moment().week()}`}
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.2em" : "1.5em",
          }}
          className={styles.layout}
          headerConfig={{
            syncAction: refetch,
            left: 10,
            mid: 0,
            right: 2,
            xs: {
              left: 12,
              mid: 0,
              right: 12,
            },
            alignItems: "center",
          }}
          primary={{
            startIcon: <AddIcon />,
            children: "Create",
            onClick: () => setOpenCreate(true),
            fullWidth: true,
          }}
          mode="default"
        >
          {isFetching && !tickets?.length && <SkeletonLoad bars={10} />}
          {!isFetching && tickets && (
            <>
              <Board
                data={formatTickets(tickets)}
                type="Ticketing"
                action={(ticketName: string) =>
                  history.push(
                    `${INCOMLEND_INTERNAL_LINK}${SUPPORT}/${ticketName}`
                  )
                }
                showSummary={false}
                defaultConsolidate={false}
              />
              <Formik
                initialValues={initialValues}
                onSubmit={console.log}
                enableReinitialize
              >
                {(formikProps) => (
                  <Form>
                    <Modal
                      Header={
                        <Typography fontWeight={"bold"} fontSize={"1.3em"}>
                          Create Ticket
                        </Typography>
                      }
                      message={<CreateTicket />}
                      modalHeaderSx={{
                        mb: 2,
                        ".MuiGrid-item:nth-child(1)": {
                          width: "90%",
                          display: "grid",
                          pl: 1.5,
                          alignContent: "center",
                        },
                      }}
                      sx={modalSx}
                      width={isMobile ? "90%" : "40%"}
                      height={"auto"}
                      modalContentSx={{ height: "auto" }}
                      open={openCreate}
                      modalOverlaySx={modalOverlaySx}
                      modalFooterSx={{ maxWidth: "100%" }}
                      onClose={() => setOpenCreate(false)}
                      primary={{
                        children: "Create",
                        loading: isLoading,
                        onClick: () =>
                          createTicket({
                            title: formikProps.values.title,
                            content: formikProps.values.content,
                            assignee_department_id:
                              formikProps.values.assignee_department_id,
                          })
                            .unwrap()
                            .then(() => {
                              toast("Ticket created successfully!", {
                                type: "success",
                              });
                              setOpenCreate(false);
                            })
                            .catch((err) => {
                              toast(`An error occured: ${err?.message}`, {
                                type: "error",
                              });
                              setOpenCreate(false);
                            }),
                        variant: "contained",
                      }}
                      secondary={{
                        children: "Cancel",
                        onClick: () => setOpenCreate(false),
                        sx: secondaryButtonStyle,
                        variant: "outlined",
                      }}
                      closeButton
                    />
                  </Form>
                )}
              </Formik>
            </>
          )}
        </Layout>
      </Box>
    </Slide>
  );
};
export default SupportPage;
