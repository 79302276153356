import { ArrowCircleRight, Info } from "@mui/icons-material";
import {
  Avatar,
  IconButton,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CreditLimitRequest from "assets/navigationIcons/creditLimitRequests.svg?react";
import { ACCOUNTS, CREDIT_LIMITS_LINK, INCOMLEND_INTERNAL_LINK } from "codes";
import history from "helpers/history";
import moment from "moment";

import React from "react";
import type { CLDetails } from "types";

interface PendingCofaceRequestProps {
  creditLimit: CLDetails;
}

const PendingCofaceRequest: React.FC<PendingCofaceRequestProps> = (props) => {
  const { creditLimit } = props;

  return (
    <ListItem
      onClick={() =>
        history.push(
          `${INCOMLEND_INTERNAL_LINK}${CREDIT_LIMITS_LINK}/${creditLimit.reqId}`
        )
      }
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="go-to-creditLimit"
          onClick={() =>
            window.open(
              `${INCOMLEND_INTERNAL_LINK}${CREDIT_LIMITS_LINK}/${creditLimit.reqId}`,
              "_blank"
            )
          }
        >
          <ArrowCircleRight color="error" />
        </IconButton>
      }
      sx={{
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 1,
        cursor: "pointer",
        my: 0.5,
        ":hover": {
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.50) inset",
        },
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.10) inset",
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "error.main" }}>
          <CreditLimitRequest />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Stack direction={"row"} spacing={1}>
            <Typography
              fontWeight={"bold"}
            >{`${creditLimit.reqId}:`}</Typography>
            <Typography>Buyer</Typography>
            <Link
              color={"error"}
              fontWeight={"bold"}
              fontSize={"1.1em"}
              href={`${INCOMLEND_INTERNAL_LINK}${ACCOUNTS}/${creditLimit.buyerInternalName}`}
              children={creditLimit.buyerName}
              target={"_blank"}
            />

            <Typography>{`Sales Lead`}</Typography>
            <Typography
              color={"error"}
            >{`${creditLimit.salesName}`}</Typography>
            <Typography>{`-`}</Typography>
            <Typography
              color={"error"}
            >{`${-moment(creditLimit.dateRequested).diff(moment(), "days")} day(s)`}</Typography>
            <Typography>{`ago`}</Typography>
            <Tooltip
              title={`Requested date: ${moment(creditLimit.dateRequested).format("DD/MM/YY")}`}
            >
              <Info />
            </Tooltip>
          </Stack>
        }
      />
    </ListItem>
  );
};

export default PendingCofaceRequest;
