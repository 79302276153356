import { Box, Grid, Slide } from "@mui/material";
import {
  useDeleteBankAccountMutation,
  useGetBankAccountQuery,
} from "api/api-banks";
import { BUYER } from "codes";
import Breadcrumbs from "components/Common/Breadcrumbs";
import Layout from "components/Common/Layout";
import Loader from "components/Common/Loader";
import SkeletonLoad from "components/Common/SkeletonLoad";
import ViewEditBankAccount from "components/Onboard/Bank/ViewEditBankAccount";
import history from "helpers/history";
import isObjectEmpty from "helpers/isObjectEmpty";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import type { BankAccount } from "types";

const BankAccountProfile = () => {
  const { bankaccount, partner_id = "" } = useParams();
  const { location } = history;
  const state: Record<string, any> = location;
  const { company_id } = state.state;
  const [bank, setBank] = useState<BankAccount>();
  const {
    data: fetchedBank,
    isLoading: isLoadingbank,
    isFetching: isFetchingBank,
    status,
  } = useGetBankAccountQuery(
    { account_id: company_id, bankaccount },
    { skip: !Boolean(bankaccount) }
  );
  const profile = useAppSelector((state) => state.appState.profile);
  const isReverseFactoring = profile === BUYER?.toLowerCase();
  const [deleteBankAccount, { isLoading: isLoadingDeletebankAccount }] =
    useDeleteBankAccountMutation();
  useEffect(() => {
    if (!isFetchingBank && status === "fulfilled" && fetchedBank)
      setBank(fetchedBank);
  }, [isFetchingBank]);

  if (!bankaccount) history.back();
  return (
    <Slide in direction={"left"}>
      <Box id="banks-box" sx={{ width: "100%" }}>
        <Layout
          title={`Bank Account - ${bank?.bank_name}`}
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.2em" : "1.5em",
          }}
          headerConfig={{
            left: 10,
            mid: 0,
            right: 2,
          }}
          mode="default"
          primary={{
            children: "Delete",
            variant: "contained",
            fullWidth: true,
            color: "error",
            onClick: () => {
              bank?.id &&
                deleteBankAccount({
                  account_id: company_id,
                  bank_account_id: bank?.id ?? "",
                })
                  .unwrap()
                  .then(() => {
                    toast.success("Bank account deleted successfully");
                    history.back();
                  })
                  .catch((error) => {
                    toast.error(error.data.message);
                  });
            },
          }}
        >
          {!isObjectEmpty(bank as object) ? (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Breadcrumbs
                  data={[
                    { name: "Banks", link: "/companies" },
                    {
                      name: `${bank?.bank_name}`,
                      link: `${location.pathname}`,
                      active: true,
                    },
                  ]}
                />
              </Grid>
              {isFetchingBank && <SkeletonLoad bars={10} />}
              {!isFetchingBank && bank && (
                <Grid item xs={12}>
                  <ViewEditBankAccount
                    bankAccount={bank}
                    account_id={company_id}
                    partner_id={partner_id}
                  />
                </Grid>
              )}
            </Grid>
          ) : (
            <Loader open={isLoadingbank} />
          )}
        </Layout>
      </Box>
    </Slide>
  );
};
export default BankAccountProfile;
