import { Grid } from "@mui/material";
import {
  useAssociateDocsMutation,
  useDeleteDocMutation,
  useGetAccountDocumentsQuery,
  useGetDocumentContentQuery,
  useGetDocumentUrlQuery,
  useGetDocumentsQuery,
} from "api/api-accounts";
import Modal from "components/Common/Modal";

import { BUYER } from "codes";
import DocView from "components/Common/DocView";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { Formik } from "formik";
import toCamelCase from "helpers/camelCase";
import { getFileTypeFromMagicNumber } from "helpers/getFileTypeFromMagicNumber";
import { t } from "i18next";
import { useState } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import type { AssociateCompanyDocs } from "types";
import DocumentUploadController from "../DocumentUploadController";
import styles from "../upldocuments.module.scss";

const DocumentList = ({
  enableCount = true,
  initialValues,
  entity_id,
  document_category,
  partner,
  forProfile,
  required = false,
}: {
  initialValues: Record<string, any>;
  enableCount?: boolean;
  entity_id: string;
  document_category: string;
  partner: boolean;
  forProfile?: string;
  required?: boolean;
}) => {
  const [associateDocs, { isLoading }] = useAssociateDocsMutation();
  const USER_ROLE = useAppSelector((state) => state.appState.role);
  const profile = useAppSelector((state) => state.appState.profile);

  const { data: accountDocs, isFetching: isFetchingAccountDocs } =
    useGetAccountDocumentsQuery(entity_id, {
      skip: !Boolean(entity_id),
    });
  const { data: pendingDocs, isFetching } = useGetDocumentsQuery(
    {
      account_id: entity_id ?? "",
    },
    { skip: !Boolean(entity_id) }
  );

  //DUC start
  const docDictionary: any =
    pendingDocs?.data
      ?.filter((item) => item.is_mandatory === required)
      .map((doc) => ({
        ...doc,
        name: toCamelCase(doc.display_name),
        label: doc.display_name,
        value: doc.display_name,
        items: doc.items.map((sub) => ({
          ...sub,
          name: toCamelCase(sub.display_name),
          label: sub.display_name,
          value: sub.display_name,
          yearly_set_count: sub.yearly_set_count,
        })),
      })) ?? [];

  const DOCUMENT_TYPES = pendingDocs?.data?.map((doc) => ({
    name: doc.display_name,
    description: doc.description,
  }));
  function base64ToBlob(base64String: string, mimeType: string) {
    const base64WithoutPrefix = base64String.replace(/^data:[^;]+;base64,/, "");
    const decodedData = atob(base64WithoutPrefix);

    const arrayBuffer = new ArrayBuffer(decodedData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < decodedData.length; i++) {
      uint8Array[i] = decodedData.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeType });
  }

  const onUpload = (
    data: { file: File; year: number | null },
    label: string,
    document_type: string,
    idx: number
  ) => {
    const associateRequestBody: AssociateCompanyDocs = {
      //if its partner, then the category changes to partner's
      document_category: forProfile
        ? forProfile
        : partner
          ? profile === BUYER.toLowerCase()
            ? "seller"
            : BUYER.toLowerCase()
          : profile,
      file: data?.file,
      document_type,
    };
    if (data?.year) associateRequestBody["year"] = `${data?.year}`;
    if (partner) associateRequestBody["partner_id"] = entity_id;
    associateDocs({ id: USER_ROLE?.id, data: associateRequestBody })
      .unwrap()
      .then(() => {
        const documentName =
          DOCUMENT_TYPES?.find(({ name }) => toCamelCase(name) === label)
            ?.name ?? "";
        toast(`Uploaded ${documentName} succesfully!`, {
          type: "success",
        });
      })
      .catch((error) => {
        const isDuplicateDocumentMessage =
          error?.message?.startsWith("Duplicate document");
        const errorFile = error?.message?.split(":")[1]?.trim();
        toast(
          `${
            isDuplicateDocumentMessage
              ? t("document-already-exists")
              : error?.message
          } : ${errorFile}`,
          {
            type: "error",
          }
        );
      });
  };

  const [document_id, setDocumentId] = useState<string>();

  const { data: documentContent } = useGetDocumentContentQuery(
    {
      account_id: entity_id,
      document_id: document_id ?? "",
    },
    { skip: !Boolean(document_id) }
  );

  const downloadDocument = () => {
    const binaryData = documentContent ?? "";
    const filetype = getFileTypeFromMagicNumber(binaryData);
    const blob = base64ToBlob(binaryData, filetype ?? "");
    const extension = (): string => {
      if (filetype === "image/jpeg") {
        return "jpg";
      } else if (filetype === "image/png") {
        return "png";
      } else if (filetype === "application/pdf") {
        return "pdf";
      }
      return "jpg";
    };
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", `download.${extension()}`);
    document.body.appendChild(link);
    link.click();
  };
  const { data: documentUrl } = useGetDocumentUrlQuery(
    {
      account_id: entity_id,
      document_id: document_id ?? "",
    },
    { skip: !Boolean(document_id) }
  );
  const [show, setShow] = useState<boolean>(false);
  const onOpen = () => setShow(true);
  const onClose = () => setShow(false);
  const [deleteDoc, { isLoading: isLoadingDelete }] = useDeleteDocMutation();

  const fetchDocument = (id: string) => {
    setDocumentId(id);
    onOpen();
  };
  const deleteDocument = (id: string) =>
    deleteDoc({ account_id: entity_id, id })
      .unwrap()
      .catch((error) => {
        const errorFile = error?.message;
        toast(`Something went wrong : ${errorFile}`, {
          type: "error",
        });
      });
  //DUC end

  return (
    <Grid item xs={12}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={() => {}}
      >
        {(muFormikProps) => {
          return (
            <>
              {isFetchingAccountDocs && <SkeletonLoad bars={1} />}
              {!isFetchingAccountDocs && accountDocs && (
                <DocumentUploadController
                  data={docDictionary}
                  formikProps={muFormikProps}
                  initialData={accountDocs}
                  onUpload={onUpload}
                  onView={(fileName: string, id: string, idx: number) =>
                    fetchDocument(id)
                  }
                  onDelete={(fileName: string, id: string, idx: number) =>
                    deleteDocument(id)
                  }
                  enableCount={enableCount}
                  isLoading={isLoading}
                  isLoadingSubmission={isLoadingDelete}
                />
              )}
              <Modal
                modalOverlaySx={{ borderRadius: "2ch" }}
                width={"30%"}
                height={"auto"}
                closeButton
                message={
                  <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center">
                      {documentUrl?.url?.includes(".pdf?") ? (
                        <Grid item xs={12} id="modal-docview">
                          <DocView
                            uri={documentUrl?.url}
                            staticView
                            pageClassName={styles.pageClass}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={12}>
                          <img
                            src={documentUrl?.url}
                            alt={documentUrl?.url}
                            width="100%"
                            height="auto"
                            style={{
                              borderRadius: "2ch",
                              margin: "auto",
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                }
                open={show}
                primary={{
                  children: "Download",
                  onClick: () => document_id && downloadDocument(),
                }}
                onClose={onClose}
              />
            </>
          );
        }}
      </Formik>
    </Grid>
  );
};
export default DocumentList;
