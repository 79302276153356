import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import EditIcon from "@mui/icons-material/Edit";
import ForumIcon from "@mui/icons-material/Forum";
import SyncIcon from "@mui/icons-material/Sync";
import {
  Avatar,
  Divider,
  Grid,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { CSSProperties } from "react";
import type { TicketActivityLog as TALog } from "types";

const TicketActivityLog = ({
  activities,
  minHeight = "60vh",
}: {
  activities: TALog[] | undefined;
  minHeight?: CSSProperties["minHeight"];
}) => {
  const theme = useTheme();
  const ACTIVITY_ICON: {
    [type: string]: React.ReactNode;
  } = {
    INSERT: <ForumIcon color="primary" />,
    UPDATE: <EditIcon color="secondary" />,
    approval: <CheckCircleIcon color="success" />,
    reject: <DoDisturbIcon color="error" />,
  };
  return (
    <Paper
      variant="outlined"
      elevation={3}
      square={false}
      sx={{
        padding: "1ch 3ch",
        borderRadius: 3,
      }}
    >
      <Stack spacing={1}>
        <Grid container spacing={1} justifyContent={"start"}>
          <Grid item>
            <Typography
              fontWeight={600}
              fontSize={"1.1em"}
              textAlign={"center"}
            >
              ACTIVITY LOG
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip
              children={<SyncIcon sx={{ ":hover": { cursor: "pointer" } }} />}
              title={"Sync latest changes"}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          sx={{
            overflow: "scroll",
            mb: 2,
            "::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
          justifyContent={"start"}
        >
          {!activities && (
            <Grid item xs={12}>
              <Typography sx={{ minHeight }} textAlign="center">
                No activity logged
              </Typography>
            </Grid>
          )}
          {activities && Boolean(activities.length) && (
            <Grid item xs={12} mt={3}>
              {activities.map((activity) => (
                <Grid container alignItems={"start"} spacing={2}>
                  <Grid item xs={1}>
                    <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                      {activity.user.first_name?.charAt(0)}
                      {activity.user.last_name?.charAt(0)}
                    </Avatar>
                  </Grid>
                  <Grid item xs={11} mt={1}>
                    <Stack spacing={1} justifyContent={"start"}>
                      <Stack direction={"row"} spacing={1}>
                        <Typography textAlign="center" fontWeight={"bold"}>
                          {activity.user.first_name} {activity.user.last_name}
                        </Typography>
                        <Typography textAlign="start">
                          {ACTIVITY_ICON[activity.operation]}
                        </Typography>
                        <Typography textAlign="center" fontStyle={"italic"}>
                          {activity.date}
                        </Typography>
                      </Stack>

                      {activity.changes?.map((change) => (
                        <Stack direction={"row"} spacing={1}>
                          <Typography textAlign="start" fontWeight={"bold"}>
                            {change?.field}
                          </Typography>
                          <Typography textAlign="start">
                            {typeof change?.old === "string"
                              ? change?.old
                              : "Object"}
                          </Typography>
                          <Typography textAlign="start">{"->"}</Typography>
                          <Typography textAlign="start" fontWeight={"bold"}>
                            {typeof change?.new === "string"
                              ? change?.new
                              : "Object"}
                          </Typography>
                        </Stack>
                      ))}
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Stack>
    </Paper>
  );
};
export default TicketActivityLog;
