import { Avatar, Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import type { INotification } from "types";
import NotificationList from "./NotificationList";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const NotificationTypeTabs: React.FC<{ notifications: INotification[] }> = ({
  notifications,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`notifications-tabpanel-${index}`}
        aria-labelledby={`notifications-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `notifications-tab-${index}`,
      "aria-controls": `notifications-tabpanel-${index}`,
    };
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab
            label={
              <Stack direction="row" spacing={1} alignItems={"center"}>
                <Typography fontSize={"1em"}>Unread</Typography>
                <Avatar
                  sx={{
                    backgroundColor: "error.main",
                    color: "primary.contrastText",
                    width: "1.5em",
                    height: "1.5em",
                    fontSize: "1.3em",
                  }}
                >
                  {notifications?.filter((n) => !n?.notif_is_read)?.length}
                </Avatar>
              </Stack>
            }
            {...a11yProps(0)}
          />
          <Tab label="Read" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <NotificationList
          notifications={notifications?.filter((n) => !n?.notif_is_read)}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <NotificationList
          notifications={notifications?.filter((n) => n?.notif_is_read)}
        />
      </TabPanel>
    </Box>
  );
};

export default NotificationTypeTabs;
