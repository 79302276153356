import EmailIcon from "@mui/icons-material/Email";
import LoadingButton from "@mui/lab/LoadingButton";
import { Divider, Grid } from "@mui/material";
import {
  useGetAllAccountsQuery,
  useGetAllDepartmentsQuery,
  useGetAllGroupsQuery,
  useInviteInternalUserMutation,
  useInviteUserMutation,
} from "api/api-it";
import { ROLE_ADMIN, ROLE_MEMBER } from "codes";
import AutoComplete from "components/Common/AutoComplete";
import Drop from "components/Common/Drop";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import Input from "components/Common/Input";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { Form, Formik } from "formik";
import isObjectEmpty from "helpers/isObjectEmpty";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styles from "./inviteuser.module.scss";

interface IUserInvite {
  first_name: string;
  last_name: string;
  title: string;
  email: string;
  mobile: string;
  role: string;
  is_authorized: boolean;
  is_partner: boolean;
  departments: any[];
  groups: any[];
  company: {
    id: string;
    name: string;
    reg_no: string;
  };
}

const InviteUser = ({
  setInviteUserMode,
}: {
  setInviteUserMode: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();

  const [userType, setUserType] = useState<string>("Internal");
  const [inviteUser, { error, isError, isLoading }] = useInviteUserMutation();
  const [inviteInternalUser, { isLoading: isLoadingInternalUser }] =
    useInviteInternalUserMutation();
  const { data: groups } = useGetAllGroupsQuery(null);
  const { data: departments } = useGetAllDepartmentsQuery(null);

  const AVAILABLE_ROLES =
    userType === "Internal"
      ? []
      : [
          { name: ROLE_ADMIN.toLowerCase(), value: ROLE_ADMIN.toLowerCase() },
          { name: ROLE_MEMBER.toLowerCase(), value: ROLE_MEMBER.toLowerCase() },
        ];

  const { data: accounts, isFetching: isFetchingAllAccounts } =
    useGetAllAccountsQuery(null);

  const setterForUserType = (value: string) => setUserType(value);

  const initialValues: IUserInvite = {
    first_name: "",
    last_name: "",
    title: "",
    email: "",
    mobile: "",
    role: ROLE_ADMIN.toLowerCase(),
    is_authorized: false,
    is_partner: false,
    departments: [],
    groups: [],
    company: {
      id: "",
      name: "",
      reg_no: "",
    },
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={() => {}}
    >
      {(formikProps) => {
        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Drop
                  label={`User Type`}
                  multilevel={false}
                  data={[
                    {
                      value: "internal",
                      name: "Internal",
                    },
                    {
                      value: "external",
                      name: "Clients",
                    },
                  ]}
                  name={"userType"}
                  value={userType}
                  keyValue={"name"}
                  setValue={setterForUserType}
                />
              </Grid>
              {userType === "Clients" && (
                <Grid item xs={12} lg={6}>
                  <LabelViewOnly
                    label={`Select Company`}
                    labelClassName={styles.labelClass}
                  />
                  {isFetchingAllAccounts && <SkeletonLoad bars={1} />}
                  {!isFetchingAllAccounts && accounts && (
                    <AutoComplete
                      labelKey="name"
                      data={accounts}
                      name={"company"}
                    />
                  )}
                </Grid>
              )}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6}>
                        <Input
                          labelClassName={styles.labelClass}
                          name={"first_name"}
                          label={"First Name"}
                          disabled={
                            !formikProps.values?.company?.id &&
                            userType === "Clients"
                          }
                          style={{ width: "100%" }}
                          {...formikProps}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Input
                          labelClassName={styles.labelClass}
                          name={"last_name"}
                          label={"Last Name"}
                          disabled={
                            !formikProps.values?.company?.id &&
                            userType === "Clients"
                          }
                          style={{ width: "100%" }}
                          {...formikProps}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6}>
                        <Input
                          labelClassName={styles.labelClass}
                          name={"title"}
                          label={t("job-position")}
                          disabled={
                            !formikProps.values?.company?.id &&
                            userType === "Clients"
                          }
                          style={{ width: "100%" }}
                          {...formikProps}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Input
                          labelClassName={styles.labelClass}
                          disabled={
                            !formikProps.values?.company?.id &&
                            userType === "Clients"
                          }
                          tabIndex={5}
                          name={"mobile"}
                          label={`${t("phone-number")}`}
                          placeholder={`${t("phone-number")}`}
                          fullWidth
                          type={"phone"}
                          style={{ width: "100%" }}
                          {...formikProps}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6}>
                        <Input
                          labelClassName={styles.labelClass}
                          name={"email"}
                          label={"Email"}
                          type="email"
                          style={{ width: "100%" }}
                          disabled={
                            !formikProps.values?.company?.id &&
                            userType === "Clients"
                          }
                          {...formikProps}
                        />
                      </Grid>

                      {Boolean(AVAILABLE_ROLES?.length) && (
                        <Grid item xs={12} lg={6}>
                          <Drop
                            label="Role"
                            labelClassName={styles.labelClass}
                            name={"role"}
                            style={{ width: "100%" }}
                            keyValue={"name"}
                            data={AVAILABLE_ROLES}
                            placeholder={`Select Role`}
                            disabled={
                              !formikProps.values?.company?.id &&
                              userType === "Clients"
                            }
                            {...formikProps}
                          />
                        </Grid>
                      )}
                      {departments &&
                        Boolean(departments?.length) &&
                        userType === "Internal" && (
                          <Grid item xs={12} lg={6}>
                            <LabelViewOnly
                              label="Departments"
                              labelClassName={styles.labelClass}
                              sx={{ fontSize: "1em", fontWeight: 600 }}
                            />
                            <AutoComplete
                              labelKey="name"
                              multiple
                              data={departments}
                              name={"departments"}
                            />
                          </Grid>
                        )}
                      {groups && userType === "Internal" && (
                        <Grid item xs={12} lg={6}>
                          <LabelViewOnly
                            label="Groups"
                            labelClassName={styles.labelClass}
                            sx={{ fontSize: "1em", fontWeight: 600 }}
                          />
                          <AutoComplete
                            labelKey="name"
                            multiple
                            data={Object.keys(groups).map((key) => ({
                              name: key,
                              value: key?.toLowerCase(),
                            }))}
                            name={"groups"}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item xs={8} lg={12} margin="auto">
                    <Grid container spacing={2} justifyContent={"end"}>
                      <Grid item xs={12} lg={3}>
                        {/* Because its two different APIs, dont ask me why */}
                        {userType === "Clients" && (
                          <LoadingButton
                            variant="contained"
                            fullWidth
                            disabled={!isObjectEmpty(formikProps.errors)}
                            loading={isLoading}
                            onClick={() => {
                              formikProps.values?.company &&
                                inviteUser({
                                  email: formikProps.values.email ?? "",
                                  first_name:
                                    formikProps.values.first_name ?? "",
                                  last_name: formikProps.values.last_name ?? "",
                                  mobile: formikProps.values.mobile ?? "",
                                  title: formikProps.values.title ?? "",
                                  account_id: formikProps.values?.company?.id,
                                  role: formikProps.values.role,
                                  is_authorized:
                                    formikProps.values.is_authorized,
                                  is_partner: formikProps.values.is_partner,
                                })
                                  .unwrap()
                                  .then(() => {
                                    toast(
                                      `Successfully invited ${formikProps.values?.first_name} ${formikProps.values?.last_name} for the Account: ${formikProps.values?.company?.name}!`,
                                      { type: "success" }
                                    );
                                    setInviteUserMode(false);
                                  })
                                  .catch((e) => {
                                    toast(e?.message, { type: "error" });
                                  });
                            }}
                          >
                            <Grid
                              container
                              justifyContent={"center"}
                              alignItems={"center"}
                              spacing={1}
                            >
                              <Grid item>
                                <EmailIcon sx={{ mt: 1 }} />
                              </Grid>
                              <Grid item>Invite</Grid>
                            </Grid>
                          </LoadingButton>
                        )}
                        {userType === "Internal" && (
                          <LoadingButton
                            variant="contained"
                            fullWidth
                            disabled={!isObjectEmpty(formikProps.errors)}
                            loading={isLoadingInternalUser}
                            onClick={() => {
                              inviteInternalUser({
                                email: formikProps.values.email ?? "",
                                first_name: formikProps.values.first_name ?? "",
                                last_name: formikProps.values.last_name ?? "",
                                mobile: formikProps.values.mobile ?? "",
                                title: formikProps.values.title ?? "",
                                role: formikProps.values.role,
                                departments:
                                  formikProps.values.departments?.map(
                                    (item) => item.name
                                  ),
                                groups: formikProps.values?.groups?.map(
                                  (item) => item.name
                                ),
                              })
                                .unwrap()
                                .then(() => {
                                  toast(
                                    `Successfully invited ${formikProps.values?.first_name} ${formikProps.values?.last_name}!`,
                                    { type: "success" }
                                  );
                                  setInviteUserMode(false);
                                })
                                .catch((e) => {
                                  toast(e?.message, { type: "error" });
                                });
                            }}
                          >
                            <Grid
                              container
                              justifyContent={"center"}
                              alignItems={"center"}
                              spacing={1}
                            >
                              <Grid item>
                                <EmailIcon sx={{ mt: 1 }} />
                              </Grid>
                              <Grid item>Invite</Grid>
                            </Grid>
                          </LoadingButton>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default InviteUser;
