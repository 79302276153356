import { Button, Grid, Typography, useTheme } from "@mui/material";
import Modal from "components/Common/Modal";
import { useFormikContext } from "formik";
import { RegistrationFormFields } from "helpers/formFields";
import useIsMobile from "hooks/useIsMobile";

import { SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import type { Partner } from "types";

interface IAddPartnerDeletion {
  list: Partner[];
  index: number;
  item: any;
  setBuyerDetailsSubmitted: (value: boolean) => void;
  setBuyerAdditionalDetailsData: (value: SetStateAction<any[]>) => void;
}

const AddPartnerDeletion = ({
  setBuyerAdditionalDetailsData,

  setBuyerDetailsSubmitted,
  list,

  index,
  item,
}: IAddPartnerDeletion) => {
  const theme = useTheme();
  const formikProps = useFormikContext<RegistrationFormFields>();
  const { t } = useTranslation();

  const modalOverlaySx = {
    backgroundColor: "white !important",
    width: "40vw",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4ch",
    padding: "2ch 3ch",
    fontSize: `${theme.typography.fontSize}px !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    fontWeight: "inherit !important",
  };
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
  const isMobile = useIsMobile();
  return (
    <>
      <Button
        fullWidth
        color="error"
        sx={{
          fontWeight: "bolder !important",
          textTransform: "capitalize",
          color: theme.palette.error.main,
        }}
        disabled={!item.showDelete}
        onClick={(e) => {
          e.preventDefault();
          setDeleteConfirm(true);
        }}
      >
        {`${t("delete")}`}
      </Button>
      <Modal
        message={
          <Grid container justifyContent={"start"} alignItems={"center"}>
            <Grid item xs={12}>
              <Typography textAlign={"center"}>
                {`Are you sure you want to delete this partner "${list[index]?.name}" ?`}
              </Typography>
            </Grid>
          </Grid>
        }
        modalContentSx={{ height: "10vh !important" }}
        Header={
          <Typography sx={{ fontWeight: 800, fontSize: "1em" }}>
            Confirm Deletion
          </Typography>
        }
        modalOverlaySx={modalOverlaySx}
        open={deleteConfirm}
        onClose={() => setDeleteConfirm(false)}
        closeButton
        width={isMobile ? "98vw" : "30vw"}
        primary={{
          variant: "contained",
          onClick: () => {
            setBuyerAdditionalDetailsData((prev) =>
              prev.filter((item, idx) => idx !== index)
            );
            const newList = list.filter(
              (item, itemIndex) => itemIndex !== index
            );
            formikProps?.setFieldValue("list", newList, true);
            setBuyerDetailsSubmitted(true);
            setDeleteConfirm(false);
          },
          children: `${t("confirm")}`,
        }}
        secondary={{
          variant: "outlined",
          onClick: () => setDeleteConfirm(false),
          sx: { textTransform: "inherit" },
          children: `${t("cancel")}`,
        }}
      />
    </>
  );
};
export default AddPartnerDeletion;
