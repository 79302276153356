import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Stack } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { useEditTemplateMutation } from "api/api-maps";
import Board from "components/Common/Board";
import { TaskData } from "components/Common/Board/types";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { HTMLAttributes, useState } from "react";
import { toast } from "react-toastify";

type ContainerProps = {
  main?: HTMLAttributes<HTMLDivElement>;
  column?: HTMLAttributes<HTMLDivElement>;
  task?: HTMLAttributes<HTMLDivElement>;
};

export default function ConfigurationTabs({
  sections,
}: {
  sections: {
    title: string;
    data: TaskData<any>[];
    isFetching: boolean;
  }[];
}) {
  const [editTemplate] = useEditTemplateMutation();
  const [containerProps, setContainerProps] = useState<ContainerProps>({
    main: {
      style: {
        height: "auto",
      },
    },
    task: {
      style: {
        height: "4vh !important",
      },
    },
  });

  return (
    <Stack id="configuration-tabs" spacing={2} maxWidth={"49vw"}>
      {sections.map((section, index) => (
        <>
          {section.isFetching && <SkeletonLoad bars={10} />}
          {!section.isFetching && section.data.length > 0 && (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${section}-content`}
                id={`${section}-header`}
              >
                <Typography fontWeight={"bold"}>{section.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Board
                  data={section.data}
                  type="MAP_CONFIGURATION"
                  defaultConsolidate={false}
                  isDragDisabled={false}
                  showSummary={false}
                  disableToolbar
                  containerProps={containerProps}
                  getBoardWidth={(width) => {
                    if (width)
                      setContainerProps((prev) => ({
                        ...prev,
                        column: {
                          style: { minWidth: `calc(${width}px - 20px)` },
                        },
                      }));
                  }}
                  onDragEndAction={(column) => {
                    const result = column.taskIds;
                    editTemplate({ data: result })
                      .then(() =>
                        toast.success("Template updated successfully")
                      )
                      .catch(() => toast.error("Failed to update template"));
                  }}
                />
              </AccordionDetails>
            </Accordion>
          )}
        </>
      ))}
    </Stack>
  );
}
