import SyncIcon from "@mui/icons-material/Sync";
import {
  Divider,
  Grid,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useGetCreditLimitCommentsQuery } from "api/api-comments";
import ActivityLog from "components/Common/ActivityLog";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import useIsMobile from "hooks/useIsMobile";
import moment from "moment";

const CreditLimitActivityLog = ({
  creditLimitId,
}: {
  creditLimitId: string;
}) => {
  const isMobile = useIsMobile();
  const { data: creditLimitComments, refetch: refreshComments } =
    useGetCreditLimitCommentsQuery(creditLimitId ?? "", {
      skip: !Boolean(creditLimitId),
    });
  return (
    <Paper
      variant="outlined"
      elevation={3}
      square={false}
      sx={{
        padding: "1ch 3ch",
        borderRadius: 1,
      }}
    >
      <Stack spacing={1}>
        <Grid container spacing={1} justifyContent={"start"}>
          <Grid item>
            <Typography
              fontWeight={600}
              fontSize={"1.1em"}
              textAlign={"center"}
            >
              Comment
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip
              children={
                <SyncIcon
                  onClick={() => refreshComments()}
                  sx={{ ":hover": { cursor: "pointer" } }}
                />
              }
              title={"Sync latest changes"}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>

        <Grid
          container
          width={isMobile ? "100%" : "15vw"}
          spacing={1}
          sx={{
            overflow: "scroll",
            mb: 2,
            "::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
          justifyContent={"start"}
        >
          {creditLimitComments?.length === 0 && (
            <Grid item xs={12}>
              <Typography sx={{ minHeight: "60vh" }} textAlign="center">
                No comment recorded
              </Typography>
            </Grid>
          )}
          {creditLimitComments?.length !== 0 && (
            <Grid item xs={12}>
              {creditLimitComments?.map((item) => (
                <ActivityLog
                  key={item.id}
                  type={"comment"}
                  timestamp={moment(item.created_at).format("DD/MM/YY HH:mm")}
                  title={item.contact ?? ""}
                  content={
                    <Stack spacing={1}>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <LabelViewOnly label={`Action: ${item.action}`} />
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <LabelViewOnly label={`Comment: ${item.comments}`} />
                      <br />
                    </Stack>
                  }
                />
              ))}
            </Grid>
          )}
        </Grid>
      </Stack>
    </Paper>
  );
};
export default CreditLimitActivityLog;
