import { Avatar, Chip, Grid, Theme, useTheme } from "@mui/material";
import { useGetMapSummaryQuery } from "api/api-maps";
import { ACCOUNTS, INCOMLEND_INTERNAL_LINK } from "codes";
import SkeletonLoad from "components/Common/SkeletonLoad";
import Icons from "components/Company/Registration/SelectorCard/Cards";
import React, { useContext } from "react";
import { MapContext } from ".";

interface DetailsStripProps {
  // Define your component's props here
}

const ProductIcon = ({ product }: { product: string }) => {
  switch (product) {
    case "factoring":
      return (
        <Icons.SupplierIcon active={true} activeColor="white" size="15px" />
      );
    case "reverse_factoring":
      return <Icons.BuyerIcon active={true} activeColor="white" size="15px" />;
    default:
      return <Icons.BuyerIcon active={true} activeColor="white" size="15px" />;
  }
};
const getBackgroundColor = (theme: Theme, product: string) => {
  switch (product) {
    case "factoring":
      return "primary";
    case "reverse_factoring":
      return "error";
    default:
      return "primary";
  }
};
const DetailsStrip: React.FC<DetailsStripProps> = (props) => {
  const theme = useTheme();
  const id = useContext(MapContext);
  const {
    data: mapData,
    refetch: refetchMapSummary,
    isLoading,
  } = useGetMapSummaryQuery(id ?? "", { skip: !id });
  const linkBarStyles = {
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.primary.main}`,
    fontWeight: "bold",
    width: "fit-content",
    fontSize: "0.6em",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8em",
      padding: "1em 2ch",
    },
  };

  return (
    <Grid container spacing={{ xs: 1, lg: 1 }} alignItems={"center"}>
      <Grid item>
        {isLoading && <SkeletonLoad bars={1} />}
        {!isLoading && mapData && (
          <Chip
            variant="filled"
            color={getBackgroundColor(theme, mapData?.product)}
            avatar={
              <Avatar
                color="primary"
                sx={{
                  bgcolor: `${getBackgroundColor(theme, mapData?.product)}.main`,
                }}
              >
                <ProductIcon product={mapData?.product} />
              </Avatar>
            }
            label={mapData?.product}
            sx={{
              textTransform: "uppercase",
              color: "#ffffff",
              fontWeight: 700,
            }}
          />
        )}
      </Grid>
      <Grid item>
        {isLoading && <SkeletonLoad bars={1} />}
        {!isLoading && mapData && (
          <Chip
            variant="outlined"
            avatar={
              <Avatar
                color="primary"
                sx={{
                  fontSize: "1em !important",
                  bgcolor: `${getBackgroundColor(theme, mapData?.product)}.main`,
                  color: "#ffffff !important",
                }}
              >
                B
              </Avatar>
            }
            label={`${mapData?.buyer_name}`}
            component="a"
            href={`${INCOMLEND_INTERNAL_LINK}${ACCOUNTS}/${mapData?.buyer_internal_name}`}
            clickable
            sx={{
              ...linkBarStyles,
            }}
          />
        )}
      </Grid>
      <Grid item>
        {isLoading && <SkeletonLoad bars={1} />}
        {!isLoading && mapData && (
          <Chip
            variant="outlined"
            avatar={
              <Avatar
                color={getBackgroundColor(theme, mapData?.product)}
                sx={{
                  fontSize: "1.4em !important",
                  bgcolor: `${getBackgroundColor(theme, mapData?.product)}.main`,
                  color: "#ffffff !important",
                }}
              >
                S
              </Avatar>
            }
            label={`${mapData?.seller_name}`}
            component="a"
            href={`${INCOMLEND_INTERNAL_LINK}${ACCOUNTS}/${mapData?.seller_internal_name}`}
            clickable
            sx={{
              ...linkBarStyles,
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default DetailsStrip;
