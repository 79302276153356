import FilterListIcon from "@mui/icons-material/FilterList";
import { Chip, Grid, Paper, Stack, Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import unique from "helpers/unique";
import * as React from "react";
import { useState } from "react";
import type { SalesOpportunity } from "types";

export default function CCFilter({
  data,
  filteredData,
  setFilteredData,
}: {
  data: SalesOpportunity[];
  filteredData: SalesOpportunity[] | undefined;
  setFilteredData: React.Dispatch<
    React.SetStateAction<SalesOpportunity[] | undefined>
  >;
}) {
  const [checked, setChecked] = useState(false);
  const [active, setActive] = useState<string>();
  const theme = useTheme();
  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  const handleDelete = () => {
    setActive(undefined);
    setFilteredData(data);
  };
  const progressConditions = [100, 50, 49];
  return (
    <Box>
      <Button
        variant="outlined"
        tabIndex={-1}
        startIcon={<FilterListIcon />}
        onClick={handleChange}
        disabled={data?.length === 0}
      >
        Filter
      </Button>
      <Box>
        <Collapse in={checked}>
          <Paper
            sx={{
              width: { xs: "100%", lg: "100%" },
              margin: "auto",
              mt: 1,
              padding: { xs: 2, lg: 2 },
              borderRadius: { xs: 2, lg: 2 },
              border: `1px solid ${theme.palette.secondary.main}80`,
            }}
          >
            {active !== undefined && (
              <Grid item xs={2}>
                <Chip
                  variant="outlined"
                  onDelete={handleDelete}
                  label={"Reset"}
                />
              </Grid>
            )}
            {/* ######### Group 1: Progress ######### */}
            <Stack spacing={1}>
              <Grid
                container
                spacing={1}
                justifyContent={"start"}
                alignItems={"center"}
              >
                <Grid item xs={12} lg={1}>
                  <Typography fontWeight={"bold"} fontSize={"0.9em"}>
                    Progress
                  </Typography>
                </Grid>
                {progressConditions.map((amount, i) => {
                  let filtering: SalesOpportunity[] = data;
                  let bgcolor = theme.palette.success.main;
                  switch (i) {
                    case 0:
                      filtering = data?.filter(
                        (item) => item.progress === amount
                      );
                      break;
                    case 1:
                      filtering = data?.filter(
                        (item) =>
                          item.progress < progressConditions[0] &&
                          item.progress >= amount
                      );
                      bgcolor = theme.palette.warning.main;
                      break;
                    case 2:
                      filtering = data?.filter(
                        (item) => item.progress < amount
                      );
                      bgcolor = theme.palette.error.main;
                      break;

                    default:
                      break;
                  }
                  return (
                    <Grid item key={`${amount}-${i}`}>
                      <Typography
                        textAlign="start"
                        sx={{
                          bgcolor,
                          color: theme.palette.background.default,
                          p: 1,
                          borderRadius: 1,
                          height: "auto",
                          py: 0.5,
                          border: `${
                            active === `${amount}`
                              ? `2px solid ${theme.palette.primary.main}`
                              : "none"
                          }`,
                          cursor: "pointer",
                        }}
                        fontWeight={"bold"}
                        onClick={() => {
                          setFilteredData(filtering);
                          setActive(`${amount}`);
                        }}
                      >
                        {`${
                          i === 1
                            ? `>${amount}`
                            : i === 2
                              ? `<${amount + 1}`
                              : amount
                        }%: ${filtering.length} deal${
                          filtering && filtering?.length > 1 ? "s" : ""
                        }`}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
              {/* ######### Group 2: Month ######### */}
              <Grid
                container
                spacing={1}
                justifyContent={"start"}
                alignItems={"center"}
              >
                <Grid item xs={12} lg={1}>
                  <Typography fontWeight={"bold"} fontSize={"0.9em"}>
                    Month
                  </Typography>
                </Grid>
                {unique(data.map((item) => item.etaMonth)).map((month) => (
                  <Grid item key={`${month}-filter`}>
                    <Typography
                      textAlign="start"
                      sx={{
                        p: 1,
                        borderRadius: 1,
                        height: "auto",
                        py: 0.5,
                        bgcolor: `${
                          active === month
                            ? `${theme.palette.primary.main}`
                            : `${theme.palette.background.default}`
                        }`,
                        color: `${
                          active === month
                            ? `${theme.palette.background.default}`
                            : `${theme.palette.primary.main}`
                        }`,
                        border: `${
                          active === month
                            ? `1px solid ${theme.palette.primary.main}`
                            : `1px solid ${theme.palette.secondary.main}`
                        }`,
                        cursor: "pointer",
                      }}
                      fontWeight={"bold"}
                      onClick={() => {
                        setFilteredData(
                          data?.filter((item) => item.etaMonth === month)
                        );
                        setActive(month);
                      }}
                    >
                      {month ?? "null"}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              {/* ######### Group 3: Sales Owner ######### */}
              <Grid
                container
                spacing={1}
                justifyContent={"start"}
                alignItems={"center"}
              >
                <Grid item xs={12} lg={1}>
                  <Typography fontWeight={"bold"} fontSize={"0.9em"}>
                    Sales Owner
                  </Typography>
                </Grid>
                {unique(data.map((item) => item.salesOwner)).map(
                  (salesOwner, i) => (
                    <Grid item key={`sales-owner-${i}-filter`}>
                      <Typography
                        textAlign="start"
                        sx={{
                          p: 1,
                          borderRadius: 1,
                          height: "auto",
                          py: 0.5,
                          bgcolor: `${
                            active === salesOwner
                              ? "#0472ca"
                              : `${theme.palette.background.default}`
                          }`,
                          color: `${
                            active === salesOwner
                              ? `${theme.palette.background.default}`
                              : `${theme.palette.primary.main}`
                          }`,
                          border: `${
                            active === salesOwner
                              ? `1px solid #0472ca`
                              : `1px solid ${theme.palette.secondary.main}`
                          }`,
                          cursor: "pointer",
                        }}
                        fontWeight={"bold"}
                        onClick={() => {
                          setFilteredData(
                            data?.filter(
                              (item) => item.salesOwner === salesOwner
                            )
                          );
                          setActive(salesOwner);
                        }}
                      >
                        {salesOwner ?? "null"}
                      </Typography>
                    </Grid>
                  )
                )}
              </Grid>
              {/* ######### Group 4: Insurer ######### */}
              <Grid
                container
                spacing={1}
                justifyContent={"start"}
                alignItems={"center"}
              >
                <Grid item xs={12} lg={1}>
                  <Typography fontWeight={"bold"} fontSize={"0.9em"}>
                    Insurer
                  </Typography>
                </Grid>
                {unique(data.map((item) => item.insurer)).map((insurer, i) => (
                  <Grid item key={`insurer-${i}-filter`}>
                    <Typography
                      textAlign="start"
                      sx={{
                        p: 1,
                        borderRadius: 1,
                        height: "auto",
                        py: 0.5,
                        bgcolor: `${
                          active === insurer
                            ? "#fcc473"
                            : `${theme.palette.background.default}`
                        }`,
                        color: `${
                          active === insurer
                            ? `${theme.palette.primary.main}`
                            : `${theme.palette.primary.main}`
                        }`,
                        border: `${
                          active === insurer
                            ? `1px solid #fcc473`
                            : `1px solid ${theme.palette.secondary.main}`
                        }`,
                        cursor: "pointer",
                      }}
                      fontWeight={"bold"}
                      onClick={() => {
                        setFilteredData(
                          data?.filter((item) => item.insurer === insurer)
                        );
                        setActive(insurer);
                      }}
                    >
                      {insurer ?? "null"}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              {active !== undefined && (
                <Typography
                  fontWeight={"bold"}
                >{`${filteredData?.length} results found`}</Typography>
              )}
            </Stack>
          </Paper>
        </Collapse>
      </Box>
    </Box>
  );
}
