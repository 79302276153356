import { Grid, Typography, useTheme } from "@mui/material";

import toCamelCase from "helpers/camelCase";
import useIsMobile from "hooks/useIsMobile";
import type { Account, RequiredDoc } from "types";
import DocumentList from "./List";

const DocSection = ({
  company,
  companyType,
  documents,
}: {
  company: Account;
  companyType: string;
  documents: RequiredDoc[];
}) => {
  const isMobile = useIsMobile();

  const DocumentPartnerList = () => {
    const theme = useTheme();
    return (
      <Grid item xs={12}>
        <Grid container spacing={1.5}>
          <Grid item xs={12} lg={6}>
            <Grid
              item
              xs={12}
              sx={{
                background: theme.palette.primary.main,
                color: theme.palette.background.default,
                borderRadius: 1,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "1em",
                  p: 2,
                }}
              >
                Required
              </Typography>
            </Grid>
            <DocumentList
              initialValues={documents
                ?.map((item) => item.display_name)
                ?.reduce((a, item) => {
                  return Object.assign(a, {
                    [toCamelCase(item)]: null,
                  });
                }, {})}
              entity_id={company.id}
              document_category={companyType}
              partner
              required={true}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid
              item
              xs={12}
              sx={{
                background: theme.palette.primary.main,
                color: theme.palette.background.default,
                borderRadius: 1,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "1em",
                  p: 2,
                }}
              >
                Optional
              </Typography>
            </Grid>
            <DocumentList
              initialValues={documents
                ?.filter((item) => !item.is_mandatory)
                ?.map((item) => item.display_name)
                ?.reduce((a, item) => {
                  return Object.assign(a, {
                    [toCamelCase(item)]: null,
                  });
                }, {})}
              entity_id={company?.id}
              document_category={companyType}
              enableCount={false}
              partner
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      container
      justifyContent={"center"}
      id="upload-onboard-ctr"
      sx={{ marginTop: !isMobile ? 0 : "2vh" }}
    >
      <Grid item xs={12}>
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <Typography
              sx={{ fontWeight: 800, fontSize: isMobile ? "1em" : "1.1em" }}
            >
              List of Documents
            </Typography>
          </Grid>
          <DocumentPartnerList />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default DocSection;
