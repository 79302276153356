import { Box, Grid, Slide, Typography } from "@mui/material";
import { useGetMapsQuery } from "api/api-maps";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import { DEFAULT_ROWS, INCOMLEND_INTERNAL_LINK, MAPS } from "codes";
import DataTablev2 from "components/Common/DataTablev2";
import Layout from "components/Common/Layout";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import useWidth from "helpers/useWidth";
import useIsMobile from "hooks/useIsMobile";
import { useRef } from "react";

const Maps = () => {
  const { data: maps, isFetching, isLoading, refetch } = useGetMapsQuery(null);
  const isMobile = useIsMobile();
  const { data: curr_user, isFetching: isFetchingUsers } =
    useGetCurrentUserQuery();
  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;
  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? (useWidth(ref) ?? 1000) : 1000;
  const headerLength = Object.keys(maps?.[0] ?? {})?.length;
  const defaultColumnWidth = width ? (width / headerLength) * 1.6 : 200;

  return (
    <Slide in direction={"left"}>
      <Box sx={{ display: "flex" }} flexGrow={1} ref={ref}>
        <Layout
          title={`Supplier Buyer Maps`}
          backArrow={false}
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.5em" : "1.5em",
          }}
          headerConfig={{
            syncAction: refetch,
            left: 8,
            mid: 0,
            right: 2,
            xs: {
              left: 12,
              mid: 0,
              right: 0,
            },
            alignItems: "center",
          }}
          mode="default"
        >
          {(isFetching || isLoading) && <SkeletonLoad bars={10} />}

          {!isFetching && (
            <Grid item xs={12} margin={"5px"}>
              {maps !== undefined && Boolean(maps?.length) ? (
                <DataTablev2
                  headerSx={{
                    h4: {
                      fontSize: isMobile ? "0.7em!important" : "1em!important",
                      height: "auto",
                    },
                  }}
                  rowsPerPage={rowsPerPage}
                  defaultColumnWidth={defaultColumnWidth}
                  onRowClicked={() => {}}
                  data={maps}
                  toBeHidden={[
                    "relationship_id",
                    "next_monitoring_date",
                    "score",
                  ]}
                  sx={{
                    table: {
                      minWidth: "100% !important",
                    },
                  }}
                  customColumns={{
                    map_id: {
                      map_id: "Map ID",
                      minWidth: 180,
                      link: {
                        href: (item: any) =>
                          `${INCOMLEND_INTERNAL_LINK}${MAPS}/${item.map_id}`,
                        target: "_self",
                      },
                    },
                    product: {
                      product: "Product",
                      minWidth: 180,
                      renderCell: ({ value }: { value: string }) => (
                        <Typography>{value}</Typography>
                      ),
                    },
                    buyer: {
                      buyer: "Buyer",
                      minWidth: (width - 180 * 2) / 2.45,
                      type: "text",
                    },
                    seller: {
                      seller: "Supplier",
                      minWidth: (width - 180 * 2) / 2.45,
                      type: "text",
                    },
                    status: {
                      status: "Status",
                      minWidth: 180,
                      type: "text",
                      renderCell: ({ value }: { value: string }) => (
                        <Typography
                          sx={{
                            bgcolor: value ? "success.main" : "error.main",
                            color: "white.main",
                            padding: "5px",
                            borderRadius: "5px",
                            fontWeight: "bold",
                          }}
                        >
                          {value ? "Approved" : "Pending"}
                        </Typography>
                      ),
                    },
                  }}
                />
              ) : (
                <NoData text="No Maps found" />
              )}
            </Grid>
          )}
        </Layout>
      </Box>
    </Slide>
  );
};
export default Maps;
