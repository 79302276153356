import { Add } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Chip, Grid, Slide, Stack, useTheme } from "@mui/material";
import {
  useAddAccountProfileMutation,
  useExportLeadToSalesforceMutation,
  useGetLeadByNameQuery,
  useGetPartnersQuery,
} from "api/api-accounts";
import SFIcon from "assets/salesforce.svg?react";
import { BUYER, INCOMLEND_INTERNAL_LINK, SELLER, SUPPLIER } from "codes";
import Drop from "components/Common/Drop";
import Layout from "components/Common/Layout";
import SkeletonLoad from "components/Common/SkeletonLoad";
import CreateLeadPartner from "components/Company/Lead/CreateLeadPartner";
import LeadSectionTabs from "components/Company/Lead/LeadSectionTabs";
import AddAccountUser from "components/Onboard/User/AddAccountUser";
import { Form, Formik } from "formik";
import history from "helpers/history";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const LeadAccount = () => {
  const { id } = useParams();

  const theme = useTheme();
  const {
    data: lead,
    isFetching: isFetchingLead,
    isLoading: isLoadingLead,
    refetch: refetchLead,
  } = useGetLeadByNameQuery(id ?? "", { skip: !id });

  const [addTradePartnerState, setAddTradePartnerState] =
    useState<boolean>(false);
  const [addUserState, setAddUserState] = useState<boolean>(false);
  const VIEW_MODE = !(addTradePartnerState || addUserState);
  const [addAccountProfile] = useAddAccountProfileMutation();
  const [leadType, setLeadType] = useState<string>("");
  const [exportToSf] = useExportLeadToSalesforceMutation();
  const SFButton = lead?.sf_lead_id ? LoadingButton : Button;

  const setter = (value: string) =>
    lead?.id &&
    addAccountProfile({
      account_id: lead?.id,
      company_type: value,
    })
      .unwrap()
      .then(() => {
        setLeadType(value);
        refetchLead();
      });
  const profile = lead?.profile ?? "";
  const isCreatorMode = (addTradePartnerState || addUserState) && Boolean(lead);
  const partnerType = profile === SELLER ? BUYER?.toLowerCase() : SELLER;
  const partnerName = partnerType === SELLER ? SUPPLIER : BUYER;

  const LEAD_TYPES = [
    {
      value: SELLER,
      name: SELLER,
    },
    {
      value: BUYER.toLowerCase(),
      name: BUYER.toLowerCase(),
    },
  ];
  const getLabel = (profile: string) =>
    profile !== ""
      ? profile?.toLowerCase() === SELLER
        ? SUPPLIER
        : BUYER
      : "No Profile Set";

  const {
    data: partners,
    isFetching,
    isUninitialized: isPartnersUninitialized,
    isLoading,
    refetch: refetchPartners,
  } = useGetPartnersQuery(lead?.id ?? "", {
    skip: !Boolean(lead?.id),
  });
  const syncAction = () => {
    refetchLead();
    refetchPartners();
  };
  const previousURL = document?.referrer;
  const leadInitialValues = {
    any_credit_note: false,
    any_delays_of_payment_with_buyer: false,
    ...lead,
    partners,
    syncAction,
  };

  return (
    <Slide in direction={"left"}>
      <Box sx={{ display: "flex" }} flexGrow={1}>
        <Layout
          title={
            addTradePartnerState
              ? `Add a ${partnerName} for ${lead?.name}`
              : (lead?.name ?? "")
          }
          backArrow={VIEW_MODE}
          backLink={document ? previousURL : `${INCOMLEND_INTERNAL_LINK}/leads`}
          subtitle=""
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            sx: {
              fontSize: "1.2em",
              [theme.breakpoints.down("md")]: {
                fontSize: "1.5em",
              },
            },
          }}
          LayoutHeader={
            !isCreatorMode ? (
              <Stack alignItems={"end"} justifyContent={"end"}>
                {isFetchingLead && <SkeletonLoad bars={1} />}
                {!isFetchingLead && (
                  <SFButton
                    fullWidth
                    startIcon={<SFIcon />}
                    loading={isLoadingLead}
                    variant={lead?.sf_lead_id ? "text" : "contained"}
                    color="secondary"
                    sx={{
                      maxWidth: "13em",
                      fontWeight: "bold",
                      bgcolor: lead?.sf_lead_id ? "transparent" : "#00a1e0",
                      textDecoration: lead?.sf_lead_id ? "underline" : "none",
                      py: 0.25,
                    }}
                    onClick={() => {
                      lead?.sf_lead_id
                        ? window.open(
                            `${import.meta.env.VITE_SF_BASE_URL}${lead?.sf_lead_id}`,
                            "_blank"
                          )
                        : lead?.id &&
                          exportToSf(lead?.id)
                            .unwrap()
                            .then(() =>
                              toast.success("Lead Exported to Salesforce")
                            )
                            .catch((err) =>
                              toast.error(
                                `Error Exporting Lead to Salesforce: ${err?.message}`
                              )
                            );
                    }}
                  >
                    {lead?.sf_lead_id ? "View" : "Export"}
                  </SFButton>
                )}
              </Stack>
            ) : undefined
          }
          headerConfig={{
            left: addTradePartnerState || addUserState ? 9 : 5,
            mid: 4,
            right: addTradePartnerState || addUserState ? 1.5 : 3,
            syncAction,
            endComponent: (
              <Grid item>
                <Chip
                  label={getLabel(profile)}
                  sx={{ height: "3em" }}
                  variant="outlined"
                />
              </Grid>
            ),

            xs: {
              left: 12,
              mid: 12,
              right: 12,
            },
            alignItems: "center",
          }}
          primary={
            !addUserState
              ? {
                  children: addTradePartnerState ? "Cancel" : "Partner",
                  startIcon: addTradePartnerState ? undefined : <Add />,
                  fullWidth: true,
                  isCancel: addTradePartnerState,
                  onClick: () => setAddTradePartnerState((prev) => !prev),
                }
              : undefined
          }
          secondary={
            !addTradePartnerState
              ? {
                  children: addUserState ? "Cancel" : "User",
                  startIcon: addUserState ? undefined : <Add />,
                  fullWidth: true,
                  color: addUserState ? "error" : "primary",
                  onClick: () => setAddUserState((prev) => !prev),
                }
              : undefined
          }
          mode="default"
        >
          {VIEW_MODE && (
            <Grid container spacing={{ xs: 3, lg: 1 }}>
              {isCreatorMode && (
                <Grid item xs={12} lg={3}>
                  <Drop
                    label={`Select Lead Type`}
                    multilevel={false}
                    data={LEAD_TYPES}
                    name={"companyType"}
                    value={leadType}
                    keyValue={"name"}
                    placeholder={`Select Profile`}
                    setValue={setter}
                  />
                </Grid>
              )}
              {isFetchingLead && <SkeletonLoad bars={5} />}
              {!isFetchingLead && lead && (
                <Grid item xs={12} lg={12}>
                  <Formik
                    enableReinitialize
                    initialValues={leadInitialValues}
                    onSubmit={console.log}
                  >
                    {() => (
                      <Form>
                        <LeadSectionTabs />
                      </Form>
                    )}
                  </Formik>
                </Grid>
              )}
            </Grid>
          )}
          {addTradePartnerState && (
            <>
              {isFetchingLead && <SkeletonLoad bars={5} />}
              {!isFetchingLead && lead && (
                <CreateLeadPartner
                  onEnd={() => {
                    setAddTradePartnerState(false);
                    syncAction();
                  }}
                  partnerType={partnerType}
                  lead_id={lead?.id}
                  lead_internal_name={lead.internal_name}
                />
              )}
            </>
          )}

          {addUserState && (
            <>
              {isFetchingLead && <SkeletonLoad bars={5} />}
              {!isFetchingLead && lead && (
                <AddAccountUser
                  account_id={lead?.id}
                  callBack={() => {
                    history.push(
                      `${INCOMLEND_INTERNAL_LINK}/leads/${lead?.internal_name}`
                    );
                    setAddUserState(false);
                  }}
                />
              )}
            </>
          )}
        </Layout>
      </Box>
    </Slide>
  );
};
export default LeadAccount;
