import { OpenInNew, Search, Sync } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Chip,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  useGetSFLeadImportProgressQuery,
  useGetSFLeadQuery,
  useImportSFLeadMutation,
} from "api/api-accounts";
import { BUYER, INCOMLEND_INTERNAL_LINK, SUPPLIER } from "codes";
import MainCard from "components/Common/Cards/MainCard";
import NoData from "components/Common/NoData";
import LinearWithValueLabel from "components/Common/Progress";
import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import type { ISFLead } from "types";
import ImportLeadDataContainer from "./ImportLeadDataContainer";

type STATUS_TYPES = "IN_PROGRESS" | "COMPLETED" | "FAILED";

const ImportLead = () => {
  const [searchClicked, setSearchClicked] = useState<boolean>(false);
  const [leadId, setLeadId] = useState<string>();
  const [taskId, setTaskId] = useState<string>();
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const {
    data: lead,
    isLoading,
    status: leadCallStatus,
    refetch,
  } = useGetSFLeadQuery(leadId ?? "", {
    skip: !leadId,
  });

  const sfLead = {
    ...lead?.data,
    phone: lead?.data?.Phone || lead?.data?.MobilePhone,
  };

  const leadType = (): string => {
    switch (true) {
      case sfLead?.Product_type__c?.startsWith(SUPPLIER):
        return SUPPLIER;
      case sfLead?.Product_type__c?.startsWith(BUYER):
        return BUYER;
      default:
        return "Lead Type not found";
    }
  };

  const RequiredFields: Record<string, Array<keyof ISFLead>> = {
    Lead: ["Company", "Country", "Product_type__c"],
    Contact: ["Name", "Phone", "Email"],
  };
  const PERMITTED_PRODUCT_TYPES_LEAD = [
    "Buyer Reverse Factoring",
    "Supplier Factoring",
  ];

  const PERMITTED_PRODUCT_TYPES_PARTNER = [
    "Buyer Factoring",
    "Supplier Reverse Factoring",
  ];

  const Partners = sfLead?.Partners ?? [];
  const [importLead, { isLoading: isImporting }] = useImportSFLeadMutation();
  const {
    data: task,
    refetch: refetchProgress,
    isLoading: isProgressLoading,
    status: progressCallStatus,
  } = useGetSFLeadImportProgressQuery(taskId ?? "", {
    skip: !taskId || !isPolling,
    pollingInterval: isPolling ? 2000 : 0, // Poll every 2 seconds
  });
  const progress = task?.progress;
  const status = task?.status;

  useEffect(() => {
    if (progressCallStatus === "fulfilled" && status === "COMPLETED") {
      setIsPolling(false);
      refetch();
      toast.success("Lead imported successfully");
    } else if (progressCallStatus === "fulfilled" && status === "FAILED") {
      refetch();
      setIsPolling(false);
      toast.error("Lead import failed");
    }
  }, [progressCallStatus, status]);

  const getColor = (status: STATUS_TYPES) => {
    switch (status) {
      case "IN_PROGRESS":
        return "warning";
      case "COMPLETED":
        return "success";
      case "FAILED":
        return "error";
      default:
        return "info";
    }
  };

  return (
    <Stack spacing={5}>
      {status && status !== "FAILED" && status !== "COMPLETED" ? (
        <>
          <NoData text="Importing Lead..." />
          <LinearWithValueLabel
            value={progress ?? 0}
            color={getColor(status)}
          />
        </>
      ) : (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography fontWeight={"bold"}>Lead SF ID</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={leadId}
                onChange={(e) => setLeadId(e.target.value)}
                placeholder="Click on the icon or press enter to search"
                variant="standard"
                fullWidth
                disabled={isLoading}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearchClicked(true);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => setSearchClicked(true)}>
                      <Search />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
          </Grid>

          {searchClicked && (
            <Stack spacing={2}>
              <Grid
                container
                direction={"row"}
                spacing={2}
                alignItems={"top"}
                justifyContent={"space-between"}
                maxWidth={"100%"}
              >
                <Grid item xs={12} lg={8}>
                  <Stack spacing={1}>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <Typography fontWeight={"bold"} variant="h2">
                        Lead Details - {sfLead?.Name}
                      </Typography>
                      <Chip
                        label={leadType()}
                        variant="outlined"
                        size="small"
                      />
                      <IconButton onClick={refetch} color="primary">
                        <Sync />
                      </IconButton>
                      {sfLead?.LastModifiedDate && (
                        <Typography variant="caption">
                          Last Updated:{" "}
                          {moment(sfLead?.LastModifiedDate).fromNow()} {" | "}{" "}
                          {moment(sfLead?.LastModifiedDate).format(
                            "DD/MM/YYYY"
                          )}
                        </Typography>
                      )}
                    </Stack>

                    {sfLead?.Id_Link_Report__c && (
                      <Stack direction={"row"} spacing={2}>
                        <Typography fontWeight={"bold"}>
                          Salesforce Link
                        </Typography>
                        <Link
                          dangerouslySetInnerHTML={{
                            __html: sfLead?.Id_Link_Report__c,
                          }}
                        />
                      </Stack>
                    )}

                    <Stack direction={"row"} spacing={2} flexWrap={"wrap"}>
                      <Typography fontWeight={"bold"}>
                        {leadType() === BUYER ? SUPPLIER : BUYER}
                        {Partners?.length > 1 ? "s" : ""}
                      </Typography>
                      {Partners?.map((partner) => (
                        <Link
                          target={"_blank"}
                          href={`${import.meta.env.VITE_SF_BASE_URL}${partner}`}
                        >
                          {partner}
                        </Link>
                      ))}
                      {Partners?.length === 0 && (
                        <Typography>
                          No Signed Partners found for the Lead
                        </Typography>
                      )}
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <Typography fontWeight={"bold"}>
                        Lead Owner Email
                      </Typography>
                      <Typography>{sfLead?.Lead_Owner_email__c}</Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <Stack direction={"row"} spacing={2}>
                        <Typography fontWeight={"bold"}>
                          Termsheet Sent
                        </Typography>
                        <Typography
                          color={
                            sfLead?.Date_Termsheet_Sent__c
                              ? "primary.main"
                              : "error.main"
                          }
                        >
                          {sfLead?.Date_Termsheet_Sent__c
                            ? moment(sfLead?.Date_Termsheet_Sent__c).format(
                                "DD/MM/YYYY"
                              )
                            : sfLead?.Date_Termsheet_Signed__c
                              ? "Corrupt Data"
                              : "Not Sent"}
                        </Typography>
                      </Stack>
                      <Stack direction={"row"} spacing={2}>
                        <Typography fontWeight={"bold"}>
                          Termsheet Signed
                        </Typography>
                        <Typography
                          color={
                            sfLead?.Date_Termsheet_Signed__c
                              ? "primary.main"
                              : "error.main"
                          }
                        >
                          {sfLead?.Date_Termsheet_Signed__c
                            ? moment(sfLead?.Date_Termsheet_Signed__c).format(
                                "DD/MM/YYYY"
                              )
                            : "Not Signed"}
                        </Typography>
                      </Stack>
                    </Stack>
                    {sfLead?.internal_name && (
                      <Stack
                        direction={"row"}
                        spacing={2}
                        alignItems={"center"}
                      >
                        <Typography fontWeight={"bold"}>
                          Existing Lead
                        </Typography>
                        <Link
                          target={"_blank"}
                          href={`${INCOMLEND_INTERNAL_LINK}/leads/${sfLead?.internal_name}`}
                        >
                          {sfLead?.internal_name}
                        </Link>
                        <OpenInNew />
                      </Stack>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Tooltip
                    title={
                      <span>
                        {sfLead?.import_completed
                          ? "Lead is already imported"
                          : "Import Lead from Salesforce"}
                      </span>
                    }
                  >
                    <LoadingButton
                      fullWidth
                      variant="contained"
                      loading={isImporting}
                      disabled={sfLead?.import_completed}
                      onClick={() => {
                        leadId &&
                          importLead(leadId)
                            .unwrap()
                            .then(({ task_id }) => {
                              setTaskId(task_id);
                              setIsPolling(true);
                              toast.success("Lead imported started");
                            })
                            .catch((err) => toast.error(err?.message));
                      }}
                    >
                      Import
                    </LoadingButton>
                  </Tooltip>
                </Grid>
              </Grid>

              <Divider />

              <Stack
                spacing={2}
                direction="row"
                flexWrap={"wrap"}
                alignItems={"center"}
                justifyContent={"start"}
              >
                {Object.entries(RequiredFields)?.map(([key, fields]) => {
                  return (
                    <Stack
                      key={key}
                      spacing={2}
                      mt={"10px !important"}
                      ml={"16px !important"}
                    >
                      <ImportLeadDataContainer
                        key={key}
                        section={key}
                        fields={fields}
                        sfLead={sfLead}
                        PERMITTED_PRODUCT_TYPES={PERMITTED_PRODUCT_TYPES_LEAD}
                      />
                    </Stack>
                  );
                })}
                {Partners?.length === 0 && (
                  <MainCard
                    headerSx={{ py: 1, px: 3 }}
                    content
                    contentSX={{
                      py: 2,
                      px: 3,
                      minHeight: "23vh",
                      minWidth: "22vw",
                    }}
                    title={`Partner Details`}
                    footer={
                      <Typography
                        color={"white.main"}
                        fontWeight={"bold"}
                        textAlign={"center"}
                        width={"100%"}
                      >
                        Cannot import without all the required fields
                      </Typography>
                    }
                    footerSx={{
                      bgcolor: "error.main",
                    }}
                  >
                    <Stack spacing={2} mt={"10px !important"}>
                      <NoData text="No Partners found for the Lead" />
                    </Stack>
                  </MainCard>
                )}
                {Partners?.length > 0 &&
                  Partners?.map((partner) => {
                    return (
                      <Stack key={partner} spacing={2} mt={"10px !important"}>
                        <ImportLeadDataContainer
                          key={partner}
                          section="Partner"
                          fields={RequiredFields.Lead}
                          sfLead={undefined}
                          sfLeadId={partner}
                          PERMITTED_PRODUCT_TYPES={
                            PERMITTED_PRODUCT_TYPES_PARTNER
                          }
                        />
                      </Stack>
                    );
                  })}
              </Stack>
            </Stack>
          )}
          {searchClicked && leadCallStatus === "rejected" && (
            <NoData text="No data found for the given Lead SF ID" />
          )}
        </>
      )}
    </Stack>
  );
};
export default ImportLead;
