import { Box, Grid, Slide, Stack, Typography, useTheme } from "@mui/material";
import { useGetEligibilitiesQuery } from "api/api-legal";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import { DEFAULT_ROWS, INCOMLEND_INTERNAL_LINK, LEGAL_LINK } from "codes";
import DataTablev2 from "components/Common/DataTablev2";
import Layout from "components/Common/Layout";
import SkeletonLoad from "components/Common/SkeletonLoad";
import useWidth from "helpers/useWidth";
import useIsMobile from "hooks/useIsMobile";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import type { CountryEligibility } from "types";
import styles from "./countries.module.scss";

const TO_BE_HIDDEN = [
  "has_buyer",
  "has_seller",
  "commit_message",
  "draft",
  "conditions",
  "buyer_has_conditions",
  "seller_has_conditions",
];

const CountryPage = () => {
  const {
    data: countries,
    isFetching: isFetchingCountries,
    refetch,
  } = useGetEligibilitiesQuery(null);
  const theme = useTheme();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const { data: curr_user, isFetching: isFetchingUsers } =
    useGetCurrentUserQuery();
  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;
  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref) : 1000;
  const headers = Object.keys(countries?.[0] ?? {}).filter(
    (item) => !TO_BE_HIDDEN.includes(item)
  );
  const headerLength = headers?.length;
  const defaultColumnWidth = width ? (width / headerLength) * 1.5 : 200;

  const initialFilters = {
    supplier: {
      comparator: "is not",
      keywords: ["Unknown"],
    },
    buyer: {
      comparator: "is not",
      keywords: ["Unknown"],
    },
  };

  return (
    <Slide in direction={"left"}>
      <Box sx={{ display: "flex" }} flexGrow={1}>
        <Layout
          title={`Countries Eligibility`} //TODO- Pending Translation
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.2em" : "1.5em",
          }}
          className={styles.layout}
          headerConfig={{
            syncAction: refetch,
            left: 10,
            mid: 0,
            right: 2,
            xs: {
              left: 12,
              mid: 0,
              right: 0,
            },
            alignItems: "center",
          }}
          mode="default"
        >
          <Grid item xs={12} margin={"5px"}>
            <Grid container ref={ref}>
              {isFetchingCountries && !countries && <SkeletonLoad bars={10} />}
              {!isFetchingCountries && countries && (
                <DataTablev2
                  toBeHidden={TO_BE_HIDDEN}
                  rowsPerPage={rowsPerPage}
                  defaultColumnWidth={defaultColumnWidth}
                  initialFilters={initialFilters}
                  customColumns={{
                    additional_requirements: {
                      additional_requirements: "Additional Requirements",
                    },
                    name: {
                      name: "Name",
                      link: {
                        href: (country: CountryEligibility) =>
                          `${INCOMLEND_INTERNAL_LINK}${LEGAL_LINK}/${country?.id}/view-eligibility`,
                        target: "_self",
                      },
                      headerProps: {
                        sx: {
                          width: "100%",
                        },
                      },
                    },
                    buyer: {
                      buyer: t("buyer"),
                      minWidth: 150,
                      type: "text",
                      valueFormatter: (value: string) => `${value}`,
                      renderCell: (params: any) => {
                        const background =
                          params.value === "Eligible"
                            ? "#9FCE63"
                            : params.value === "Conditional"
                              ? "orange"
                              : params.value === "Unknown"
                                ? "grey"
                                : "red";
                        const text = params.value;
                        return (
                          <Typography
                            style={{
                              background,
                              padding: "0.5ch",
                              borderRadius: "10px",
                              color: "white",
                              fontWeight: "600",
                              textAlign: "center",
                            }}
                            sx={{
                              [theme.breakpoints.down("sm")]: {
                                fontSize: "1em",
                              },
                            }}
                          >
                            {text}
                          </Typography>
                        );
                      },
                    },
                    seller: {
                      seller: t("supplier"),
                      minWidth: 150,
                      type: "text",
                      valueFormatter: (value: string) => `${value}`,
                      renderCell: (params: any) => {
                        const background =
                          params.value === "Eligible"
                            ? "#9FCE63"
                            : params.value === "Conditional"
                              ? "orange"
                              : params.value === "Unknown"
                                ? "grey"
                                : "red";
                        const text = params.value;

                        return (
                          <Typography
                            style={{
                              background,
                              padding: "0.5ch",
                              borderRadius: "10px",
                              color: "white",
                              fontWeight: "600",
                              textAlign: "center",
                            }}
                            sx={{
                              [theme.breakpoints.down("sm")]: {
                                fontSize: "1em",
                              },
                            }}
                          >
                            {text}
                          </Typography>
                        );
                      },
                    },
                    party: {
                      party: "Parties",
                      minWidth: 150,
                      renderCell: ({ value }: { value: string[] }) => {
                        return (
                          <Stack spacing={1}>
                            {!value?.length && (
                              <Typography
                                sx={{
                                  color: theme.palette.secondary.main,
                                  textAlign: "center",
                                }}
                              >
                                Unknown
                              </Typography>
                            )}
                            {value?.map((item) => (
                              <Typography
                                sx={{
                                  fontWeight: 700,
                                  fontSize: "1em",
                                  p: 1,
                                  borderRadius: 1,
                                  border: `1px solid ${theme.palette.primary.main}`,
                                  textAlign: "center",
                                }}
                              >
                                {item}
                              </Typography>
                            ))}
                          </Stack>
                        );
                      },
                    },
                    document: {
                      document: "Documents",
                      minWidth: 150,
                      renderCell: ({ value }: { value: string[] }) => {
                        return (
                          <Stack spacing={1}>
                            {!value?.length && (
                              <Typography
                                sx={{
                                  color: theme.palette.secondary.main,
                                  textAlign: "center",
                                }}
                              >
                                Unknown
                              </Typography>
                            )}
                            {value?.map((item) => (
                              <Typography
                                sx={{
                                  fontWeight: 700,
                                  fontSize: "1em",
                                  p: 1,
                                  borderRadius: 1,
                                  border: `1px solid ${theme.palette.primary.main}`,
                                  textAlign: "center",
                                }}
                              >
                                {item}
                              </Typography>
                            ))}
                          </Stack>
                        );
                      },
                    },
                    legal_comments: {
                      legal_comments: "Legal Comments",
                      minWidth: 600,
                      renderCell: ({ value }: { value: string }) => {
                        return (
                          <Stack spacing={1}>
                            {!value && (
                              <Typography
                                sx={{ color: theme.palette.secondary.main }}
                              >
                                None
                              </Typography>
                            )}
                            {value && (
                              <Typography
                                sx={{
                                  fontWeight: 600,
                                  fontSize: "1em",
                                  lineHeight: "2em",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: 700,
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {value}
                              </Typography>
                            )}
                          </Stack>
                        );
                      },
                    },
                  }}
                  data={countries}
                />
              )}
            </Grid>
          </Grid>
        </Layout>
      </Box>
    </Slide>
  );
};

export default CountryPage;
