import { TableCell, useTheme } from "@mui/material";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import valueCleaner from "helpers/valueCleaner";
import React from "react";

interface MapRiskParamValues {
  [source: string]: {
    [field_name: string]: any;
  };
}

interface FinancialGrowthIndicatorProps {
  values: MapRiskParamValues;
  fieldName: string;
}

const FinancialGrowthIndicator: React.FC<FinancialGrowthIndicatorProps> = ({
  values,
  fieldName,
}) => {
  const ALLOWED_FIELDS = [
    "revenue",
    "gross profit",
    "net profit",
    "current assets",
    "current liabilities",
    "total assets",
    "total liabilities",
    "equity",
    "total debt",
    "cash",
    "receivables",
    "payables",
    "inventory",
  ];
  const numbers = Object.values(
    Object.entries(values)
      ?.map(([year, value]) => {
        const financial = value[fieldName] as unknown as
          | string
          | number
          | undefined;

        return financial === undefined ||
          (typeof financial === "string" &&
            financial === "Can not calculate because of no value") ||
          (typeof financial === "string" &&
            financial.startsWith("Need input for this field"))
          ? { [year]: 0 }
          : { [year]: Number(valueCleaner(`${financial}`)) };
      })
      .reduce(
        (acc, curr) => {
          const year = Object.keys(curr)[0];
          const value = Object.values(curr)?.[0] ?? 0;
          acc[year] = value;
          return acc;
        },
        {} as Record<number, number>
      )
  )?.reverse();

  const calculateGrowth = (values: number[]): number => {
    const difference = values[0] - values[1];
    return (difference / (values[1] > 0 ? values[1] : 1)) * 100;
  };

  const theme = useTheme();

  const getGrowthIndication = (growth: number): any[] => {
    switch (true) {
      case growth > 0:
        return [`▲`, theme.palette.success.main, growth];
      case growth === 0:
        return [``, theme.palette.info.main, growth];
      case growth < 0:
        return [`▼`, theme.palette.error.main, growth];
      default:
        return [``, theme.palette.info.main, growth];
    }
  };
  const [symbol, color, growth] = getGrowthIndication(calculateGrowth(numbers));

  return (
    <TableCell sx={{ textAlign: "left" }}>
      {ALLOWED_FIELDS.includes(fieldName?.toLowerCase()) && (
        <LabelViewOnly
          label={`${symbol} ${growth?.toFixed(2) ?? 0}%`}
          sx={{ color, minWidth: 80 }}
        />
      )}
    </TableCell>
  );
};

export default FinancialGrowthIndicator;
