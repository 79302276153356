import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import DateInput from "components/Common/DateInput";
import Drop from "components/Common/Drop";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import { useFormikContext } from "formik";

import { useEditMapSummaryMutation } from "api/api-maps";
import MainCard from "components/Common/Cards/MainCard";
import CheckboxField from "components/Common/FormFields/CheckboxField";
import VariableInput from "components/Common/VariableInput";
import moment from "moment";
import { PERMISSIONS } from "permissions";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import type { MapSummary } from "types";
import styles from "../maps.module.scss";

const Termination = () => {
  const USER_PERMISSIONS = useAppSelector(
    (state) => state.appState.user_permission
  );

  const disabled =
    !USER_PERMISSIONS.includes(PERMISSIONS.map_admin) ||
    !USER_PERMISSIONS.includes(PERMISSIONS.map_access_risk);
  const formikProps = useFormikContext<MapSummary>();
  const { map_name } = formikProps.values;
  const [editMapSummary] = useEditMapSummaryMutation();
  const editor = (field: string, value: any, friendlyLabel: string) => {
    editMapSummary({
      map_name,
      data: { [field]: value },
    })
      .unwrap()
      .then(() => {
        toast.success(`${friendlyLabel} Updated`);
      })
      .catch((err) => toast.error(`Error: ${err?.message}`));
  };
  return (
    <MainCard
      title="Termination"
      headerSx={{ py: 2, px: 3 }}
      content
      contentSX={{ py: 2, px: 3 }}
      children={
        <Grid
          container
          spacing={1}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid item xs={12}>
            <Grid
              container
              spacing={1}
              alignItems={"top"}
              justifyContent={"space-between"}
            >
              <Grid item lg={5} xs={12}>
                <LabelViewOnly
                  label="Freeze Account"
                  labelClassName={styles.labelClass}
                />
              </Grid>
              <Grid item lg={7} xs={12}>
                <FormGroup>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="freezed"
                      name="freezed"
                      value={formikProps.values.freezed}
                      onChange={(e) => {
                        formikProps.setFieldValue(
                          "freezed",
                          e.target.value === "true",
                          true
                        );
                        editor(
                          "freezed",
                          e.target.value === "true",
                          "Freeze Account"
                        );
                      }}
                      row
                    >
                      <Grid container spacing={1}>
                        <Grid item>
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            sx={{ alignItems: "center" }}
                            label={"Yes"}
                            disabled={disabled}
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            sx={{ alignItems: "center" }}
                            label={"No"}
                            disabled={disabled}
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </FormGroup>
              </Grid>

              {formikProps.values.freezed && (
                <>
                  <Grid item xs={12} lg={12}>
                    <Drop
                      label="Reason for Freeze"
                      name={"freeze_reason"}
                      keyValue={"name"}
                      value={formikProps.values.freeze_reason}
                      setValue={(value) => {
                        formikProps.setFieldValue("freeze_reason", value);
                        editor("freeze_reason", value, "Reason for Freeze");
                      }}
                      data={[
                        {
                          name: "Overdues",
                          value: "Overdues",
                        },
                        {
                          name: "Negative News",
                          value: "Negative News",
                        },
                        {
                          name: "Negative Financials",
                          value: "Negative Financials",
                        },
                        {
                          name: "Pending Documents",
                          value: "Pending Documents",
                        },
                      ]}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <DateInput
                      labelClassName={styles.labelClass}
                      wrapperClassName={styles.datePickerWrapper}
                      name={"freeze_start_date"}
                      label={"Start Date of Freeze (DD/MM/YYYY)"}
                      value={
                        formikProps.values.freeze_start_date !== undefined
                          ? new Date(formikProps.values.freeze_start_date)
                          : undefined
                      }
                      setFieldValue={(field, value, shouldValidate) => {
                        formikProps.setFieldValue(field, value, shouldValidate);
                        editor(
                          field,
                          moment(value).format("YYYY-MM-DD"),
                          "Start Date of Freeze"
                        );
                      }}
                      error={formikProps.errors.freeze_start_date as string}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LabelViewOnly
                      label="Risk Comment, if any"
                      labelClassName={styles.labelClassDropdown}
                    />
                    <VariableInput
                      labelClassName={styles.labelClass}
                      sx={{
                        ".MuiInputBase-root": {
                          height: "15vh",
                          backgroundClip: "border-box",
                          borderRadius: "10px",
                          textarea: {
                            height: "13vh !important",
                            overflow: "scroll !important",
                          },
                        },
                      }}
                      name={"freeze_comment"}
                      label={""}
                      placeholder={"Enter your comments here..."}
                      className={styles.formInput}
                      value={formikProps.values.freeze_comment ?? ""}
                      handleSave={(value) => {
                        formikProps.setFieldValue("freeze_comment", value);
                        editor("freeze_comment", value, "Risk Comment");
                      }}
                      multiline
                      style={{ width: "100%" }}
                      {...formikProps}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CheckboxField
                      label={`To be shared in Invoice verification details`}
                      name="shared_in_invoice"
                      labelClassName={styles.labelClass}
                      getFieldValue={(value) => {
                        formikProps.setFieldValue("shared_in_invoice", value);
                        editor(
                          "shared_in_invoice",
                          value,
                          "Shared in Invoice verification details"
                        );
                      }}
                      {...formikProps}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      }
      sx={{ width: "100%" }}
    />
  );
};
export default Termination;
