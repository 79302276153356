import { Cancel, CheckCircle } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { BUYER, SELLER } from "codes";
import * as React from "react";
import type { Account, PartnerData } from "types";
import TradeDetails from "../Details/TradeDetails";
import LeadPartnerAdditionalInformation from "./LeadPartnerAdditionalInformation";
import LeadPartnerCreditLimit from "./LeadPartnerCreditLimit";
import usePartnerStatus from "./utils/usePartnerStatus";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`leade-partner-tabpanel-${index}`}
      aria-labelledby={`leade-partner-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `leade-partner-tab-${index}`,
    "aria-controls": `leade-partner-tabpanel-${index}`,
  };
}

export default function LeadPartnerTabs({
  lead,
  partner,
  partnerDetails,
  onEnd,
}: {
  lead: Account;
  partner: Account;
  partnerDetails: PartnerData;
  onEnd: () => void;
}) {
  const [value, setValue] = React.useState(0);
  const {
    isPartnerAdditionalInformationFilledUp,
    isPartnerTradeDetailsInformationFilledUp,
  } = usePartnerStatus(partnerDetails);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const isBuyer = partner.profile === BUYER?.toLowerCase();
  const LEAD_IS_SELLER = lead.profile === SELLER;

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="lead-partner-tabs"
        >
          <Tab
            label="Additional Information"
            {...a11yProps(0)}
            icon={
              isPartnerAdditionalInformationFilledUp ? (
                <CheckCircle color="success" />
              ) : (
                <Cancel color="error" />
              )
            }
            iconPosition="end"
          />
          {isBuyer && (
            <Tab
              label="Trade Details"
              {...a11yProps(1)}
              icon={
                isPartnerTradeDetailsInformationFilledUp ? (
                  <CheckCircle color="success" />
                ) : (
                  <Cancel color="error" />
                )
              }
              iconPosition="end"
            />
          )}
          {LEAD_IS_SELLER && (
            <Tab label="Credit Limit" {...a11yProps(isBuyer ? 2 : 1)} />
          )}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <LeadPartnerAdditionalInformation partner={partner} onEnd={onEnd} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {isBuyer && (
          <TradeDetails companyId={lead?.id} partnerId={partner?.id} />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={isBuyer ? 2 : 1}>
        {LEAD_IS_SELLER && (
          <LeadPartnerCreditLimit
            seller={lead}
            buyer={partner}
            onEnd={onEnd}
            navigateTab={setValue}
          />
        )}
      </CustomTabPanel>
    </Box>
  );
}
