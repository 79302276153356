import { Add, Delete } from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  useEditMapRiskParametersMutation,
  useGetMapRiskParametersQuery,
  useGetMapSummaryQuery,
  useGetTemplatesQuery,
} from "api/api-maps";
import { SUPPLIER } from "codes";
import { InputType } from "components/Common/FormFields/InputField";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import SkeletonLoad from "components/Common/SkeletonLoad";
import VariableInput from "components/Common/VariableInput";
import { Form, Formik } from "formik";
import toCamelCase from "helpers/camelCase";
import { currencyFormatter } from "helpers/currencyFormatter";
import isObjectEmpty from "helpers/isObjectEmpty";
import valueCleaner from "helpers/valueCleaner";
import moment from "moment";
import { MapContext } from "pages/maps/map";
import React, { useEffect, useState } from "react";
import FinancialGrowthIndicator from "./FinancialGrowthIndicator";
import YearPicker from "./YearPicker";

interface MapTemplate {
  [field_name: string]: {
    id: string;
    name: string;
    type: string;
    format: string;
    log_id: string;
    data: string | number | string[];
  };
}

type FINANCIAL_BY_YEAR = {
  [year: string]: MapTemplate;
};

interface MapRiskParamValues {
  [source: string]: {
    [field_name: string]: any;
  };
}
const Financials = ({
  accountType,
  disabled,
}: {
  accountType: string;
  disabled: boolean;
}) => {
  const item_type = "financial";
  const id = React.useContext(MapContext);

  const {
    data: financialsTemplate,

    isLoading: isLoadingTemplate,
    refetch: refetchTemplate,
  } = useGetTemplatesQuery(item_type);
  const {
    data: mapRiskParams,

    isLoading: isLoadingMapRiskParams,
    refetch: refetchValues,
  } = useGetMapRiskParametersQuery(
    {
      map_name: id ?? "",
      account_type: accountType as "supplier" | "buyer",
      item_type,
    },
    { skip: !id }
  );
  const {
    data: mapData,
    refetch: refetchMapSummary,
    isLoading,
  } = useGetMapSummaryQuery(id ?? "", { skip: !id });

  const companyName = `${
    accountType === SUPPLIER?.toLowerCase()
      ? mapData?.seller_name
      : mapData?.buyer_name
  } (in USD)`;
  const [editFinancial] = useEditMapRiskParametersMutation();
  const theme = useTheme();

  const sortAndCompile = (data: { [x: string]: MapTemplate }) => {
    const keyValueArray = Object.entries(data)
      // Sort the array by keys (month, years) in descending order
      .sort(
        ([a], [b]) =>
          -(
            moment(a?.trim(), "MM/yyyy").valueOf() -
            moment(b?.trim(), "MM/yyyy").valueOf()
          )
      )
      // Convert the sorted array back to financials Object
      .reduce((acc, [key, value]) => {
        //preserve descending order
        acc[` ${key?.trim()}`] = value;
        return acc;
      }, {} as FINANCIAL_BY_YEAR);

    setFinancialsByYear(keyValueArray);
  };

  const addYear = (year: string) => {
    const unsortedObject = {
      ...financialsByYear,
      [year]: financialsTemplate as MapTemplate,
    };
    sortAndCompile(unsortedObject);
  };

  const generateInitialValues = (
    valueObject: MapRiskParamValues,
    initialYear?: string,
    financials?: MapTemplate
  ): MapRiskParamValues => {
    if (!isObjectEmpty(valueObject)) {
      return Object.entries(valueObject).reduce((acc, [key, value]) => {
        if (typeof value === "object" && value !== null) {
          // If the value is an object, transform its keys to camelCase
          acc[key] = Object.entries(value).reduce(
            (innerAcc, [innerKey, innerValue]) => {
              innerAcc[toCamelCase(innerKey)] = innerValue;
              return innerAcc;
            },
            {} as Record<string, any>
          );
        } else {
          // Otherwise, keep the value as is
          acc[key] = value;
        }
        return acc;
      }, {} as MapRiskParamValues);
    }
    if (initialYear !== undefined)
      return {
        [initialYear]: financials
          ? Object.keys(financials).map((item) => ({
              [toCamelCase(item)]: undefined,
            }))
          : {},
      };
    return {};
  };

  const [financialsByYear, setFinancialsByYear] = useState<FINANCIAL_BY_YEAR>(
    mapRiskParams
      ? generateInitialValues(mapRiskParams)
      : ({} as FINANCIAL_BY_YEAR)
  );
  useEffect(() => {
    mapRiskParams && setFinancialsByYear(generateInitialValues(mapRiskParams));
  }, [isLoadingMapRiskParams]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value?: string) => {
    setOpen(false);
    if (!value) return;
    if (value) addYear(value);
  };

  const getByAuxType = (value: string, auxType: string | undefined) => {
    switch (auxType) {
      case "percentage":
        return `${(Number(value) * 100).toFixed(2)}%`;
      case "money":
        return currencyFormatter({ amount: Number(value), currency: "USD" });
      case "decimal":
        return `${Number(value).toFixed(2)}`;
      case "text":
        return value;
      default:
        return value;
    }
  };

  return (
    <Grid item xs={12}>
      {(isLoadingMapRiskParams || isLoadingTemplate) && (
        <SkeletonLoad bars={10} />
      )}
      {!(isLoadingMapRiskParams || isLoadingTemplate) &&
        financialsTemplate &&
        mapRiskParams && (
          <Formik
            initialValues={financialsByYear}
            onSubmit={console.log}
            enableReinitialize
          >
            {(formikProps) => {
              return (
                <Form>
                  <TableContainer>
                    <Table component={Paper} size="small">
                      <TableHead
                        sx={{
                          borderBottom: `3px solid ${theme.palette.error.main}`,
                        }}
                      >
                        <TableRow>
                          <TableCell>
                            <Typography fontWeight={"bold"}>
                              {companyName}
                            </Typography>
                          </TableCell>
                          {Object.keys(formikProps.values).map((item) => (
                            <TableCell>
                              <Stack
                                direction={"row"}
                                spacing={1}
                                alignItems={"center"}
                              >
                                <Typography fontWeight={"bold"}>
                                  {moment(item, "MM/yyyy").format("MMM-YYYY")}
                                </Typography>
                                <Tooltip
                                  title="Delete Record"
                                  placement="right-end"
                                >
                                  <IconButton
                                    onClick={() => {
                                      const newValues = {
                                        ...formikProps.values,
                                      };
                                      delete newValues[item];
                                      formikProps.setValues(newValues);
                                      sortAndCompile(newValues);
                                    }}
                                    disabled={disabled}
                                  >
                                    <Delete
                                      color={disabled ? "disabled" : "error"}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Stack>
                            </TableCell>
                          ))}
                          {Object.keys(formikProps.values)?.length > 1 && (
                            <TableCell>
                              <Typography fontWeight={"bold"}>
                                Growth
                              </Typography>
                            </TableCell>
                          )}
                          <TableCell>
                            <Stack
                              direction={"row"}
                              spacing={1}
                              alignItems={"center"}
                            >
                              <Button
                                variant="contained"
                                onClick={handleClickOpen}
                                startIcon={<Add />}
                                disabled={disabled}
                              >
                                YEAR
                              </Button>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!financialsTemplate && <SkeletonLoad bars={10} />}
                        {financialsTemplate &&
                          Object.entries(financialsTemplate).map(
                            ([key, keyParams]) => (
                              <TableRow key={key}>
                                <TableCell>
                                  <LabelViewOnly
                                    sx={{ fontWeight: 500 }}
                                    label={key}
                                    helpText={
                                      keyParams?.type === "formula"
                                        ? `This field is calculated using the formula: ${keyParams?.data}`
                                        : undefined
                                    }
                                  />
                                </TableCell>
                                {Object.keys(formikProps.values).map((year) => {
                                  const value = (
                                    formikProps.values as MapRiskParamValues
                                  )?.[year]?.[toCamelCase(key)];
                                  const { type, format } = keyParams;
                                  return (
                                    <TableCell key={year}>
                                      {type === "formula" ? (
                                        <LabelViewOnly
                                          label={
                                            value === undefined ||
                                            (typeof value === "string" &&
                                              value ===
                                                "Can not calculate because of no value") ||
                                            (typeof value === "string" &&
                                              value.startsWith(
                                                "Need input for this field"
                                              ))
                                              ? keyParams?.data
                                              : `${getByAuxType(value, format)} ${key.includes("Cash Conversion") ? "days" : ""}`
                                          }
                                        />
                                      ) : (
                                        <VariableInput
                                          name={`${year}.${toCamelCase(key)}`}
                                          label={undefined}
                                          disabled={disabled}
                                          noHover={disabled}
                                          value={
                                            (value ===
                                              "Need input for this field" ||
                                              value === undefined) &&
                                            format === "money"
                                              ? 0
                                              : valueCleaner(`${value}`)
                                          }
                                          type={format as InputType}
                                          handleSave={(value) => {
                                            id &&
                                              editFinancial({
                                                map_name: id,
                                                account_type: accountType as
                                                  | "buyer"
                                                  | "supplier",
                                                data: {
                                                  map_metadata_id:
                                                    keyParams?.id,
                                                  source: year,
                                                  value:
                                                    type === "money"
                                                      ? valueCleaner(`${value}`)
                                                      : value,
                                                },
                                              });
                                          }}
                                          {...formikProps}
                                        />
                                      )}
                                    </TableCell>
                                  );
                                })}
                                {Object.keys(formikProps.values)?.length >
                                  1 && (
                                  <FinancialGrowthIndicator
                                    values={
                                      formikProps.values as MapRiskParamValues
                                    }
                                    fieldName={toCamelCase(key)}
                                  />
                                )}
                              </TableRow>
                            )
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <YearPicker open={open} onClose={handleClose} />
                </Form>
              );
            }}
          </Formik>
        )}
    </Grid>
  );
};
export default Financials;
