import { Sync } from "@mui/icons-material";
import {
  IconButton,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  useGetCreditCommitteeMapApprovalsQuery,
  useGetMapSummaryQuery,
} from "api/api-maps";
import MainCard from "components/Common/Cards/MainCard";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { Form, Formik } from "formik";
import { MapContext } from "pages/maps/map";
import { useContext } from "react";
import type { MapSummary } from "types";
import HeadApproversField from "./HeadApprovers";
import { initialValues } from "./formvalue";

const TableHeader = styled(TableCell)(() => ({
  fontWeight: "bold",
  fontSize: "1em",
  lineHeight: "3em",
}));

const CreditCommitteeApproval = () => {
  const id = useContext(MapContext);
  const { data: mapSummary, isLoading } = useGetMapSummaryQuery(id ?? "", {
    skip: id === undefined,
  });
  const { buyer_name, seller_name } = mapSummary as MapSummary;
  const { data: credit_committee_approvers, refetch } =
    useGetCreditCommitteeMapApprovalsQuery(id ?? "", {
      skip: id === undefined,
    });

  return (
    <Formik
      enableReinitialize
      initialValues={{ initialValues, ...credit_committee_approvers }}
      onSubmit={console.log}
    >
      {(formikProps) => (
        <Form>
          {isLoading && <SkeletonLoad bars={10} />}
          {!isLoading && (
            <MainCard
              headerSx={{ py: 2, px: 3 }}
              content
              contentSX={{ py: 2, px: 3 }}
              title={
                <Stack
                  alignItems={"center"}
                  justifyContent={"start"}
                  direction={"row"}
                >
                  <Typography fontWeight={600} fontSize={"1em"}>
                    Credit Committee Status
                  </Typography>
                  <IconButton onClick={() => refetch()}>
                    <Sync />
                  </IconButton>
                </Stack>
              }
              children={
                <TableContainer component={Paper}>
                  <Table
                    sx={{
                      minWidth: 650,
                      border: "1px solid #e0e0e0",
                      bgcolor: "background.default",
                    }}
                    size="small"
                    aria-label="approval-table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableHeader>CC Member</TableHeader>
                        <TableHeader>Approver</TableHeader>
                        <TableHeader>Decision</TableHeader>
                        <TableHeader>Comments</TableHeader>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(initialValues).map(([key, _]) => {
                        return (
                          <HeadApproversField
                            key={key}
                            department={key}
                            buyer={buyer_name}
                            supplier={seller_name}
                          />
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              }
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default CreditCommitteeApproval;
