import { Container } from "@mui/material";
import * as Sentry from "@sentry/react";
import {
  useGetAccountQuery,
  useInviteTradePartnersMutation,
  useUpdateStepsMutation,
} from "api/api-accounts";
import {
  useGetPartnerEligibleCountiesQuery,
  useGetStepsQuery,
} from "api/api-misc";
import {
  ADD_CREDIT_LIMITS,
  ADD_PARTNERS,
  BUYER,
  CL_REQUESTED,
  INCOMLEND_INTERNAL,
  REGISTER_FOR_FINANCING,
  SUPPLIER,
} from "codes";
import Loader from "components/Common/Loader";
import SkeletonLoad from "components/Common/SkeletonLoad";
import CompletionScreen from "components/Company/Registration/CompletionScreen";
import StepperComponent from "components/Stepper";
import { Form, Formik, FormikProps } from "formik";
import {
  RegistrationFormFields,
  defaultValues,
  validationSchemas,
} from "helpers/formFields";
import history from "helpers/history";
import isObjectEmpty from "helpers/isObjectEmpty";
import Error from "pages/error";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setCurrentCompany } from "redux/reducers/companyReducer";
import { setSteps } from "redux/reducers/stepsReducer";
import type { InviteTradePartners, ProcessStep } from "types";

const Presales = (): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const USER_ROLE = useAppSelector((state) => state.appState.role);
  const stepper = useAppSelector((state) => state.steps.value);

  const { data: steps, isLoading: isLoadingSteps } = useGetStepsQuery(
    USER_ROLE?.id,
    {
      skip: !Boolean(USER_ROLE?.id) || USER_ROLE?.id === INCOMLEND_INTERNAL,
    }
  );

  //Check from DB first, then check from Redux
  const CURRENT_STEP = steps?.step ? steps?.step : stepper.step;

  const STEP_FORM_VALUES =
    steps && !isObjectEmpty(steps?.form as object)
      ? steps?.form
      : stepper?.form;

  const companyType = STEP_FORM_VALUES?.companyType;
  const companyId = STEP_FORM_VALUES?.companyId;

  const currency = STEP_FORM_VALUES?.currency;
  const isSeller =
    companyType?.toLowerCase() === SUPPLIER.toLowerCase() ||
    companyType?.toLowerCase() === "seller" ||
    companyType === t("supplier");
  const type =
    companyType?.toLowerCase() === BUYER.toLowerCase() ? "buyer" : "seller";
  const partnerType = isSeller ? BUYER.toLowerCase() : "seller";

  const { data: company } = useGetAccountQuery(
    STEP_FORM_VALUES?.companyId ?? "",
    {
      skip: !Boolean(STEP_FORM_VALUES?.companyId),
    }
  );

  const { data: partnerEligibleCountries } = useGetPartnerEligibleCountiesQuery(
    partnerType,
    {
      skip: !Boolean(companyType) || !Boolean(partnerType),
    }
  );
  const [updateSteps, { isUninitialized: isUpdateUncalled }] =
    useUpdateStepsMutation();

  const [
    inviteTradePartners,
    { isUninitialized, isLoading: isLoadingInvite, isSuccess },
  ] = useInviteTradePartnersMutation();

  const saveAndContinue = (value: ProcessStep) => {
    updateSteps({
      company_id: value.data.companyId,
      step: value.step,
      form: value.data,
    })
      .unwrap()
      .then((res) => {
        dispatch(setSteps(res));
        document.getElementById("modal-header")?.scrollIntoView(true);
      })
      .catch((error) => {
        const errorFile = error?.message;
        toast(`Something went wrong : ${errorFile}`, {
          type: "error",
        });
      })
      .finally(() => {
        company &&
          dispatch(
            setCurrentCompany({
              ...company,
              type: value.data.companyType,
            })
          );
      });
  };

  const LABELS: Record<string, any> = {
    0: `${t("i-am-a")}`,
    1: `${t("add-partner")}`,
    2: isSeller ? `${t("credit-limit-request")}` : "Credit Limit Request",
  };

  const ROUTING_TABLE: Record<number, Record<number, string>> = {
    0: {
      3: REGISTER_FOR_FINANCING,
    },
    1: {
      0: ADD_PARTNERS,
    },
    2: { 0: ADD_CREDIT_LIMITS },
  };
  const maxSteps = Object.keys(LABELS)?.length;
  const Route = (
    formikProps: FormikProps<RegistrationFormFields>
  ): React.ReactElement => {
    const step = CURRENT_STEP;
    //Stop routing and wait for completion screen
    if (step !== maxSteps) {
      //This improves scalability of steps with sub-steps
      const sub_step = step === 0 ? formikProps?.values?.crStage : 0;
      const LINK = ROUTING_TABLE[step][sub_step];
      //in this way, the routes are always protected by their step values
      history.push(LINK);
    }

    return (
      <Form>
        <StepperComponent
          step={CURRENT_STEP}
          labels={Object.values(LABELS)}
          label={LABELS[CURRENT_STEP]}
          maxSteps={maxSteps}
          CompletionScreen={<CompletionScreen />}
        >
          <Container maxWidth={"md"} sx={{ mt: 5 }}>
            <Outlet />
          </Container>
        </StepperComponent>
      </Form>
    );
  };

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <Error
          error={error as Error}
          componentStack={componentStack}
          resetError={resetError}
          scope={"Presales routes"}
        />
      )}
      beforeCapture={(scope) => {
        scope.setTag("location", "PreSales routes");
      }}
    >
      <Suspense fallback={<Loader />}>
        <Formik
          enableReinitialize
          initialValues={{
            ...defaultValues,
            ...STEP_FORM_VALUES,
          }}
          validationSchema={
            validationSchemas[CURRENT_STEP]?.[
              CURRENT_STEP === 0 ? STEP_FORM_VALUES?.crStage : 0
            ]
          }
          onSubmit={(values) => {
            const isBuyerDriven =
              values?.requested_amount &&
              values?.companyType.toLowerCase() === BUYER.toLowerCase();

            //Check if the partner is eligible before submitting anything and revert if ineligible
            if (
              partnerEligibleCountries?.every(
                (country) =>
                  !values?.list
                    .map((list: any) => list.country)
                    .includes(country.name)
              )
            ) {
              toast(
                "Unfortunately, there are no eligible buyers for a Credit Limit request with Incomlend",
                { type: "error" }
              );
              if (isUpdateUncalled && companyId)
                updateSteps({
                  company_id: companyId,
                  step: 1,
                  form: values,
                })
                  .unwrap()
                  .catch((error) => {
                    const errorFile = error?.message;
                    toast(`Something went wrong : ${errorFile}`, {
                      type: "error",
                    });
                  });
            }

            const requestBody: InviteTradePartners = isBuyerDriven
              ? {
                  company_id: companyId ?? "",
                  data: values?.list ?? [],
                  requested_amount: values.requested_amount,
                  currency,
                }
              : { company_id: companyId ?? "", data: values?.list ?? [] };

            if (
              isUninitialized &&
              Boolean(requestBody?.data?.length) &&
              values &&
              !isSuccess
            )
              inviteTradePartners(requestBody)
                .unwrap()
                .then(() => {
                  saveAndContinue({
                    status: "complete",
                    step: 3,
                    data: {
                      ...values,
                      clStatus: CL_REQUESTED,
                    },
                  });
                })
                .catch((error) => {
                  const errorFile = error?.message;
                  toast(`Something went wrong : ${errorFile}`, {
                    type: "error",
                  });
                });
          }}
        >
          {(formikProps) => (
            <>
              {isLoadingInvite && <SkeletonLoad bars={10} />}
              {!isLoadingInvite && <Route {...formikProps} />}
            </>
          )}
        </Formik>
      </Suspense>
    </Sentry.ErrorBoundary>
  );
};
export default Presales;
