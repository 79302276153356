import { Button, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import moment from "moment";
import { useState } from "react";
import DatePicker from "react-datepicker";

const emails = ["username@gmail.com", "user02@gmail.com"];

export interface YearPickerProps {
  open: boolean;
  onClose: (value?: string) => void;
}

function YearPicker(props: YearPickerProps) {
  const { onClose, open } = props;
  const pickerFormat = "MM/yyyy";

  const [startDate, setStartDate] = useState(moment().format(pickerFormat));

  const handleClose = () => {
    onClose(startDate);
  };
  const handleOutsideClickClose = () => onClose();
  const onChange = (date: any) => {
    setStartDate(moment(date).format(pickerFormat));
  };

  return (
    <Dialog onClose={handleOutsideClickClose} open={open}>
      <Typography variant="h4" textAlign={"center"} pt={1}>
        Select Month & Year
      </Typography>
      <List sx={{ pt: 0 }}>
        <ListItem>
          <DatePicker
            onChange={onChange}
            value={startDate}
            dateFormat={pickerFormat}
            showMonthYearPicker
            inline
          />
        </ListItem>
        <ListItem>
          <Typography
            sx={{
              fontSize: "1em",
              width: "100%",
            }}
            textAlign={"center"}
            fontWeight={"bold"}
            color={"error.main"}
          >
            Selected Period :{" "}
            {moment(moment(startDate, pickerFormat)?.toDate())?.format(
              "MMM-YY"
            )}
          </Typography>
        </ListItem>
        <ListItem
          sx={{
            justifyContent: "center",
          }}
        >
          <Button variant="contained" color="success" onClick={handleClose}>
            Confirm
          </Button>
        </ListItem>
      </List>
    </Dialog>
  );
}

export default YearPicker;
