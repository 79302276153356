import { Info } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, Grid, Stack, Tooltip, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { useGetAccountOnboardingStatusQuery } from "api/api-accounts";
import { BUYER } from "codes";
import CompanyDetails from "components/Company/Details";
import BankAccountManagement from "components/Onboard/Bank";
import AddBankAccount from "components/Onboard/Bank/AddBankAccount";
import PartnerDocs from "components/Onboard/Companies/Partner";
import CompanyUserManagement from "components/Onboard/User";
import AddAccountUser from "components/Onboard/User/AddAccountUser";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import type { Account, BankAccount, CompanyUser } from "types";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`trading-partnerpanel-${index}`}
      aria-labelledby={`trading-partner-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `trading-partner-${index}`,
    "aria-controls": `trading-partnerpanel-${index}`,
  };
}

export default function TradingPartnertabs({
  currentPartner,
  profile,
  users,
  partnerAccountId,
  banks,
  tab = 0,
}: {
  currentPartner: Account;
  profile: string;
  users: CompanyUser[];
  partnerAccountId: string;
  banks?: BankAccount[];
  tab: number;
}) {
  const [value, setValue] = useState(tab);
  const [additionState, setAdditionState] = useState(false);
  const theme = useTheme();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { t } = useTranslation();

  const { data: onboardingStatus } = useGetAccountOnboardingStatusQuery(
    currentPartner.id,
    {
      skip: !currentPartner.id,
    }
  );

  console.log("onboardingStatus", onboardingStatus);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Trading partner tabs"
          variant="fullWidth"
          centered
        >
          <Tab
            label={
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>{`${t("details")}`}</Typography>
                <Tooltip
                  title={
                    <Typography>
                      {onboardingStatus?.details?.info
                        ? onboardingStatus?.details?.info
                        : "All details completed"}
                    </Typography>
                  }
                >
                  <Info />
                </Tooltip>
              </Stack>
            }
            {...a11yProps(0)}
            icon={
              onboardingStatus?.details?.status ? (
                <CheckCircleIcon color="success" />
              ) : (
                <CancelIcon color="error" />
              )
            }
            iconPosition="start"
          />
          <Tab
            label={
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>{`Documents`}</Typography>
                <Tooltip
                  title={
                    <Typography>
                      {onboardingStatus?.documents?.info
                        ? onboardingStatus?.documents?.info
                        : "All details completed"}
                    </Typography>
                  }
                >
                  <Info />
                </Tooltip>
              </Stack>
            }
            {...a11yProps(1)}
            icon={
              !onboardingStatus?.documents?.status ? (
                <CancelIcon color="error" data-testid="indicator-fail" />
              ) : (
                <CheckCircleIcon
                  color="success"
                  data-testid="indicator-success"
                />
              )
            }
            iconPosition="start"
          />
          <Tab
            label={
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>{`Contacts`}</Typography>
                <Tooltip
                  title={
                    <Typography>
                      {onboardingStatus?.users?.info
                        ? onboardingStatus?.users?.info
                        : "All details completed"}
                    </Typography>
                  }
                >
                  <Info />
                </Tooltip>
              </Stack>
            }
            {...a11yProps(2)}
            icon={
              onboardingStatus?.users.status ? (
                <CheckCircleIcon color="success" />
              ) : (
                <CancelIcon color="error" />
              )
            }
            iconPosition="start"
          />
          {profile === BUYER.toLowerCase() && (
            <Tab
              label={
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>{`Bank Information`}</Typography>
                  <Tooltip
                    title={
                      <Typography>
                        {onboardingStatus?.bank_details?.info
                          ? onboardingStatus?.bank_details?.info
                          : "All details completed"}
                      </Typography>
                    }
                  >
                    <Info />
                  </Tooltip>
                </Stack>
              }
              {...a11yProps(3)}
              icon={
                !onboardingStatus?.bank_details?.status ? (
                  <CancelIcon color="error" />
                ) : (
                  <CheckCircleIcon color="success" />
                )
              }
              iconPosition="end"
            />
          )}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <CompanyDetails partnerCompany={currentPartner} isPartner />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <PartnerDocs currentPartner={currentPartner} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Grid container spacing={3} justifyContent={"center"}>
          <Grid item xs={12}>
            {additionState && partnerAccountId ? (
              <AddAccountUser
                account_id={partnerAccountId}
                callBack={() => setAdditionState(false)}
                isPartner
              />
            ) : (
              partnerAccountId &&
              Boolean(users?.length) && (
                <CompanyUserManagement
                  companyId={partnerAccountId}
                  users={users}
                  isPartner
                />
              )
            )}
          </Grid>
          {!Boolean(users?.length) && (
            <AddAccountUser
              account_id={partnerAccountId}
              callBack={() => setAdditionState(false)}
              isPartner
            />
          )}
          {Boolean(users?.length) && (
            <Grid item xs={4}>
              <Button
                variant="contained"
                fullWidth
                disabled={additionState}
                sx={{
                  [theme.breakpoints.down("md")]: {
                    height: "2.8em",
                    ">div": {
                      height: "2.8em",
                      ">input": {
                        height: "0.8em",
                        fontSize: "0.8em",
                      },
                    },
                  },
                }}
                onClick={() => setAdditionState(true)}
              >
                Add
              </Button>
            </Grid>
          )}
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Grid container spacing={3} justifyContent={"center"}>
          {additionState ? (
            <Grid item xs={12}>
              <AddBankAccount
                setAdditionState={setAdditionState}
                account_id={currentPartner?.id}
                isPartner
              />
            </Grid>
          ) : (
            banks &&
            currentPartner && (
              <Grid item xs={12}>
                <BankAccountManagement
                  company={currentPartner}
                  partner_id={currentPartner?.id}
                />
              </Grid>
            )
          )}
          {Boolean(banks?.length) && (
            <Grid item xs={4}>
              <Button
                variant="contained"
                fullWidth
                disabled={additionState}
                sx={{
                  [theme.breakpoints.down("md")]: {
                    height: "2.8em",
                    ">div": {
                      height: "2.8em",
                      ">input": {
                        height: "0.8em",
                        fontSize: "0.8em",
                      },
                    },
                  },
                }}
                onClick={() => setAdditionState(true)}
              >
                Add
              </Button>
            </Grid>
          )}
          {!Boolean(banks?.length) && currentPartner && (
            <Grid item margin="auto" id="add-bank-account">
              <AddBankAccount
                setAdditionState={setAdditionState}
                account_id={currentPartner?.id}
              />
            </Grid>
          )}
        </Grid>
      </CustomTabPanel>
    </Box>
  );
}
