import { Grid, Typography } from "@mui/material";
import ThumbnailView from "components/Common/ThumbnailView";
import { useFormikContext } from "formik";
import toProperCase from "helpers/propercase";
import { Fragment } from "react/jsx-runtime";
import type { MapSummary } from "types";

const CCDocs = () => {
  const formikProps = useFormikContext<MapSummary>();
  const { map_documents } = formikProps.values;

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={3}>
          <ThumbnailView fileName={"Purchase Order"} />
        </Grid>
        <Grid item xs={3}>
          <ThumbnailView fileName={"Sales Contract"} />
        </Grid>
        <Grid item xs={3}>
          <ThumbnailView fileName={"Invoice"} />
        </Grid>
        <Grid item xs={3}>
          <ThumbnailView fileName={"Bill of Lading / Airway Bill"} />
        </Grid>
        <Grid item xs={3}>
          <ThumbnailView fileName={"Proof of local delivery"} />
        </Grid>
        <Grid item xs={3}>
          <ThumbnailView fileName={"Goods Acceptance Clause in BC"} />
        </Grid>
        <Grid item xs={12}>
          {map_documents &&
            Object.entries(map_documents).map(([key, value]) => {
              return (
                <Fragment key={key}>
                  <Typography key={key} fontWeight={"bold"}>
                    {key
                      ?.replace("_", " ")
                      .split(" ")
                      .map((word) => `${toProperCase(word)}`)
                      .join(" ")}
                  </Typography>
                  {value.length ? (
                    value.map((item) => (
                      <Grid item xs={3}>
                        <ThumbnailView
                          fileName={item.document_type_name}
                          imgSrc={item.url}
                        />
                      </Grid>
                    ))
                  ) : (
                    <Typography>No documents uploaded</Typography>
                  )}
                </Fragment>
              );
            })}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CCDocs;
