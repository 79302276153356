import { Grid, Slide, Stack, Typography, useTheme } from "@mui/material";
import { Form, Formik } from "formik";

import {
  useGetAccountQuery,
  useGetAccountRelationshipQuery,
  useGetBuyerCreditLimitQuery,
} from "api/api-accounts";
import { BUYER, ONBOARDING } from "codes";
import Layout from "components/Common/Layout";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { currencyFormatter } from "helpers/currencyFormatter";
import useIsMobile from "hooks/useIsMobile";
import { Dispatch, SetStateAction } from "react";
import { useAppSelector } from "redux/hooks";
import type { Account, Company } from "types";
import AdditionalInformation from "./AdditionalInformation";
import RegistrationDetails from "./RegistrationDetails";
import TradeDetails from "./TradeDetails";
import {
  companyDetailsInitialValues,
  validationSchemaOB,
  validationSchemaPresales,
} from "./validationSchema";

const CompanyDetails = ({
  partnerCompany,
  isPartner,
}: {
  partnerCompany?: Account;
  setCurrentPartner?: Dispatch<SetStateAction<Company | undefined>>;
  isPartner?: boolean;
}) => {
  const USER_ROLE = useAppSelector((state) => state.appState.role);
  const profile = useAppSelector((state) => state.appState.profile);
  const APP_STATE = useAppSelector((state) => state.appState.value);

  const isMobile = useIsMobile();

  const { data: availableCreditLimit, isFetching: isFetchingCreditLimit } =
    useGetBuyerCreditLimitQuery(USER_ROLE?.id, {
      skip: !Boolean(USER_ROLE.id) || profile !== BUYER.toLowerCase(),
    });
  const {
    data: company,
    isFetching: isFetchingCompany,
    refetch: refetchCompany,
  } = useGetAccountQuery(USER_ROLE?.id, {
    skip: !Boolean(USER_ROLE.id),
  });

  const {
    data: partner,
    isFetching: isFetchingPartnerCompany,
    refetch: refetchPartner,
  } = useGetAccountQuery(partnerCompany?.id ?? "", {
    skip: !Boolean(partnerCompany?.id),
  });

  const currentCompany = partnerCompany ? partner : company;
  const isFetching = partnerCompany
    ? isFetchingPartnerCompany
    : isFetchingCompany;

  const theme = useTheme();
  const { data: relationship } = useGetAccountRelationshipQuery(
    {
      companyId: USER_ROLE?.id,
      partnerId: partner?.id ?? "",
    },
    { skip: !isPartner || !partner?.id }
  );

  if (!currentCompany) return <NoData text={"No Data to be displayed"} />;
  const initialValues = {
    ...companyDetailsInitialValues,
    ...currentCompany,
    name: currentCompany?.name,
    id: currentCompany?.id,
  };

  return (
    <Layout
      title={currentCompany?.name}
      subtitle={currentCompany?.contact_email}
      textHeadingOptions={{
        fontWeight: "bolder",
        level: 2,
        fontSize: isMobile ? "1.2em" : "1.5em",
      }}
      LayoutHeader={
        <Grid container spacing={isMobile ? 0 : 2}>
          {profile === BUYER.toLowerCase() && !isPartner && (
            <Grid item>
              <Typography
                sx={{
                  background: theme.palette.primary.main,
                  color: theme.palette.background.default,
                  fontWeight: "bold",
                  padding: 1,
                  borderRadius: 3,
                }}
              >
                Available Credit Limit:{" "}
                {availableCreditLimit && availableCreditLimit.currency}{" "}
                {availableCreditLimit &&
                  currencyFormatter({
                    amount:
                      availableCreditLimit?.approved_amount ||
                      availableCreditLimit?.atradius_amount ||
                      availableCreditLimit?.requested_amount,
                  })}
              </Typography>
            </Grid>
          )}
        </Grid>
      }
      headerConfig={{
        syncAction: isPartner ? refetchPartner : refetchCompany,
        left: 8,
        mid: 0,
        right: 0,
      }}
      mode="default"
    >
      <Grid container marginTop="2vh">
        <Slide in direction={"left"} appear={Boolean(currentCompany)}>
          <Grid item xs={12}>
            {isFetching && <SkeletonLoad bars={10} />}
            {!isFetching && currentCompany ? (
              <Stack spacing={3}>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={
                    APP_STATE === ONBOARDING
                      ? validationSchemaOB
                      : validationSchemaPresales
                  }
                  onSubmit={() => {}}
                  validateOnChange
                >
                  {() => {
                    return (
                      <Form>
                        <RegistrationDetails
                          isPartner={Boolean(partnerCompany)}
                        />
                      </Form>
                    );
                  }}
                </Formik>
                <Formik
                  enableReinitialize
                  initialValues={{
                    ...companyDetailsInitialValues,
                    ...currentCompany,
                    ...relationship,
                    name: currentCompany?.name,
                    id: currentCompany?.id,
                    partner_id: partner?.id,
                  }}
                  validationSchema={
                    APP_STATE === ONBOARDING
                      ? validationSchemaOB
                      : validationSchemaPresales
                  }
                  onSubmit={() => {}}
                  validateOnChange
                >
                  {() => {
                    return (
                      <Form>
                        <AdditionalInformation />
                      </Form>
                    );
                  }}
                </Formik>
                <Formik
                  enableReinitialize
                  initialValues={{
                    ...companyDetailsInitialValues,
                    ...currentCompany,
                    ...relationship,
                    name: currentCompany?.name,
                    id: currentCompany?.id,
                  }}
                  validationSchema={
                    APP_STATE === ONBOARDING
                      ? validationSchemaOB
                      : validationSchemaPresales
                  }
                  onSubmit={() => {}}
                  validateOnChange
                >
                  {() => {
                    return (
                      <Form>
                        {Boolean(partnerCompany) && partner && (
                          <TradeDetails
                            companyId={USER_ROLE?.id}
                            partnerId={partner?.id}
                          />
                        )}
                      </Form>
                    );
                  }}
                </Formik>
              </Stack>
            ) : (
              <NoData text="Click the 'Add New Company' button to get started" />
            )}
          </Grid>
        </Slide>
      </Grid>
    </Layout>
  );
};
export default CompanyDetails;
