import { Grid } from "@mui/material";
import { INCOMLEND_INTERNAL_LINK, MAPS } from "codes";
import Layout from "components/Common/Layout";
import MapSections from "components/Map/Sections";
import { createContext } from "react";
import { useParams } from "react-router-dom";
import DetailsStrip from "./DetailsStrip";

export const MapContext = createContext<string | undefined>(undefined);
const Map = () => {
  const { id = "" } = useParams();

  return (
    <MapContext.Provider value={id}>
      <Layout
        title={<DetailsStrip />}
        headerConfig={{
          // syncAction: refetch,
          left: 12,
          mid: 3,
          right: 12,
          xs: {
            left: 12,
            mid: 12,
            right: 0,
          },
          alignItems: "start",
        }}
        backArrow={false}
        breadcrumbs={{
          data: [
            {
              name: "Maps",
              link: `${INCOMLEND_INTERNAL_LINK}${MAPS}`,
            },
            {
              name: id,
              link: `${window.location.pathname}`,
              active: true,
            },
          ],
        }}
      >
        <Grid
          container
          justifyContent={"space-between"}
          maxWidth={"xl"}
          margin="auto"
        >
          <MapSections />
        </Grid>
      </Layout>
    </MapContext.Provider>
  );
};
export default Map;
