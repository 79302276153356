import { Grid, Typography } from "@mui/material";
import {
  useGetAccountRelationshipQuery,
  useGetBuyerCreditLimitQuery,
} from "api/api-accounts";
import { BUYER } from "codes";
import SkeletonLoad from "components/Common/SkeletonLoad";

import { useFormikContext } from "formik";
import { currencyFormatter } from "helpers/currencyFormatter";
import { useEffect } from "react";
import type { Account } from "types";
import { AccountSections } from "./AccountSectionTabs";

const AccountPartnerCreditLimit = ({
  account,
  partner,
  onEnd,
}: {
  account: Account;
  partner: Account;
  onEnd: () => void;
}) => {
  const formikProps = useFormikContext<AccountSections>();
  const { setFieldValue } = formikProps;
  const { profile } = partner;
  const {
    data: availableCreditLimit,
    isFetching: isFetchingCreditLimit,
    status,
    refetch: refetchCL,
  } = useGetBuyerCreditLimitQuery(partner?.id ?? "", {
    skip: !account || profile !== BUYER.toLowerCase(),
  });

  const { data: relationship, refetch } = useGetAccountRelationshipQuery(
    {
      companyId: account?.id,
      partnerId: partner?.id ?? "",
    },
    { skip: !account || !partner?.id }
  );
  const IS_BUYER = profile === BUYER.toLowerCase();

  useEffect(() => {
    setFieldValue(
      "average_invoice_amount",
      relationship?.average_invoice_amount
    );
    setFieldValue("average_payment_terms", relationship?.average_payment_terms);
    setFieldValue(
      "number_of_past_paid_transaction",
      relationship?.number_of_past_paid_transaction
    );
    setFieldValue("any_credit_note", relationship?.any_credit_note);
    setFieldValue(
      "reason_for_credit_note",
      relationship?.reason_for_credit_note
    );
    setFieldValue(
      "any_delays_of_payment_with_buyer",
      relationship?.any_delays_of_payment_with_buyer
    );
    setFieldValue(
      "reason_of_any_delays_of_payment_with_buyer",
      relationship?.reason_of_any_delays_of_payment_with_buyer
    );
  }, [relationship, IS_BUYER]);

  return (
    <>
      {isFetchingCreditLimit && IS_BUYER && <SkeletonLoad bars={3} />}
      {!isFetchingCreditLimit &&
        availableCreditLimit &&
        IS_BUYER &&
        status !== "rejected" && (
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: "bold",
                padding: 1,
                borderRadius: 1,
                fontSize: "1.5em",
              }}
            >
              Available Credit Limit:{" "}
              {availableCreditLimit && availableCreditLimit.currency}{" "}
              {availableCreditLimit &&
                currencyFormatter({
                  amount:
                    availableCreditLimit?.approved_amount ||
                    availableCreditLimit?.atradius_amount ||
                    availableCreditLimit?.requested_amount,
                })}
            </Typography>
          </Grid>
        )}
    </>
  );
};
export default AccountPartnerCreditLimit;
