import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SyncIcon from "@mui/icons-material/Sync";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Link,
  Stack,
} from "@mui/material";
import { useGetAccountQuery } from "api/api-accounts";
import { INCOMLEND_INTERNAL_LINK } from "codes";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { useFormikContext } from "formik";
import type { PartnerData } from "types";
import LeadPartnerTabs from "./LeadPartnerTabs";
import { LeadSections } from "./LeadSectionTabs";

export default function LeadPartnerAccordion({
  sync,
  partner,
}: {
  sync: () => void;
  partner: PartnerData;
}) {
  const formikProps = useFormikContext<LeadSections>();
  const { partners, ...lead } = formikProps.values;
  const {
    data: account,
    isFetching,
    refetch,
  } = useGetAccountQuery(partner?.id ?? "", {
    skip: !Boolean(partner?.id),
    refetchOnMountOrArgChange: true,
  });

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Link
            fontWeight={"bold"}
            variant={"h4"}
            href={`${INCOMLEND_INTERNAL_LINK}/leads/${partner?.internal_name}`}
          >
            {partner?.name}
          </Link>

          <IconButton onClick={sync}>
            <SyncIcon />
          </IconButton>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {isFetching && <SkeletonLoad bars={10} />}
        {!isFetching && account && (
          <LeadPartnerTabs
            lead={lead}
            partner={account}
            partnerDetails={partner}
            onEnd={() => {
              sync();
              refetch();
            }}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
}
