import SyncIcon from "@mui/icons-material/Sync";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Chip,
  Divider,
  Grid,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  useApproveDraftMutation,
  useGetDraftEligibilityByVersionIdQuery,
} from "api/api-legal";
import ActivityLog from "components/Common/ActivityLog";
import moment from "moment";
import { toast } from "react-toastify";
import type { LegalCondition } from "types";

const LegalActivityLog = ({
  iso3,
  version_id,
}: {
  iso3: string;
  version_id: string;
}) => {
  const [approveDraft, { isLoading }] = useApproveDraftMutation();
  const { data: draft, refetch: refreshDraft } =
    useGetDraftEligibilityByVersionIdQuery(version_id ?? "", {
      skip: !Boolean(version_id),
    });
  const conditionFormatter = (condition: LegalCondition[]) =>
    condition
      ?.map(
        (item) =>
          `${item.condition ? "Condition: " + item.condition : ""} ${
            item.document_name ? "Document: " + item.document_name : ""
          }`
      )
      ?.join(" ,");
  return (
    <Paper
      variant="outlined"
      elevation={3}
      square={false}
      sx={{
        padding: "1ch 3ch",
        borderRadius: 1,
      }}
    >
      <Stack spacing={1}>
        <Grid container spacing={1} justifyContent={"start"}>
          <Grid item>
            <Typography
              fontWeight={600}
              fontSize={"1.1em"}
              textAlign={"center"}
            >
              ACTIVITY LOG
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip
              children={
                <SyncIcon
                  onClick={() => refreshDraft()}
                  sx={{ ":hover": { cursor: "pointer" } }}
                />
              }
              title={"Sync latest changes"}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid
          container
          width={"15vw"}
          spacing={1}
          sx={{
            overflow: "scroll",
            mb: 2,
            "::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
          justifyContent={"start"}
        >
          {!version_id && (
            <Grid item xs={12}>
              <Typography sx={{ minHeight: "60vh" }} textAlign="center">
                No activity logged
              </Typography>
            </Grid>
          )}
          {version_id &&
            draft?.logs?.map((item) => (
              <Grid item xs={12}>
                <ActivityLog
                  type={"edit"}
                  timestamp={moment(item.created_at).format("DD/MM/YY HH:mm")}
                  title={`${item.created_by.first_name} ${item.created_by.last_name}`}
                  content={
                    <Stack spacing={1}>
                      {(Boolean(item.buyer?.conditions_added?.length) ||
                        Boolean(item.buyer?.conditions_removed?.length)) && (
                        <Stack>
                          <Typography fontSize={"0.8em"}>
                            Changes on Buyer Conditions:
                          </Typography>
                          <Chip
                            variant="outlined"
                            color={
                              item.buyer?.conditions_removed?.length
                                ? "error"
                                : "success"
                            }
                            label={
                              item.buyer?.conditions_removed?.length
                                ? `🗑️ Removed`
                                : `➕ Added`
                            }
                            sx={{ width: "80%" }}
                          />
                          {item.buyer?.conditions_removed?.length
                            ? `${conditionFormatter(
                                item.buyer?.conditions_added
                              )} -> ${conditionFormatter(
                                item.buyer?.conditions_removed
                              )}`
                            : `${conditionFormatter(
                                item.buyer?.conditions_removed
                              )} -> ${conditionFormatter(
                                item.buyer?.conditions_added
                              )}`}
                        </Stack>
                      )}
                      <br />
                      {(item.buyer?.eligibility_type_added ||
                        item.buyer?.eligibility_type_removed) && (
                        <Stack>
                          <Chip
                            variant="outlined"
                            color="info"
                            label={`🔄 Changed`}
                          />
                          Buyer Eligibility Type:
                          <br />
                          {`${item.buyer?.eligibility_type_removed} -> ${item.buyer?.eligibility_type_added}`}
                          <br />
                        </Stack>
                      )}

                      {(Boolean(item.seller?.conditions_added?.length) ||
                        Boolean(item.seller?.conditions_removed?.length)) && (
                        <Stack spacing={1}>
                          <Typography fontSize={"0.8em"}>
                            Changes on Supplier Conditions:
                          </Typography>
                          <Chip
                            variant="outlined"
                            color={
                              item.seller?.conditions_removed?.length
                                ? "error"
                                : "success"
                            }
                            label={
                              item.seller?.conditions_removed?.length
                                ? `🗑️ Removed`
                                : `➕ Added`
                            }
                          />
                          <Typography fontSize={"1em"}>
                            {item.seller?.conditions_removed?.length
                              ? `${conditionFormatter(
                                  item.seller?.conditions_added
                                )} -> ${conditionFormatter(
                                  item.seller?.conditions_removed
                                )}`
                              : `${conditionFormatter(
                                  item.seller?.conditions_removed
                                )} -> ${conditionFormatter(
                                  item.seller?.conditions_added
                                )}`}
                          </Typography>
                        </Stack>
                      )}
                      <br />
                      {(item.seller?.eligibility_type_added ||
                        item.seller?.eligibility_type_removed) && (
                        <Stack>
                          <Chip
                            variant="outlined"
                            color="info"
                            label={`🔄 Changed`}
                          />
                          Supplier Eligibility Type:
                          <br />
                          {`${item.seller?.eligibility_type_removed} -> ${item.seller?.eligibility_type_added}`}
                          <br />
                        </Stack>
                      )}
                    </Stack>
                  }
                />
              </Grid>
            ))}
        </Grid>
        {version_id && (
          <LoadingButton
            variant="contained"
            fullWidth
            sx={{
              padding: "0.5ch",
            }}
            loading={isLoading}
            onClick={() =>
              iso3 &&
              approveDraft({
                country: iso3,
                version_id,
              })
                .unwrap()
                .then(() =>
                  toast("Approved Draft Successfully!", {
                    type: "success",
                  })
                )
                .catch((err) =>
                  toast(`Error: ${err?.message}`, {
                    type: "error",
                  })
                )
            }
          >
            Approve
          </LoadingButton>
        )}
      </Stack>
    </Paper>
  );
};
export default LegalActivityLog;
